import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";

import InputMask from "react-input-mask";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../actions";

const PeopleMainInfo = (props) => {
	let avatarInput = null;
	let idInput = null;
	let passportInput = null;
	let cvInput = null;
	let regInput = null;

	const fileUploadHost = "PeopleUpload";
	const downloadHost = "PeopleDownload";

	const [branchName, setBranchName] = useState("");

	useEffect(() => {
		if (props.person.branch && props.branches) {
			getBranchName(props.person.branch, props.branches);
		}
	}, [props.branches, props.person]);

	useEffect(() => {}, [props.person]);

	const getBranchName = (branchId, branches) => {
		let branchTitle = "";

		branches.forEach((el) => {
			if (el._id == branchId) {
				branchTitle = el.title;
			}
		});

		setBranchName(branchTitle);
	};

	const avatarClickHandler = () => {
		avatarInput.click();
	};

	const idClickHandler = () => {
		idInput.click();
	};

	const passportClickHandler = () => {
		passportInput.click();
	};

	const cvClickHandler = () => {
		cvInput.click();
	};

	const regClickHandler = () => {
		regInput.click();
	};

	const fileRemoveHandler = (event, name) => {
		props.setLoading(true);

		props.itemUpdateApiCall(null, null, {
			// name: "pictureImage",
			name: name,
			value: "",
		});
	};

	const fileUploadHandler = (event, bucket) => {
		props.setLoading(true);
		let imageUrl = URL.createObjectURL(event.target.files[0]);

		// props.handleFileInputChange({
		// 	name: "pictureImage",
		// 	value: imageUrl,
		// });
		const formData = new FormData();
		formData.append("file", event.target.files[0]);
		formData.append("bucket", bucket);

		axios
			.post(apiConfig[fileUploadHost], formData, {
				onUploadProgress: (ProgressEvent) => {
					let progress =
						Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
						"%";
				},
			})
			.then((result) => {
				if (result.data.success) {
					props.itemUpdateApiCall(null, null, {
						name: event.target.name,
						value: result.data.path,
					});
				}

				avatarInput.value = null;
				idInput.value = null;
				passportInput.value = null;
				cvInput.value = null;
				regInput.value = null;
			});
	};

	const downloadHandler = (fileName, folder, fileType) => {
		props.setLoading(true);
		let request = {
			filename: fileName,
			folder: folder,
			fileType: fileType,
		};

		axios
			.post(apiConfig[downloadHost], request, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				// let nameToReturn = request.filename.replace(`/${folder}/`, "");
				let userFriendlyFileName = `${props.person.slug}-${fileType}.${fileName
					.split(".")
					.pop()}`;
				link.setAttribute("download", userFriendlyFileName);
				document.body.appendChild(link);
				link.click();
				props.setLoading(false);
			});
	};

	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="name-input"
								name="name"
								onBlur={props.handleItemUpdate}
								// onChange={inputValidation}
								defaultValue={props.person.name}
								// onChange={props.handleInputChange}
								label="Ім'я"
								helperText={props.nameError ? "Поле обов'язкове" : ""}
								error={props.nameError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="surname-input"
								name="surname"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.surname}
								// onChange={props.handleInputChange}
								label="Призвище"
								// defaultValue=""
								helperText={props.surnameError ? "Поле обов'язкове" : ""}
								error={props.surnameError}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="settings-modal-input-library-label">
								Стать
							</InputLabel>
							<Select
								labelId="settings-modal-input-library-label"
								id="settings-modal-input-library"
								name="gender"
								onBlur={props.handleItemUpdate}
								// onChange={props.handleInputChange}
								defaultValue={props.person.gender}
								// onChange={(event) => handleParentCheck(event)}
							>
								<MenuItem selected value="1">
									Чоловіча
								</MenuItem>
								<MenuItem selected value="0">
									Жіноча
								</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<div className="profile-photo-actions-wrapper">
							<span className="acorion-form-radio-legend">Фото:</span>
							{props.person.pictureImage ? (
								<>
									<Tooltip title="Завантажити">
										<GetAppIcon
											onClick={() =>
												downloadHandler(
													props.person.pictureImage,
													"avatars",
													"avatar"
												)
											}
										/>
									</Tooltip>
									<Tooltip title="Видалити фото">
										<ClearIcon
											onClick={(event) =>
												fileRemoveHandler(event, "pictureImage")
											}
										/>
									</Tooltip>
								</>
							) : (
								<Tooltip title="Додати фото">
									<AddAPhotoIcon onClick={avatarClickHandler} />
								</Tooltip>
							)}
							<input
								type="file"
								style={{ display: "none" }}
								ref={(input) => {
									avatarInput = input;
								}}
								accept="image/x-png,image/gif,image/jpeg"
								onChange={(event) => fileUploadHandler(event, "avatars")}
								name="pictureImage"
								// onBlur={inputBlurHandler}
							/>
							{props.person.pictureImage ? (
								<img
									className="acordeon-form-avatar-img"
									src={apiConfig.Main + props.person.pictureImage}
								/>
							) : (
								""
							)}
						</div>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="birthday"
								name="birthday"
								label="Дата народження"
								type="date"
								defaultValue={props.person.birthday}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="email-input"
								name="email"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.email}
								// onChange={props.handleInputChange}
								label="Email"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputMask
								mask="\+380 (99\)\ 999 9999"
								defaultValue={props.person.phone}
								disabled={false}
								maskChar="_"
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{() => (
									<TextField
										id="phone-input"
										name="phone"
										label="Телефон"
										defaultValue=""
										// helperText={formValidation.fields.shortName.text}
										// error={formValidation.fields.shortName.isError}
									/>
								)}
							</InputMask>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="adress-input"
							name="adress"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.adress}
							// onChange={props.handleInputChange}
							label="Адреса"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="adressEng-input"
							name="adressEng"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.adressEng}
							// onChange={props.handleInputChange}
							label="Адреса англійською"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								disabled
								id="branch-input"
								name="branch"
								value={
									props.branches.find((el) => el._id == props.person.branch)
										.title
								}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								label="Осередок"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6"></div>
				</div>
				<div className="row acordion-row col-md-12">
					<div className="files-wrapper">
						<div
							className={
								"file-item-wrapper " +
								(props.person.id_file ? "file-exist" : "")
							}
						>
							<span className="file-item-name">ID документ</span>
							<div className="file-item-icons-wrapper">
								{props.person.id_file ? (
									<>
										<Tooltip title="Завантажити">
											<GetAppIcon
												className="file-item-icons-icon"
												onClick={() =>
													downloadHandler(props.person.id_file, "iddocs", "id")
												}
											/>
										</Tooltip>
										<Tooltip title="Видалити">
											<ClearIcon
												className="file-item-icons-icon"
												onClick={(event) => fileRemoveHandler(event, "id_file")}
											/>
										</Tooltip>
									</>
								) : (
									<Tooltip title="Додати">
										<PublishIcon
											className="file-item-icons-icon"
											// fontSize="large"
											onClick={idClickHandler}
										/>
									</Tooltip>
								)}

								<input
									type="file"
									style={{ display: "none" }}
									ref={(input) => {
										idInput = input;
									}}
									// accept="image/x-png,image/gif,image/jpeg"
									onChange={(event) => fileUploadHandler(event, "iddocs")}
									name="id_file"
									// onBlur={inputBlurHandler}
								/>
							</div>
						</div>
						<div
							className={
								"file-item-wrapper " +
								(props.person.passport_file ? "file-exist" : "")
							}
						>
							<span className="file-item-name">Папорт</span>
							<div className="file-item-icons-wrapper">
								{props.person.passport_file ? (
									<>
										<Tooltip title="Завантажити">
											<GetAppIcon
												className="file-item-icons-icon"
												onClick={() =>
													downloadHandler(
														props.person.passport_file,
														"passports",
														"passport"
													)
												}
											/>
										</Tooltip>
										<Tooltip title="Видалити">
											<ClearIcon
												className="file-item-icons-icon"
												onClick={(event) =>
													fileRemoveHandler(event, "passport_file")
												}
											/>
										</Tooltip>
									</>
								) : (
									<Tooltip title="Додати">
										<PublishIcon
											className="file-item-icons-icon"
											// fontSize="large"
											onClick={passportClickHandler}
										/>
									</Tooltip>
								)}
								<input
									type="file"
									style={{ display: "none" }}
									ref={(input) => {
										passportInput = input;
									}}
									// accept="image/x-png,image/gif,image/jpeg"
									onChange={(event) => fileUploadHandler(event, "passports")}
									name="passport_file"
									// onBlur={inputBlurHandler}
								/>
							</div>
						</div>
						<div
							className={
								"file-item-wrapper " +
								(props.person.cv_file ? "file-exist" : "")
							}
						>
							<span className="file-item-name">CV файл</span>
							<div className="file-item-icons-wrapper">
								{props.person.cv_file ? (
									<>
										<Tooltip title="Завантажити">
											<GetAppIcon
												className="file-item-icons-icon"
												onClick={() =>
													downloadHandler(props.person.cv_file, "cvfiles", "cv")
												}
											/>
										</Tooltip>
										<Tooltip title="Видалити">
											<ClearIcon
												className="file-item-icons-icon"
												onClick={(event) => fileRemoveHandler(event, "cv_file")}
											/>
										</Tooltip>
									</>
								) : (
									<Tooltip title="Додати">
										<PublishIcon
											className="file-item-icons-icon"
											// fontSize="large"
											onClick={cvClickHandler}
										/>
									</Tooltip>
								)}
								<input
									type="file"
									style={{ display: "none" }}
									ref={(input) => {
										cvInput = input;
									}}
									// accept="image/x-png,image/gif,image/jpeg"
									onChange={(event) => fileUploadHandler(event, "cvfiles")}
									name="cv_file"
									// onBlur={inputBlurHandler}
								/>
							</div>
						</div>
						<div
							className={
								"file-item-wrapper " +
								(props.person.registration_file ? "file-exist" : "")
							}
						>
							<span className="file-item-name">Рег. файл</span>
							<div className="file-item-icons-wrapper">
								{props.person.registration_file ? (
									<>
										<Tooltip title="Завантажити">
											<GetAppIcon
												className="file-item-icons-icon"
												onClick={() =>
													downloadHandler(
														props.person.registration_file,
														"registrations",
														"registration-file"
													)
												}
											/>
										</Tooltip>
										<Tooltip title="Видалити">
											<ClearIcon
												className="file-item-icons-icon"
												onClick={(event) =>
													fileRemoveHandler(event, "registration_file")
												}
											/>
										</Tooltip>
									</>
								) : (
									<Tooltip title="Додати">
										<PublishIcon
											className="file-item-icons-icon"
											// fontSize="large"
											onClick={regClickHandler}
										/>
									</Tooltip>
								)}
								<input
									type="file"
									style={{ display: "none" }}
									ref={(input) => {
										regInput = input;
									}}
									// accept="image/x-png,image/gif,image/jpeg"
									onChange={(event) =>
										fileUploadHandler(event, "registrations")
									}
									name="registration_file"
									// onBlur={inputBlurHandler}
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default connect(
	(store) => ({}),
	(dispatch) =>
		bindActionCreators(
			{
				setLoading: Loading,
			},
			dispatch
		)
)(PeopleMainInfo);
