import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "@mui/material/Tooltip";
import logo from "../../flamingo.png";

import NavItem from "./NavItem";

import { useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import * as ApiRequest from "../../api/apiRequest";
import { Session } from "../../actions";

const mainMenuItems = [
	{
		name: "Люди",
		exact: true,
		to: "/people",
		iconClassName: "zmdi zmdi-face",
		active: false,
	},
	{
		name: "Заходи",
		exact: true,
		to: ``,
		iconClassName: "zmdi zmdi-cocktail",
		active: false,
		subMenus: [
			{ name: "Зовнішні", to: "/events/external", active: false },
			{ name: "Внутрішні", to: "/events/internal", active: false },
		],
	},
	// {
	// 	name: "Книги",
	// 	exact: true,
	// 	to: "/books",
	// 	iconClassName: "zmdi zmdi-library",
	// 	active: false,
	// },
	{
		name: "Книги",
		exact: true,
		to: ``,
		iconClassName: "zmdi zmdi-book",
		active: false,
		subMenus: [
			{ name: "Книги", to: "/books", active: false },
			{ name: "Видачі", to: "/loans", active: false },
		],
	},
	// {
	// 	name: "Звіти",
	// 	exact: true,
	// 	to: "/reports",
	// 	iconClassName: "zmdi zmdi-trending-up",
	// },
];

const settingsMenuItems = [
	{
		name: "Адміністратори",
		exact: true,
		to: "/settings/admin",
		iconClassName: "zmdi zmdi-lock",
		active: false,
	},
	{
		name: "Осередки",
		exact: true,
		to: "/settings/branches",
		iconClassName: "zmdi zmdi-flight-takeoff",
		active: false,
	},
	{
		name: "Слухачі",
		exact: true,
		to: ``,
		iconClassName: "zmdi zmdi-account-circle",
		active: false,
		subMenus: [
			{ name: "Загальне", to: "/settings/people/general", active: false },
			{ name: "Третє коло", to: "/settings/people/thirdcircle", active: false },
			{
				name: "Друге коло",
				to: "/settings/people/secondcircle",
				active: false,
			},
		],
	},
	{
		name: "Книги",
		exact: true,
		to: "/settings/books",
		iconClassName: "zmdi zmdi-book",
		active: false,
	},
	{
		name: "Заходи",
		exact: true,
		to: "/settings/events",
		iconClassName: "zmdi zmdi-cocktail",
		active: false,
	},
	{
		name: "Медіа",
		exact: true,
		to: "/settings/media",
		iconClassName: "zmdi zmdi-thumb-up",
		active: false,
	},
];

const Nav = (props) => {
	const cookies = new Cookies();

	const [user, setUser] = useState({});

	useEffect(() => {
		if (props.session.name) {
			setUser(props.session);
		} else {
			let userId = cookies.get("user");
			getUserData(userId);
		}
	}, []);

	// useEffect(() => {
	// 	console.log("session", props.session);
	// }, [props.session]);

	const getUserData = (userId) => {
		console.log("get user data: ", userId);
		if (userId) {
			let request = {
				purpose: "getUserDate",
				userId: userId,
			};
			ApiRequest.apiRequest("AdminConfig", request).then((result) => {
				if (result && result.success) {
					let userObject = {
						...result.user,
						roleName: result.permissions.title,
					};
					setUser(userObject);

					props.Session(userObject);
					cookies.set("user", result.user._id, { path: "/" });
				}
			});
		}
	};

	const logoutHandler = () => {
		cookies.remove("flamingo");
		cookies.remove("user");
		window.location.replace("/login");
	};

	// const [navigationObject, setNavigationObject] = useState(navigationArray);

	useEffect(() => {
		getUserData();
	});

	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	mainMenuItems.map((path, i) => {
		if (path.to == `/${splitLocation[1]}`) {
			path.active = true;
		} else {
			path.active = false;
		}

		if (path.subMenus) {
			path.subMenus.map((sub, j) => {
				if (sub.to.split("/")[1] == `${splitLocation[1]}`) {
					path.active = true;
				}
			});
		}
	});

	settingsMenuItems.map((pathSettings, i) => {
		if (pathSettings.to == `/settings/${splitLocation[2]}`) {
			pathSettings.active = true;
		} else {
			pathSettings.active = false;
		}

		if (pathSettings.subMenus) {
			pathSettings.subMenus.map((sub, j) => {
				if (sub.to.split("/")[2] == `${splitLocation[2]}`) {
					pathSettings.active = true;
				}
			});
		}
	});

	return (
		<>
			<aside id="app_sidebar-left">
				<div id="logo_wrapper">
					<ul>
						<li class="logo-icon">
							<Link to="/">
								<div class="logo">
									<img src={logo} alt="Logo" />
								</div>
								<h1 class="brand-text">Flamingo</h1>
							</Link>
						</li>
					</ul>
				</div>

				<nav id="app_main-menu-wrapper" class="scrollbar">
					<div class="sidebar-inner sidebar-push">
						<ul class="nav nav-pills nav-stacked">
							<li class="sidebar-header">Робоча зона</li>

							{mainMenuItems.map((menuItem, index) => (
								<NavItem
									key={index}
									name={menuItem.name}
									exact={menuItem.exact}
									to={menuItem.to}
									active={menuItem.active}
									subMenus={menuItem.subMenus || []}
									iconClassName={menuItem.iconClassName}
								/>
							))}

							<li class="sidebar-header">Налаштування</li>

							{settingsMenuItems.map((settingsMenuItem, i) => (
								<NavItem
									key={i}
									name={settingsMenuItem.name}
									exact={settingsMenuItem.exact}
									to={settingsMenuItem.to}
									active={settingsMenuItem.active}
									subMenus={settingsMenuItem.subMenus || []}
									iconClassName={settingsMenuItem.iconClassName}
								/>
							))}

							{/* <li>
								<Link to="/settings/admin">
									<i class="zmdi zmdi-lock"></i>Адміністратори
								</Link>
							</li>
							<li>
								<Link to="/settings/branches">
									<i class="zmdi zmdi-book"></i>Осередки
								</Link>
							</li>
							<li>
								<button>
									<i class="zmdi zmdi-account-circle"></i>Слухачі
								</button>
								<ul class="nav-sub">
									<li>
										<Link to="/settings/people/general">Загальне</Link>
									</li>
									<li>
										<Link to="/settings/people/thirdcircle">Третє коло</Link>
									</li>
									<li>
										<Link to="/settings/people/secondcircle">Друге коло</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/settings/books">
									<i class="zmdi zmdi-book"></i>Книги
								</Link>
							</li>

							<li>
								<Link to="/settings/events">
									<i class="zmdi zmdi-cocktail"></i>Заходи
								</Link>
							</li>
							<li>
								<Link to="/settings/media">
									<i class="zmdi zmdi-thumb-up"></i>Медіа
								</Link>
							</li> */}
						</ul>
					</div>
					{user.name ? (
						<div className="nav-admin-wrapper">
							<div className="nav-admin-person-wrapper">
								<span className="nav-admin-person-name">{`${user.name} ${user.surname}`}</span>
								<span className="nav-admin-person-role">{user.roleName}</span>
							</div>
							<div className="nav-admin-logout-wrapper">
								<Tooltip title="Log out" placement="right">
									<IconButton aria-label="delete" onClick={logoutHandler}>
										<ExitToAppIcon
											className="nav-admin-logout-icon"
											fontSize="medium"
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title="Change password" placement="right">
									<NavLink to="/changepassword">
										<IconButton aria-label="delete" onClick={false}>
											<LockIcon
												className="nav-admin-logout-icon"
												fontSize="medium"
											/>
										</IconButton>
									</NavLink>
								</Tooltip>
							</div>
						</div>
					) : (
						""
					)}
				</nav>
			</aside>
		</>
	);
};

// export default Nav;

export default connect(
	(store) => ({
		session: store.session,
		isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Session,
			},
			dispatch
		)
)(Nav);
