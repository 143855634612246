import Cookies from "universal-cookie";
import jwt from "jsonwebtoken";
import { isExpired } from "react-jwt";

const cookies = new Cookies();

class Auth {
	constructor() {
		// let authToken = cookies.get("flamingo");
		// this.authenticated = !!cookies.get("flamingo");
		// var decodedToken = jwt.decode(authToken, { complete: true });
		// var dateNow = new Date();
		// if (decodedToken) {
		// 	if (decodedToken.exp < dateNow.getTime()) {
		// 		this.authenticated = false;
		// 	}
		// } else {
		// 	this.authenticated = false;
		// }
	}

	isAuthenticated() {
		let authToken = cookies.get("flamingo", { path: "/" });
		// this.authenticated = !!cookies.get("flamingo");

		var decodedToken = jwt.decode(authToken);
		var dateNow = new Date();

		if (decodedToken) {
			if (decodedToken.exp * 1000 < dateNow.getTime()) {
				this.authenticated = false;
			} else {
				this.authenticated = true;
			}
		} else {
			this.authenticated = false;
		}

		return this.authenticated;
	}
}

export default new Auth();
