import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";
import { useErrorHandler } from "react-error-boundary";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";

// import InputMask from "react-input-mask";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../actions";

import PeopleMainInfo from "../people/PeopleMainInfo";
import PeopleThirdCircleInfo from "../people/PeopleThirdCircleInfo";
import PeopleAdditionalInfo from "../people/PeopleAdditionalInfo";
import PeoplePrivateDetails from "../people/PeoplePrivateDetails";
import PeopleProfileActionButtons from "../people/PeopleProfileActionButtons";
import PeopleVolunteerInfo from "../people/PeopleVolunteerInfo";
import PeopleProbationistInfo from "../people/PeopleProbationistInfo";
import PeopleMemberInfo from "../people/PeopleMemberInfo";
import PeopleCandidateInfo from "../people/PeopleCandidateInfo";
import PeopleLiveForceInfo from "../people/PeopleLiveForceInfo";
import PeopleInstructorCandidateInfo from "../people/PeopleInstructorCandidateInfo";
import PeopleInstructorInfo from "../people/PeopleInstructorInfo";
import PeopleAxeBearerInfo from "../people/PeopleAxeBearerInfo";

const PeopleEdit = (props) => {
	const handleError = useErrorHandler();

	let history = useHistory();

	const [person, setPerson] = useState({});

	const [acordeonsOpen, setAcordeonsOpen] = useState({
		MainInfo: true,
		ThirdCircle: false,
		AditionalDetails: false,
		PrivateDetails: false,
		Volunteer: false,
		Probationist: true,
		Member: true,
		Candidate: true,
		LiveForce: true,
		Instructor: true,
		AxeBearer: true,
	});

	const host = "People";
	const endpointPurposes = {
		getData: "get",
		getItem: "getOneItem",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
		getDropdownData: "getDropdownData",
	};

	const [peopleConstants, setPeopleConstants] = useState({
		branches: undefined,
	});

	const [drawerOpen, setDrawerOpen] = useState(false);

	const [nameError, setNameError] = useState(false);
	const [surnameError, setSurnameError] = useState(false);

	useEffect(() => {
		const personId = props.match.params.param;
		props.setLoading(true);

		getPersonData(personId);
		// getDropdownData();
	}, []);

	const getPersonData = (personId) => {
		console.log("get person: ", personId);
		let request = {
			purpose: endpointPurposes.getItem,
			body: {
				id: personId,
				slug: personId,
			},
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			try {
				if (result.success) {
					console.log("test result person edit: ", result);
					setPerson(result.person);
					setPeopleConstants({
						libraries: result.libraries,
						permissions: result.permissions,
						categories: result.categories,
						languages: result.languages,
						values: result.values,
						branches: result.branches,
						initialContactMedia: result.initialContactMedias,
						initialContactChannels: result.initialContactChannels,
						interests: result.interests,
						externalNewsletters: result.externalNewsletters,
						internalNewsletters: result.internalNewsletters,
						academicLevels: result.academicLevels,
						membersOrigins: result.membersOrigins,
						livingForceLogos: result.livingForceLogos,
						probationistOrigins: result.probationistOrigins,
						probationistSubscriptionMeans: result.probationistSubscriptionMeans,
						probationistRemoveStatuses: result.probationistRemoveStatuses,
						memberRemoveStatuses: result.memberRemoveStatuses,
						departureReasons: result.departureReasons,
						hierarchy: result.hierarchy,
						secretary: result.secretary,
						courses: result.courses,
						courseQualification: result.courseQualification,
						candidateStages: result.candidateStages,
						liveForceOrigins: result.liveForceOrigins,
						liveForceLeadershipLevels: result.liveForceLeadershipLevels,
						liveForceInternationalRoles: result.liveForceInternationalRoles,
						instructorOrigins: result.instructorOrigins,
						bonfires: result.bonfires,
						axes: result.axes,
					});

					props.setLoading(false);
				} else {
					props.setLoading(false);
					throw new Error("not exist");
				}
			} catch (error) {
				handleError(error);
			}
		});
	};

	const handleAcordeonChange = (panel) => (event, newExpanded) => {
		setAcordeonsOpen({
			...acordeonsOpen,
			[panel]: newExpanded,
		});
	};

	const handleInputChange = (event) => {
		console.log("update: ", event.target.name, event.target.value);
		setPerson({
			...person,
			[event.target.name]: event.target.value,
		});
	};

	const handleSwitchChange = (event) => {
		setPerson({
			...person,
			[event.target.name]: event.target.checked,
		});
		itemUpdateApiCall(event.target.name, event.target.checked);
	};

	const handleFileInputChange = (field) => {
		// setPerson({
		// 	...person,
		// 	[field.name]: field.value,
		// });
	};

	const handleItemUpdate = (event) => {
		console.log("update: ", event.target.name, event.target.value);
		itemUpdateApiCall(event.target.name, event.target.value);
	};

	const itemUpdateApiCall = (name, value, newFileField) => {
		let valid = true;

		if (name == "name") {
			if (!value) {
				valid = false;
				setNameError(true);
			} else {
				setNameError(false);
			}
		}

		if (name == "surname") {
			if (!value) {
				valid = false;
				setSurnameError(true);
			} else {
				setSurnameError(false);
			}
		}

		if (valid) {
			let personObject = {
				[name]: value,
				_id: person._id,
			};

			let request = {
				purpose: endpointPurposes.updateItem,
				// body: person,
				body: personObject,
				newFileField: newFileField,
			};

			console.log("update call: ", request);

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getPersonData(person.slug);
				} else {
					console.log("Error updating");
				}
			});
		}
	};

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setDrawerOpen(open);
	};

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm book-create-edit-header">
						<div class="container-fluid">
							<div class="row">
								<div class="col-md-12">
									<header id="header">
										<div className="card-actions-left-wrapper">
											<Button onClick={() => history.goBack()}>
												<IconButton
													aria-label="Перегляд/Редагувати"
													// onClick={() => deleteItemHandler(value)}
												>
													<ArrowBackIcon className="header-back-arrow" />
												</IconButton>
											</Button>

											<h1>
												{person.name ? person.name + " " + person.surname : ""}
											</h1>
										</div>
										<ul class="card-actions icons right-top">
											<li className="page-controlls-right-item">
												{/* <Button
													onClick={toggleDrawer(true)}
													variant="outlined"
													// color="white"
													startIcon={<SettingsEthernetIcon />}
													className="header-drawer-button"
												>
													Дії з профайлом
												</Button> */}
												{person.name && peopleConstants.livingForceLogos ? (
													<PeopleProfileActionButtons
														person={person}
														drawerOpen={drawerOpen}
														membersOrigins={peopleConstants.membersOrigins}
														livingForceLogos={peopleConstants.livingForceLogos}
														probationistOrigins={
															peopleConstants.probationistOrigins
														}
														probationistSubscriptionMeans={
															peopleConstants.probationistSubscriptionMeans
														}
														probationistRemoveStatuses={
															peopleConstants.probationistRemoveStatuses
														}
														departureReasons={peopleConstants.departureReasons}
														branches={peopleConstants.branches}
														hierarchy={peopleConstants.hierarchy}
														secretary={peopleConstants.secretary}
														memberRemoveStatuses={
															peopleConstants.memberRemoveStatuses
														}
														candidateStages={peopleConstants.candidateStages}
														bonfires={peopleConstants.bonfires}
														axes={peopleConstants.axes}
														getPersonData={getPersonData}
													/>
												) : (
													""
												)}
											</li>
										</ul>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="buttons-header-wrapper">
					<Button onClick={toggleDrawer(true)}>Дії з профайлом</Button>
				</div> */}
				<div class="content-body">
					<div id="card_content" class="tab-content p-20 books-edit-wrapper">
						<div class="row">
							<div class="col-xs-12">
								<div class="content">
									<header class="content-heading"></header>
									<div class="content-body m-t-20">
										<div className="col-md-6">
											{/* <h2>Основна інформація</h2> */}
											{person.name && peopleConstants.branches ? (
												<Accordion
													expanded={acordeonsOpen.MainInfo}
													onChange={handleAcordeonChange("MainInfo")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="main-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Основна інформація
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														{person.name ? (
															<PeopleMainInfo
																person={person}
																branches={peopleConstants.branches}
																handleInputChange={handleInputChange}
																handleFileInputChange={handleFileInputChange}
																handleItemUpdate={handleItemUpdate}
																itemUpdateApiCall={itemUpdateApiCall}
																nameError={nameError}
																surnameError={surnameError}
															/>
														) : (
															""
														)}
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											<Accordion
												expanded={acordeonsOpen.PrivateDetails}
												onChange={handleAcordeonChange("PrivateDetails")}
												className="people-info-accordeon"
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="main-content"
													id="main-header"
													className="panel-form-panel-summary"
												>
													<h2 className="panel-form-panel-header">
														Приватна інформація
													</h2>
												</AccordionSummary>
												<AccordionDetails className="acordion-form-body">
													{person.name ? (
														<PeoplePrivateDetails
															person={person}
															handleInputChange={handleInputChange}
															handleFileInputChange={handleFileInputChange}
															handleItemUpdate={handleItemUpdate}
															itemUpdateApiCall={itemUpdateApiCall}
															handleSwitchChange={handleSwitchChange}
														/>
													) : (
														""
													)}
												</AccordionDetails>
											</Accordion>
											{person.isProbationist &&
											peopleConstants.probationistSubscriptionMeans ? (
												<Accordion
													expanded={acordeonsOpen.Probationist}
													onChange={handleAcordeonChange("Probationist")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="volunteer-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація пробаціоніста
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleProbationistInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															probationistSubscriptionMeans={
																peopleConstants.probationistSubscriptionMeans
															}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isCandidate && peopleConstants.candidateStages ? (
												<Accordion
													expanded={acordeonsOpen.Candidate}
													onChange={handleAcordeonChange("Candidate")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="volunteer-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація кандидата
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleCandidateInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															candidateStages={peopleConstants.candidateStages}
															instructorOrigins={
																peopleConstants.instructorOrigins
															}
															livingForceLogos={
																peopleConstants.livingForceLogos
															}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isLiveForce &&
											peopleConstants.liveForceOrigins ? (
												<Accordion
													expanded={acordeonsOpen.LiveForce}
													onChange={handleAcordeonChange("LiveForce")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="volunteer-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація ЖС
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleLiveForceInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															livingForceLogos={
																peopleConstants.livingForceLogos
															}
															liveForceOrigins={
																peopleConstants.liveForceOrigins
															}
															liveForceInternationalRoles={
																peopleConstants.liveForceInternationalRoles
															}
															liveForceLeadershipLevels={
																peopleConstants.liveForceLeadershipLevels
															}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isAxeBearer && peopleConstants.axes ? (
												<Accordion
													expanded={acordeonsOpen.AxeBearer}
													onChange={handleAcordeonChange("AxeBearer")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="volunteer-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація Сикіріча
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleAxeBearerInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															getPersonData={getPersonData}
															axes={peopleConstants.axes}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
										</div>
										<div className="col-md-6 books-right-column-wrapper">
											<Accordion
												expanded={acordeonsOpen.ThirdCircle}
												onChange={handleAcordeonChange("ThirdCircle")}
												className="people-info-accordeon"
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="main-content"
													id="third-circle-header"
													className="panel-form-panel-summary"
												>
													<h2 className="panel-form-panel-header">
														Інформація третього кола
													</h2>
												</AccordionSummary>
												<AccordionDetails className="acordion-form-body">
													{peopleConstants.initialContactMedia ? (
														<PeopleThirdCircleInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															initialContactMedia={
																peopleConstants.initialContactMedia
															}
															initialContactChannels={
																peopleConstants.initialContactChannels
															}
															interests={peopleConstants.interests}
															externalNewsletters={
																peopleConstants.externalNewsletters
															}
														/>
													) : (
														""
													)}
												</AccordionDetails>
											</Accordion>
											<Accordion
												expanded={acordeonsOpen.AditionalDetails}
												onChange={handleAcordeonChange("AditionalDetails")}
												className="people-info-accordeon"
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="main-content"
													id="additional-details-header"
													className="panel-form-panel-summary"
												>
													<h2 className="panel-form-panel-header">
														Додаткова інформація
													</h2>
												</AccordionSummary>
												<AccordionDetails className="acordion-form-body">
													{peopleConstants.academicLevels ? (
														<PeopleAdditionalInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															academicLevels={peopleConstants.academicLevels}
														/>
													) : (
														""
													)}
												</AccordionDetails>
											</Accordion>

											{person.isVolunteer ? (
												<Accordion
													expanded={acordeonsOpen.Volunteer}
													onChange={handleAcordeonChange("Volunteer")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="volunteer-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Волонтерська інформація
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleVolunteerInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isMember && peopleConstants.courses ? (
												<Accordion
													expanded={acordeonsOpen.Member}
													onChange={handleAcordeonChange("Member")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="member-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Членська інформація
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleMemberInfo
															person={person}
															branches={peopleConstants.branches}
															hierarchy={peopleConstants.hierarchy}
															secretary={peopleConstants.secretary}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															getPersonData={getPersonData}
															courses={peopleConstants.courses}
															courseQualification={
																peopleConstants.courseQualification
															}
															internalNewsletters={
																peopleConstants.internalNewsletters
															}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isInstructorCandidate ? (
												<Accordion
													expanded={acordeonsOpen.Member}
													onChange={handleAcordeonChange("Member")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="member-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація кандадата в Інструктори
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleInstructorCandidateInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															getPersonData={getPersonData}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
											{person.isInstructor &&
											peopleConstants.instructorOrigins ? (
												<Accordion
													expanded={acordeonsOpen.Instructor}
													onChange={handleAcordeonChange("Instructor")}
													className="people-info-accordeon"
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="main-content"
														id="member-header"
														className="panel-form-panel-summary"
													>
														<h2 className="panel-form-panel-header">
															Інформація Інструктора
														</h2>
													</AccordionSummary>
													<AccordionDetails className="acordion-form-body">
														<PeopleInstructorInfo
															person={person}
															handleInputChange={handleInputChange}
															handleItemUpdate={handleItemUpdate}
															getPersonData={getPersonData}
															instructorOrigins={
																peopleConstants.instructorOrigins
															}
															courses={peopleConstants.courses}
															bonfires={peopleConstants.bonfires}
														/>
													</AccordionDetails>
												</Accordion>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default connect(
	(store) => ({}),
	(dispatch) =>
		bindActionCreators(
			{
				setLoading: Loading,
			},
			dispatch
		)
)(PeopleEdit);
