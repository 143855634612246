import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

const NavItem = (props) => {
	const { name, subMenus, iconClassName, onClick, to, exact, active } = props;
	const [expand, setExpand] = useState(false);

	useEffect(() => {
		if (active) {
			setExpand(true);
		} else {
			setExpand(false);
		}
	}, [active]);

	if (subMenus && subMenus.length > 0) {
		return (
			<>
				<li
					className={`nav-dropdown ${expand === true ? "open" : ""} ${
						active ? "active" : ""
					}`}
				>
					<a
						name={name}
						onClick={() => setExpand(!expand)}
						className="nav-dropdown-head"
					>
						<i className={props.iconClassName} />
						{name}
					</a>
					<ul className={`nav-sub`}>
						{subMenus.map((menu, index) => (
							<li key={index}>
								<NavLink to={menu.to}>{menu.name}</NavLink>
							</li>
						))}
					</ul>
				</li>
			</>
		);
	} else {
		return (
			<>
				<li className={active ? "active" : ""}>
					<NavLink exact to={to} className={``}>
						<i className={props.iconClassName} />
						{name}
					</NavLink>
				</li>
			</>
		);
	}
};

// 	return (
// 		<>
// 			<li
// 				className={`${subMenus && subMenus.length > 0 ? "nav-dropdown" : ""} ${
// 					expand === true ? "open" : ""
// 				} ${active ? "active" : ""}`}
// 			>
// 				{subMenus && subMenus.length > 0 ? (
// 					<a
// 						name={name}
// 						onClick={() => setExpand(!expand)}
// 						className="nav-dropdown-head"
// 					>
// 						<i className={props.iconClassName} />
// 						{name}
// 					</a>
// 				) : (
// 					<NavLink
// 						exact
// 						to={to}
// 						// onClick={() => {
// 						//   setExpand((e) => !e);
// 						// }}
// 						className={``}
// 					>
// 						<i className={props.iconClassName} />
// 						{name}
// 					</NavLink>
// 				)}
// 				{subMenus && subMenus.length > 0 ? (
// 					<ul className={`nav-sub`}>
// 						{subMenus.map((menu, index) => (
// 							<li key={index}>
// 								<NavLink to={menu.to}>{menu.name}</NavLink>
// 							</li>
// 						))}
// 					</ul>
// 				) : null}
// 			</li>
// 		</>
// 	);
// };

export default NavItem;
