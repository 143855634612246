import React, { useState, useEffect } from "react";

import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/lab/Autocomplete";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";

import Moment from "moment";
import Cookies from "universal-cookie";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import { Loading } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as ApiRequest from "../../api/apiRequest";
import { tableSettings } from "../../configs/visualizationConfig";

const Loans = (props) => {
	let fileInput = null;

	const cookies = new Cookies();

	const host = "Loan";
	const endpointPurposes = {
		getData: "get",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
		closeLoan: "return",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [returnDialogOpen, setReturnDialogOpen] = useState(false);

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [isReturnState, setIsreturnState] = useState(false);

	const [isExternal, setIsExternal] = useState(false);
	// const [isExternalText, setIsExternalText] = useState("");

	//// !!! Change the structure !!!
	const [newItem, setNewItem] = useState({
		id: "",
		book: "",
		person: null,
		librarian: "",
		dueDate: "",
		isActive: "",
		closeDate: "",
		library: "",
	});

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState({
		id: "",
		book: "",
		person: null,
		librarian: "",
		dueDate: "",
		isActive: "",
		closeDate: "",
		library: "",
	});

	const [libraries, setLibraries] = useState([]);
	const [people, setPeople] = useState([]);
	const [books, setBooks] = useState([]);

	// const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	// const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [loanBookError, setLoanBookError] = useState(false);
	const [loanBookErrorText, setLoanBookErrorText] = useState("");

	const [loanPersonError, setLoanPersonError] = useState(false);
	const [loanPersonErrorText, setLoanPersonErrorText] = useState("");

	const [loanDueDateError, setLoanDueDateError] = useState(false);
	const [loanDueDateErrorText, setLoanDueDateErrorText] = useState("");

	const [loanCloseDateError, setLoanCloseDateError] = useState(false);
	const [loanCloseDateErrorText, setLoanCloseDateErrorText] = useState("");

	const getItemsFromApi = (external) => {
		// let external = isExternal;
		// if (external == undefined) {
		// 	external = externalCall;
		// }
		let request = {
			purpose: endpointPurposes.getData,
		};

		props.Loading(true);

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			console.log("loans result: ", result);
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let bookOfLoan = result.books.find((bo) => bo._id == element.book);

					let tempArray = [];
					tempArray.push(result.books.find((b) => b._id == element.book).title);
					tempArray.push(
						bookOfLoan.library
							? result.libraries.find((l) => l._id == bookOfLoan.library).title
							: ""
					);
					tempArray.push(
						result.people.find((p) => p._id == element.person).name +
							" " +
							result.people.find((p2) => p2._id == element.person).surname
					);
					tempArray.push({ dueDate: element.dueDate, active: element.active });
					tempArray.push(element.active);
					tempArray.push(element.returnDate);
					tempArray.push(
						result.people.find((p3) => p3._id == element.librarian).name +
							" " +
							result.people.find((p4) => p4._id == element.librarian).surname
					);
					tempArray.push(element._id);

					dataArray.push(tempArray);
				});

				dataArray.sort(function(x, y) {
					return x[4] === y[4] ? 0 : x ? -1 : 1;
				});

				setItems(result.items);
				setTableData(dataArray);
				setPeople(result.people);
				setBooks(result.books);
				setLibraries(result.libraries);

				props.Loading(false);
			}
		});
		// props.Loading(false);
	};

	useEffect(() => {
		getItemsFromApi();
	}, []);

	//// !!! Change the structure !!!
	const columns = [
		"Книга",
		"Бібліотека",
		"Читач",
		// "Термін",
		{
			name: "Термін",
			label: "Термін",
			options: {
				customBodyRender: (value) => (
					<>
						<span
							className={
								Moment().isSameOrAfter(value.dueDate) && value.active
									? "red"
									: ""
							}
						>
							{value.dueDate}
						</span>
					</>
				),
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Статус",
			label: "Статус",
			options: {
				customBodyRender: (value) => (value ? "Відкрито" : "Закрито"),
				display: true,
				filter: true,
				sort: true,
			},
		},
		"Дата закриття",
		"Видав",
		{
			name: "",
			label: "Дії",
			options: {
				customBodyRender: (value) => (
					<>
						<div className="table-row-action-people-buttons-wrapper">
							{items.find((i) => i._id == value).active ? (
								<Tooltip title="Повернути книгу">
									<IconButton
										aria-label="Повернути"
										onClick={() => closeLoanHandler(value)}
									>
										<AlarmOffIcon />
									</IconButton>
								</Tooltip>
							) : (
								""
							)}
						</div>
					</>
				),
				filter: false,
				sort: false,
				search: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		setNewItemStateSave({
			...newItem,
			[event.target.name]: event.target.value,
		});

		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
	};

	const closeLoanHandler = (loanId) => {
		let loanToReturn = items.find((l) => l._id == loanId);
		setIsreturnState(true);
		setNewItem(loanToReturn);
		setSaveStateItemInput({});
		setReturnDialogOpen(true);
	};

	const returnDialogCloseHandler = () => {
		setIsreturnState(false);
		setNewItem({});
		setSaveStateItemInput({});
		setReturnDialogOpen(false);

		setLoanCloseDateError(false);
		setLoanCloseDateErrorText("");
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem({});
		setDialogOpen(false);
		setNewItem({});
		setSaveStateItemInput(false);

		setLoanBookError(false);
		setLoanBookErrorText("");

		setLoanPersonError(false);
		setLoanPersonErrorText("");

		setLoanDueDateError(false);
		setLoanDueDateErrorText("");
	};

	const autocompleteHandler = (event, value, itemType) => {
		if (value) {
			setNewItemStateSave({
				...newItem,
				[itemType]: value._id,
			});

			setNewItem({
				...newItem,
				[itemType]: value._id,
			});
		} else {
			setNewItemStateSave({
				...newItem,
				[itemType]: null,
			});

			setNewItem({
				...newItem,
				[itemType]: null,
			});
		}
	};

	const validateFormLoan = (dueDate) => {
		let valid = true;

		if (!newItem.book) {
			valid = false;
			setLoanBookError(true);
			setLoanBookErrorText("Поле обов’язкове");
		} else {
			setLoanBookError(false);
			setLoanBookErrorText("");
		}

		if (!newItem.person) {
			valid = false;
			setLoanPersonError(true);
			setLoanPersonErrorText("Поле обов’язкове");
		} else {
			setLoanPersonError(false);
			setLoanPersonErrorText("");
		}

		if (!dueDate) {
			valid = false;
			setLoanDueDateError(true);
			setLoanDueDateErrorText("Поле обов’язкове");
		} else {
			setLoanDueDateError(false);
			setLoanDueDateErrorText("");
		}

		return valid;
	};

	const validateFormCloseLoan = (closeDate) => {
		let valid = true;

		if (!closeDate) {
			valid = false;
			setLoanCloseDateError(true);
			setLoanCloseDateErrorText("Поле обов’язкове");
		} else {
			setLoanCloseDateError(false);
			setLoanCloseDateErrorText("");
		}

		return valid;
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const data = new FormData(event.target);

		let isValid = validateFormLoan(data.get("dueDate"));

		if (isValid) {
			let librarian = cookies.get("person", { path: "/" });

			var item = {
				book: newItem.book,
				person: newItem.person,
				dueDate: data.get("dueDate"),
				librarian: librarian,
				loanData: Moment().format("YYYY-MM-DD"),
			};

			// currentFormStructure.form.forEach((element) => {
			// 	item[element.name] = data.get(element.name);
			// });

			let request = {
				purpose: endpointPurposes.createItem,
				body: item,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					dialogCancelHandler();
					// setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage(
						`Книгу ${books.find((b) => b._id == item.book).title} видано`
					);
				} else if (result.message == "exist") {
					// setSnackBarOpen(true);
					// setSnackBarSeverity("error");
					// setSnackBarMessage(`Подія ${newItem.title} вже існує`);
				}
			});
		}
	};

	const handleReturnFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const data = new FormData(event.target);

		let isValid = validateFormCloseLoan(data.get("returnDate"));

		if (isValid) {
			let librarian = cookies.get("person", { path: "/" });

			var item = {
				loan: newItem._id,
				loanReturnDate: data.get("returnDate"),
				librarian: librarian,
			};

			let request = {
				purpose: endpointPurposes.closeLoan,
				body: item,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					returnDialogCloseHandler();
					// setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage(`Книгу повернуто`);
				} else {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(`Сталась помилка`);
				}
			});
		}
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi(isExternal);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Видалено " + itemsWaitingForDelete.length + " подій"
				);
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обрану подію?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index]._id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " подій?"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				// let parentItem = "";
				// let parentItemId = "";
				// let excelParentItem = data[index]["Материнський осередок"];

				// if (excelParentItem) {
				// 	items.forEach((element) => {
				// 		if (element.title == excelParentItem) {
				// 			parentItem = element.title;
				// 			parentItemId = element.id;
				// 		}
				// 	});
				// }

				itemsUpload.push({
					title: data[index]["Подія"],
					location: data[index]["Локація"],
					event_type: data[index]["Тип"],
					branch: data[index]["Осередок"],
					start_date: data[index]["Дата"],
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi(isExternal);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage("Було завантажено " + counter + " людей!");
				}
			});
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "People-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			dataArray.push({
				Подія: element.title,
				Локація: element.location,
				Тип: element.event_type,
				Осередок: element.branch,
				Дата: element.start_date,
				"Кількість участників": element.participants_total,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-md-12">
									<header id="header">
										<h1>Видачі книг</h1>
										<ol class="breadcrumb">
											{/* <li>
												<a href="index.html">Головна</a>
											</li> */}
											{/* <li>
												<a href="javascript:void(0)">Налаштування</a>
											</li> */}
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="categories">
							<div class="row">
								<div class="col-xs-12">
									<div class="content">
										<header class="content-heading">
											<div className="card-actions-left-wrapper">
												<button
													class="btn btn-green"
													onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Видати книгу
												</button>
											</div>
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={fileClickHandler}
													>
														<i class="zmdi zmdi-upload"></i>
														<input
															type="file"
															style={{ display: "none" }}
															ref={(input) => {
																fileInput = input;
															}}
															accept=".csv, .xls, .xlsx"
															onChange={fileUploadHandler}
														/>
														{/* /> */}
													</button>
												</li>
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={prepareDataToExport}
													>
														<i class="zmdi zmdi-download"></i>
													</button>
												</li>
											</ul>
										</header>
										<div class="content-body m-t-20">
											<MUIDataTable
												title={"Видачі книг"}
												data={tableData}
												columns={columns}
												options={options}
											/>
										</div>
									</div>
								</div>

								{/* Create dialog */}

								<Dialog
									open={dialogOpen}
									onClose={dialogCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div class="modal-dialog">
										<form onSubmit={handleFormSave} noValidate>
											<div class="modal-content">
												<DialogTitle className="modal-header">
													<h4 class="modal-title" id="myModalLabel-2">
														Видати книгу
													</h4>
													<ul class="card-actions icons right-top">
														<a
															href="javascript:void(0)"
															data-dismiss="modal"
															class="text-white"
															aria-label="Close"
															onClick={dialogCloseHandler}
														>
															<i class="zmdi zmdi-close"></i>
														</a>
													</ul>
												</DialogTitle>
												<div class="modal-body">
													{/* <form onSubmit={handleFormSave}> */}
													<Autocomplete
														id="library"
														name="library"
														// className="admin-select-person-autocomplete-field"
														options={libraries}
														// disabled={updateMode ? true : false}
														getOptionLabel={(option) => `${option.title}`}
														onChange={(e, v) =>
															autocompleteHandler(e, v, "library")
														}
														// value={newItem.user}
														// style={{ width: 300 }}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Бібліотека"
																// variant="outlined"
															/>
														)}
													/>
													<Autocomplete
														id="book"
														name="book"
														// className="admin-select-person-autocomplete-field"
														options={books.filter((b) => {
															if (newItem.library) {
																return (
																	b.isLoaned == false &&
																	b.library == newItem.library
																);
															} else {
																return b.isLoaned == false;
															}
														})}
														// disabled={updateMode ? true : false}
														getOptionLabel={(option) =>
															`${option.title} - ${option.author} (${option.bookIdentifier})`
														}
														onChange={(e, v) =>
															autocompleteHandler(e, v, "book")
														}
														// value={newItem.user}
														// style={{ width: 300 }}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Оберіть книгу"
																error={loanBookError}
																helperText={loanBookErrorText}
																// variant="outlined"
															/>
														)}
													/>

													<Autocomplete
														id="person"
														name="person"
														// className="admin-select-person-autocomplete-field"
														options={people}
														// disabled={updateMode ? true : false}
														getOptionLabel={(option) =>
															`${option.name} ${option.surname}`
														}
														onChange={(e, v) =>
															autocompleteHandler(e, v, "person")
														}
														// value={newItem.user}
														// style={{ width: 300 }}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Кому видається"
																error={loanPersonError}
																helperText={loanPersonErrorText}
																// variant="outlined"
															/>
														)}
													/>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="dueDate"
															name="dueDate"
															label="Термін повернення"
															type="date"
															required
															defaultValue={Moment()
																.add(7, "days")
																.format("YYYY-MM-DD")}
															// onChange={props.handleInputChange}
															// onBlur={loanBlurHandler}
															InputLabelProps={{
																shrink: true,
															}}
															// helperText={
															// 	dueDateError ? "Поле обов'язкове" : ""
															// }
															// error={dueDateError}
															error={loanDueDateError}
															helperText={loanDueDateErrorText}
														/>
													</FormControl>

													{/* </form> */}
												</div>
												<div class="modal-footer">
													<button
														type="button"
														class="btn btn-default btn-flat"
														data-dismiss="modal"
														onClick={dialogCancelHandler}
													>
														Скасувати
													</button>

													<button
														type="submit"
														class="btn btn-primary"
														// onClick={handleFormSave}
														// onClick={validateForm}
													>
														Видати
													</button>
												</div>
											</div>
										</form>
									</div>
								</Dialog>

								{books && newItem.book && newItem.person != "" && people ? (
									<Dialog
										open={returnDialogOpen}
										onClose={returnDialogCloseHandler}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										className="flamingo-modal"
									>
										<div class="modal-dialog">
											<form onSubmit={handleReturnFormSave} noValidate>
												<div class="modal-content">
													<DialogTitle className="modal-header">
														<h4 class="modal-title" id="myModalLabel-2">
															{`Повернення книги: ${
																books.find((b) => b._id == newItem.book).title
															}`}
														</h4>
														<ul class="card-actions icons right-top">
															<a
																href="javascript:void(0)"
																data-dismiss="modal"
																class="text-white"
																aria-label="Close"
																onClick={returnDialogCloseHandler}
															>
																<i class="zmdi zmdi-close"></i>
															</a>
														</ul>
													</DialogTitle>
													<div class="modal-body">
														{/* <form onSubmit={handleFormSave}> */}

														{newItem.person ? (
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="person"
																	name="person"
																	label="Читач"
																	disabled
																	value={`${
																		people.find((p) => p._id == newItem.person)
																			.name
																	} ${
																		people.find((p) => p._id == newItem.person)
																			.surname
																	}`}
																	// onChange={props.handleInputChange}
																	// onBlur={loanBlurHandler}
																/>
															</FormControl>
														) : (
															""
														)}
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="dueDate"
																name="dueDate"
																label="Плановий термін повернення"
																type="date"
																required
																disabled
																defaultValue={Moment()
																	.add(7, "days")
																	.format("YYYY-MM-DD")}
																// onChange={props.handleInputChange}
																// onBlur={loanBlurHandler}
																InputLabelProps={{
																	shrink: true,
																}}
																// helperText={
																// 	dueDateError ? "Поле обов'язкове" : ""
																// }
																// error={dueDateError}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="returnDate"
																name="returnDate"
																label="Дата повернення"
																type="date"
																required
																defaultValue={Moment().format("YYYY-MM-DD")}
																// onChange={props.handleInputChange}
																// onBlur={loanBlurHandler}
																error={loanCloseDateError}
																helperText={loanCloseDateErrorText}
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</FormControl>

														{/* </form> */}
													</div>
													<div class="modal-footer">
														<button
															type="button"
															class="btn btn-default btn-flat"
															data-dismiss="modal"
															onClick={dialogCancelHandler}
														>
															Скасувати
														</button>

														<button
															type="submit"
															class="btn btn-primary"
															// onClick={handleFormSave}
															// onClick={validateForm}
														>
															Повернути
														</button>
													</div>
												</div>
											</form>
										</div>
									</Dialog>
								) : (
									""
								)}

								<Dialog
									open={alertOpen}
									onClose={alertCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div className="flamingo-alert-body-wrapper">
										<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
										{alertWarningMessage}
									</div>
									<div className="flamingo-alert-buttons-wrapper">
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={alertCloseHandler}
										>
											Скасувати
										</button>
										{alertActionButton}
									</div>
								</Dialog>
								<Snackbar
									open={snackBarOpen}
									autoHideDuration={6000}
									onClose={handleSnackBarClose}
								>
									<MuiAlert
										onClose={handleSnackBarClose}
										severity={snackBarSeverity}
										elevation={6}
										variant="filled"
									>
										{snackBarMessage}
									</MuiAlert>
								</Snackbar>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default connect(
	(store) => ({
		isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Loading,
			},
			dispatch
		)
)(Loans);
