//const host = "api";

// const host =
// 	process.env.NODE_ENV === "production"
//  		? process.env.REACT_APP_PROD_API_URL
//  		: process.env.REACT_APP_DEV_API_URL;

const host = "https://flamingo-dev.newacropolis.org.ua/api";
// const host = "http://localhost:8088";

// const host = "https://flamingo-api.herokuapp.com";

const apiConfig = {
	key: "oinaukr2021",
	Main: `${host}`,
	BooksConfig: `${host}/config/books/`,
	BranchesConfig: `${host}/config/branches/`,
	EventsConfig: `${host}/config/events/`,
	MediaConfig: `${host}/config/media/`,
	PeopleConfig: `${host}/config/people/`,
	PeopleConfigGeneral: `${host}/config/people/general/`,
	PeopleConfigSettings: `${host}/config/people/peopleconfig/`,
	HierarchySettings: `${host}/config/people/hierarchy/`,
	SecretarySettings: `${host}/config/people/secretary/`,
	AcademicLevels: `${host}/config/people/academiclevels/`,
	LibrarySettings: `${host}/config/libraries/`,
	PromoEventSettings: `${host}/config/promoevents`,
	AdminConfig: `${host}/config/admin`,
	Login: `${host}/login/`,
	ForgotPassword: `${host}/forgotpassword`,
	ForgotPasswordCheck: `${host}/forgotpassword/check`,
	ForgotPasswordChange: `${host}/forgotpassword/change`,
	Books: `${host}/books/`,
	BookCoverUpload: `${host}/upload/bookcover`,
	Loan: `${host}/loans/`,
	People: `${host}/people`,
	PeopleUpload: `${host}/upload/upload`,
	PeopleDownload: `${host}/upload/download`,
	Events: `${host}/events`,
};

export default apiConfig;
