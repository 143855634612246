import { SET_LOGIN_ERROR, SET_SESSION, HIDE_LOADER } from "./actionsType";
import apiConfig from "../api/apiConfig";
import axios from "axios";

import Cookies from "universal-cookie";
import { isExpired, decodeToken } from "react-jwt";
import { useHistory } from "react-router-dom";

export const actionLogin = (purpose, request, locationState) => {
	return async (dispatch) => {
		try {
			// dispatch({ type: SHOW_LOADER });

			console.log("login debug: ", apiConfig[purpose]);

			const data = await axios.post(
				apiConfig[purpose],
				{
					apiKey: apiConfig.key,
					request: request,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const cookies = new Cookies();

			if (data.data.success) {
				if (data.data.user.token) {
					cookies.set("flamingo", data.data.user.token, { path: "/" });
					cookies.set("user", data.data.user._id, { path: "/" });
					cookies.set("person", data.data.user.person, { path: "/" });

					let adminObject = {
						...data.data.user.permissions,
						adminBranch: data.data.user.branch,
					};

					localStorage.setItem("permissions", JSON.stringify(adminObject));

					// for (const [key, value] of Object.entries(data.data.user.permissions)) {
					// 	localStorage.setItem(key, value);
					// }

					// localStorage.setItem("adminBranch", data.data.user.branch);

					dispatch({ type: SET_SESSION, payload: data.data.user });
					dispatch({ type: HIDE_LOADER });

					window.location.replace(locationState ? locationState : "/");
					// history.push("/");
				}
			} else {
				console.log("login error", data.data);
				dispatch({ type: SET_LOGIN_ERROR, payload: data.data });
				dispatch({ type: HIDE_LOADER });
			}

			// dispatch({ type: HIDE_LOADER });
		} catch (error) {
			console.log(error);
		}
	};
};
