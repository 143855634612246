import { SHOW_LOADER, HIDE_LOADER } from "./actionsType";

export const Loading = (isLoading) => {
	return async (dispatch) => {
		switch (isLoading) {
			case true:
				return dispatch({ type: SHOW_LOADER });
				break;
			case false:
				return dispatch({ type: HIDE_LOADER });
				break;
			default:
				return dispatch({ type: HIDE_LOADER });
				break;
		}
		// dispatch({ type: HIDE_LOADER });
	};
};
