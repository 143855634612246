import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import InputMask from "react-input-mask";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";

const PeopleAdditionalInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="name-input"
								name="add_profession"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.add_profession}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								label="Професія"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="person-input-initial-academic-level-label">
								Рівень освіти
							</InputLabel>
							<Select
								labelId="person-input-initial-academic-level-label"
								id="add_academicLevel"
								name="add_academicLevel"
								defaultValue={props.person.add_academicLevel}
								onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.academicLevels.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_secondarySchool-input"
							name="add_secondarySchool"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_secondarySchool}
							// onChange={props.handleInputChange}
							label="Школи"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_qualification-input"
							name="add_qualification"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_qualification}
							// onChange={props.handleInputChange}
							label="Вища освіта/Проф освіта"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_tradesAndSkills-input"
							name="add_tradesAndSkills"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_tradesAndSkills}
							// onChange={props.handleInputChange}
							label="Навички та здібності"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_booksAuthor-input"
							name="add_booksAuthor"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_booksAuthor}
							// onChange={props.handleInputChange}
							label="Авторство книг"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_languages-input"
							name="add_languages"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_languages}
							// onChange={props.handleInputChange}
							label="Мови якими володіє"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="2"
							id="add_professionalAssociations-input"
							name="add_professionalAssociations"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.add_professionalAssociations}
							// onChange={props.handleInputChange}
							label="Членство професійних спілок"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
			</form>
		</>
	);
};

export default PeopleAdditionalInfo;
