import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const PeopleProbationistInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="study_group"
							name="study_group"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.study_group}
							// onChange={props.handleInputChange}
							label="Группа навчання"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="probationist_subscribtion_mean-label">
								Канал контакту
							</InputLabel>
							<Select
								labelId="probationist_subscribtion_mean-label"
								id="probationist_subscribtion_mean"
								name="probationist_subscribtion_mean"
								defaultValue={props.person.probationist_subscribtion_mean}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.probationistSubscriptionMeans.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="probationist_start_date"
								name="probationist_start_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.probationist_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
			</form>
		</>
	);
};

export default PeopleProbationistInfo;
