import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import ProtectedRoute from "../HOC/ProtectedRoute";

import Navigation from "./main/Navigation";
import Nav from "./main/Nav";

import Home from "./pages/Home";
import BookSettings from "./pages/settings/BookSettings";
import BranchesSettings from "./pages/settings/BranchesSettings";
import EventsSettings from "./pages/settings/EventsSettings";
import MediaSettings from "./pages/settings/MediaSettings";
import PeopleGeneralSettings from "./pages/settings/People/GeneralSettings";
import ThirdCircleSettings from "./pages/settings/People/ThirdCircleSettings";
import SeccondCircleSettings from "./pages/settings/People/SecondCircleSettings";
import AdminSettings from "./pages/settings/AdminSettings";
import BookCreateEdit from "./pages/BookCreateEdit";
import Books from "./pages/Books";
import PeopleEdit from "./pages/PeopleEdit";
import EventView from "./pages/EventView";
import Events from "./pages/Events";
import People from "./pages/People";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";

import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "./ErrorScreen";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordEnterNew from "./pages/ForgotPasswordEnterNew";
import Loans from "./pages/Loans";

import {
	loginContainerRoutes,
	defaultContainerRoutes,
} from "../configs/routes";

const Router = (props) => {
	const errorHandler = (error, errorInfo) => {
		console.log("Logging: ", error, errorInfo);
	};

	return (
		<BrowserRouter>
			<ErrorBoundary FallbackComponent={ErrorScreen} onError={errorHandler}>
				{/* <Navigation /> */}
				<Switch>
					<Route
						// path={["/login", "/changepassword", "/forgot"]}
						path={loginContainerRoutes}
						component={LoginContainer}
					/>
					<Route
						// path={["/people", "/books"]}
						path={defaultContainerRoutes}
						component={DefaultContainer}
					/>
					<Route path={"/"} component={Home} />
					<Route>
						<Redirect to="/" />
					</Route>
				</Switch>
			</ErrorBoundary>
		</BrowserRouter>
	);
};

const LoginContainer = () => (
	<>
		<Route path="/login" component={Login} />
		<Route exact path="/changepassword" component={ChangePassword} />
		<Route exact path="/forgot/:hash" component={ForgotPasswordEnterNew} />
		<Route exact path="/forgot" component={ForgotPassword} />
	</>
);

const DefaultContainer = () => (
	<>
		{/* <Navigation /> */}
		<Nav />

		<ProtectedRoute exact path="/" component={Home} />
		<ProtectedRoute
			path="/settings/books/"
			component={BookSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/branches/"
			component={BranchesSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/events/"
			component={EventsSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/media/"
			component={MediaSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/people/general"
			component={PeopleGeneralSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/people/thirdcircle"
			component={ThirdCircleSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/people/secondcircle"
			component={SeccondCircleSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/settings/admin"
			component={AdminSettings}
		></ProtectedRoute>
		<ProtectedRoute
			path="/books/:param"
			component={BookCreateEdit}
			exact
		></ProtectedRoute>
		<ProtectedRoute path="/books" exact component={Books}></ProtectedRoute>
		<ProtectedRoute path="/loans" exact component={Loans}></ProtectedRoute>
		<ProtectedRoute
			path="/people/view/:param"
			component={PeopleEdit}
			exact
		></ProtectedRoute>
		<ProtectedRoute
			path="/people/"
			component={() => <People />}
			exact
		></ProtectedRoute>
		<ProtectedRoute
			path="/people/:page"
			component={() => <People />}
			exact
		></ProtectedRoute>
		<ProtectedRoute
			path="/events/view/:param"
			exact
			component={EventView}
		></ProtectedRoute>
		<ProtectedRoute
			path="/events/:param"
			exact
			component={Events}
		></ProtectedRoute>
		{/* <Route path="*">
			<Redirect to="/" />
		</Route> */}
		{/* <Route from="*" component={Home} /> */}
	</>
);

export default Router;
