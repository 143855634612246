import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useHistory } from "react-router-dom";

import * as ApiRequest from "../../api/apiRequest";
import Moment from "moment";

const PeopleProfileActionButtons = (props) => {
	let history = useHistory();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [newItem, setNewItem] = useState({});

	const host = "People";
	const endpointPurposes = {
		deleteItem: "delete",
		addVolunteer: "addVolunteer",
		removeVolunteer: "removeVolunteer",
		addProbationist: "addProbationist",
		removeProbationist: "removeProbationist",
		addMember: "addMember",
		addMemberActivity: "addMemberActivity",
		removeMember: "removeMember",
		addCandidate: "addCandidate",
		removeCandidate: "removeCandidate",
		addLiveForce: "addLiveForce",
		addLiveForceLogos: "addLiveForceLogos",
		removeLiveForce: "removeLiveForce",
		addInstructorCandidate: "addInstructorCandidate",
		removeInstructorCandidate: "removeInstructorCandidate",
		addInstructor: "addInstructor",
		addBonfire: "addBonfire",
		addAxeBearer: "addAxeBearer",
		removeAxeBearer: "removeAxeBearer",
		addAxe: "addAxe",
		changeBranch: "changeBranch",
	};

	const [modalHeader, setModalHeader] = useState("");
	const [modalBody, setModalBody] = useState("");
	const [modalPurpose, setModalPurpose] = useState("");

	const [currentFormStructure, setCurrentFormStructure] = useState();

	const [modalsStructure, setModalsStructure] = useState();

	const [formValidation, setFormValidation] = useState({});

	const [formOpened, setFormOpened] = useState();

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	// const modalsStructure = {
	// 	add_volunteer: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "isVolunteer_date",
	// 				id: "isVolunteer_date",
	// 				value: props.person.isVolunteer_date,
	// 				required: true,
	// 				autoFocus: true,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати волонтерство",
	// 		purpose: "addVolunteer",
	// 	},
	// 	remove_volunteer: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата завершення",
	// 				name: "isVolunteer_endDate",
	// 				id: "isVolunteer_endDate",
	// 				// value: props.person.isVolunteer_endDate,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Закінчити волонтерство",
	// 		purpose: "removeVolunteer",
	// 	},
	// 	add_probationist: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Новий пробаціоніст?",
	// 				name: "probationist_origin",
	// 				id: "probationist_origin",
	// 				required: true,
	// 				autoFocus: true,
	// 				value: props.person.probationist_origin,
	// 				arrayItems: props.probationistOrigins,
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Канал контакту",
	// 				name: "probationist_subscribtion_mean",
	// 				id: "probationist_subscribtion_mean",
	// 				required: true,
	// 				autoFocus: false,
	// 				value: props.person.probationist_subscribtion_mean,
	// 				arrayItems: props.probationistSubscriptionMeans,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: false,
	// 				label: "Группа навчання",
	// 				name: "study_group",
	// 				id: "study_group",
	// 				value: props.person.study_group,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: "",
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "probationist_start_date",
	// 				id: "probationist_start_date",
	// 				value: props.person.probationist_start_date,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Пробаціоніст",
	// 		purpose: "addProbationist",
	// 	},
	// 	remove_probationist: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Статус",
	// 				name: "probationist_remove_status",
	// 				id: "probationist_remove_status",
	// 				required: true,
	// 				autoFocus: true,
	// 				value: props.person.probationist_remove_status,
	// 				arrayItems: props.probationistRemoveStatuses,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата заверщення",
	// 				name: "probationist_end_date",
	// 				id: "probationist_end_date",
	// 				value: props.person.probationist_end_date,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Причина виходу",
	// 				name: "departure_reason",
	// 				id: "departure_reason",
	// 				required: true,
	// 				autoFocus: false,
	// 				value: props.person.departure_reason,
	// 				arrayItems: props.departureReasons,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: true,
	// 				label: "Комментарі виходу",
	// 				name: "departure_remarks",
	// 				id: "departure_remarks",
	// 				value: props.person.departure_remarks,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: "",
	// 			},
	// 		],
	// 		title: "Пробаціоніст",
	// 		purpose: "removeProbationist",
	// 	},
	// 	add_member: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Статус",
	// 				name: "member_origin",
	// 				id: "member_origin",
	// 				required: true,
	// 				autoFocus: false,
	// 				disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.member_origin,
	// 				arrayItems: props.membersOrigins,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: false,
	// 				label: "Группа навчання",
	// 				name: "study_group",
	// 				id: "study_group",
	// 				value: props.person.study_group,
	// 				required: true,
	// 				autoFocus: true,
	// 				defaultValue: "",
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "member_memberSince",
	// 				id: "member_memberSince",
	// 				value: props.person.member_memberSince,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "День народження",
	// 				name: "birthday",
	// 				id: "birthday",
	// 				value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: "",
	// 			},
	// 		],
	// 		title: "Членство",
	// 		purpose: "addMember",
	// 	},
	// 	add_member_activity: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Філія",
	// 				name: "member_activity_branch",
	// 				id: "member_activity_branch",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.branch,
	// 				arrayItems: props.branches,
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Ієрархія",
	// 				name: "member_activity_hierarchy",
	// 				id: "member_activity_hierarchy",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				// value: props.person.,
	// 				arrayItems: props.hierarchy,
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Секретаріат",
	// 				name: "member_activity_secretary",
	// 				id: "member_activity_secretary",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				// value: props.person.,
	// 				arrayItems: props.secretary,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "member_activity_start_date",
	// 				id: "member_activity_start_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Видалити членство",
	// 		purpose: "removeMemberActivity",
	// 	},
	// 	remove_member: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Статус",
	// 				name: "member_remove_status",
	// 				id: "member_remove_status",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.member_remove_status,
	// 				arrayItems: props.memberRemoveStatuses,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата завершення",
	// 				name: "member_memberEndDate",
	// 				id: "member_memberEndDate",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Причина виходу",
	// 				name: "departure_reason",
	// 				id: "departure_reason",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.departure_reason,
	// 				arrayItems: props.departureReasons,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: true,
	// 				label: "Комментарі виходу",
	// 				name: "departure_remarks",
	// 				id: "departure_remarks",
	// 				value: props.person.departure_remarks,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: "",
	// 			},
	// 		],
	// 		title: "Видалити члена",
	// 		purpose: "removeMember",
	// 	},
	// 	add_candidate: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Логос",
	// 				name: "candidate_logos",
	// 				id: "candidate_logos",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.candidate_logos,
	// 				arrayItems: props.livingForceLogos.filter(
	// 					(logos) => logos.gender == props.person.gender
	// 				),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "candidate_start_date",
	// 				id: "candidate_start_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати кандидата",
	// 		purpose: "addCandidate",
	// 	},
	// 	remove_candidate: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Стадія",
	// 				name: "candidate_stage",
	// 				id: "candidate_stage",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.candidate_stage,
	// 				arrayItems: props.candidateStages,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата завершення",
	// 				name: "candidate_end_date",
	// 				id: "candidate_end_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "select",
	// 				label: "Причина виходу",
	// 				name: "departure_reason",
	// 				id: "departure_reason",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.departure_reason,
	// 				arrayItems: props.departureReasons,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: true,
	// 				label: "Комментарі виходу",
	// 				name: "departure_remarks",
	// 				id: "departure_remarks",
	// 				value: props.person.departure_remarks,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: "",
	// 			},
	// 		],
	// 		title: "Видалити кандидата",
	// 		purpose: "removeCandidate",
	// 	},
	// 	add_live_force: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Логос",
	// 				name: "live_force_logos",
	// 				id: "live_force_logos",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				value: props.person.candidate_logos,
	// 				arrayItems: props.livingForceLogos.filter(
	// 					(logos) => logos.gender == props.person.gender
	// 				),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "live_force_start_date",
	// 				id: "live_force_start_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати ЖС",
	// 		purpose: "addLiveForce",
	// 	},
	// 	add_live_force_logos: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Логос",
	// 				name: "live_force_logos",
	// 				id: "live_force_logos",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				// value: props.person.candidate_logos,
	// 				arrayItems: props.livingForceLogos.filter(
	// 					(logos) =>
	// 						logos.gender == props.person.gender &&
	// 						logos._id != props.person.live_force_logos
	// 				),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "logos_start_date",
	// 				id: "logos_start_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати Логос",
	// 		purpose: "addLiveForceLogos",
	// 	},
	// 	remove_live_force: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата завершення",
	// 				name: "live_force_end_date",
	// 				id: "live_force_end_date",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Видалити ЖС",
	// 		purpose: "removeLiveForce",
	// 	},
	// 	add_instructor_candidate: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "instructor_candidate_start_date",
	// 				id: "instructor_candidate_start_date",
	// 				// value: props.person.birthday,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: false,
	// 				label: "Группи викладання",
	// 				name: "instructor_candidate_study_groups",
	// 				id: "instructor_candidate_study_groups",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: true,
	// 				// defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати кандидата в Інструктори",
	// 		purpose: "addInstructorCandidate",
	// 	},
	// 	remove_instructor_candidate: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата завершення",
	// 				name: "instructor_candidate_end_date",
	// 				id: "instructor_candidate_end_date",
	// 				// value: props.person.birthday,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Видалити кандидата в Інструктори",
	// 		purpose: "removeInstructorCandidate",
	// 	},
	// 	add_instructor: {
	// 		form: [
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата початку",
	// 				name: "instructor_start_date",
	// 				id: "instructor_start_date",
	// 				// value: props.person.birthday,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "text",
	// 				multiline: false,
	// 				label: "Группи викладання",
	// 				name: "instructor_study_groups",
	// 				id: "instructor_study_groups",
	// 				// value: props.person.birthday,
	// 				required: false,
	// 				autoFocus: true,
	// 				defaultValue: props.person.instructor_candidate_study_groups,
	// 			},
	// 		],
	// 		title: "Додати Інструктора",
	// 		purpose: "addInstructor",
	// 	},
	// 	add_bonfire: {
	// 		form: [
	// 			{
	// 				input: "select",
	// 				label: "Факел",
	// 				name: "bonfire",
	// 				id: "bonfire",
	// 				required: true,
	// 				autoFocus: true,
	// 				// disabled: props.person.isProbationist ? true : false,
	// 				// value: props.person.candidate_logos,
	// 				arrayItems:
	// 					props.person.bonfires.length > 0
	// 						? props.bonfires.filter(
	// 								(bonfire) => bonfire._id > props.person.current_bonfire
	// 						  )
	// 						: props.bonfires,
	// 			},
	// 			{
	// 				input: "text",
	// 				type: "date",
	// 				multiline: false,
	// 				label: "Дата присвоєння",
	// 				name: "bonfire_start_date",
	// 				id: "bonfire_start_date",
	// 				// value: props.person.birthday,
	// 				required: true,
	// 				autoFocus: false,
	// 				defaultValue: Moment().format("YYYY-MM-DD"),
	// 			},
	// 		],
	// 		title: "Додати Інструкторський факел",
	// 		purpose: "addBonfire",
	// 	},
	// };

	const fillModalStructure = () => {
		const tempModalStructure = {
			add_volunteer: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "isVolunteer_date",
						id: "isVolunteer_date",
						value: props.person.isVolunteer_date,
						required: true,
						autoFocus: true,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати волонтерство",
				purpose: "addVolunteer",
			},
			remove_volunteer: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "isVolunteer_endDate",
						id: "isVolunteer_endDate",
						// value: props.person.isVolunteer_endDate,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Закінчити волонтерство",
				purpose: "removeVolunteer",
			},
			add_probationist: {
				form: [
					{
						input: "select",
						label: "Новий пробаціоніст?",
						name: "probationist_origin",
						id: "probationist_origin",
						required: true,
						autoFocus: true,
						value: props.person.probationist_origin,
						arrayItems: props.probationistOrigins,
					},
					{
						input: "select",
						label: "Канал контакту",
						name: "probationist_subscribtion_mean",
						id: "probationist_subscribtion_mean",
						required: true,
						autoFocus: false,
						value: props.person.probationist_subscribtion_mean,
						arrayItems: props.probationistSubscriptionMeans,
					},
					{
						input: "text",
						type: "text",
						multiline: false,
						label: "Группа навчання",
						name: "study_group",
						id: "study_group",
						value: props.person.study_group,
						required: true,
						autoFocus: false,
						defaultValue: "",
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "probationist_start_date",
						id: "probationist_start_date",
						value: props.person.probationist_start_date,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Пробаціоніст",
				purpose: "addProbationist",
			},
			remove_probationist: {
				form: [
					{
						input: "select",
						label: "Статус",
						name: "probationist_remove_status",
						id: "probationist_remove_status",
						required: true,
						autoFocus: true,
						value: props.person.probationist_remove_status,
						arrayItems: props.probationistRemoveStatuses,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата заверщення",
						name: "probationist_end_date",
						id: "probationist_end_date",
						value: props.person.probationist_end_date,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "select",
						label: "Причина виходу",
						name: "departure_reason",
						id: "departure_reason",
						required: true,
						autoFocus: false,
						value: props.person.departure_reason,
						arrayItems: props.departureReasons,
					},
					{
						input: "text",
						type: "text",
						multiline: true,
						label: "Комментарі виходу",
						name: "departure_remarks",
						id: "departure_remarks",
						value: props.person.departure_remarks,
						required: false,
						autoFocus: false,
						defaultValue: "",
					},
				],
				title: "Пробаціоніст",
				purpose: "removeProbationist",
			},
			add_member: {
				form: [
					{
						input: "select",
						label: "Статус",
						name: "member_origin",
						id: "member_origin",
						// required: true,
						autoFocus: false,
						disabled: props.person.isProbationist ? true : false,
						value: props.person.member_origin,
						arrayItems: props.membersOrigins,
					},
					{
						input: "text",
						type: "text",
						multiline: false,
						label: "Группа навчання",
						name: "study_group",
						id: "study_group",
						value: props.person.study_group,
						required: true,
						autoFocus: true,
						defaultValue: "",
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "member_memberSince",
						id: "member_memberSince",
						value: props.person.member_memberSince,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "День народження",
						name: "birthday",
						id: "birthday",
						value: props.person.birthday,
						required: false,
						autoFocus: false,
						defaultValue: "",
					},
				],
				title: "Членство",
				purpose: "addMember",
			},
			add_member_activity: {
				form: [
					{
						input: "select",
						label: "Філія",
						name: "member_activity_branch",
						id: "member_activity_branch",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.branch,
						arrayItems: props.branches,
					},
					{
						input: "select",
						label: "Ієрархія",
						name: "member_activity_hierarchy",
						id: "member_activity_hierarchy",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						// value: props.person.,
						arrayItems: props.hierarchy,
					},
					{
						input: "select",
						label: "Секретаріат",
						name: "member_activity_secretary",
						id: "member_activity_secretary",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						// value: props.person.,
						arrayItems: props.secretary,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "member_activity_start_date",
						id: "member_activity_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати членську активність",
				purpose: "addMemberActivity",
			},
			remove_member: {
				form: [
					{
						input: "select",
						label: "Статус",
						name: "member_remove_status",
						id: "member_remove_status",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.member_remove_status,
						arrayItems: props.memberRemoveStatuses,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "member_memberEndDate",
						id: "member_memberEndDate",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "select",
						label: "Причина виходу",
						name: "departure_reason",
						id: "departure_reason",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.departure_reason,
						arrayItems: props.departureReasons,
					},
					{
						input: "text",
						type: "text",
						multiline: true,
						label: "Комментарі виходу",
						name: "departure_remarks",
						id: "departure_remarks",
						value: props.person.departure_remarks,
						required: false,
						autoFocus: false,
						defaultValue: "",
					},
				],
				title: "Видалити члена",
				purpose: "removeMember",
			},
			add_candidate: {
				form: [
					{
						input: "select",
						label: "Логос",
						name: "candidate_logos",
						id: "candidate_logos",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.candidate_logos,
						arrayItems: props.livingForceLogos.filter(
							(logos) => logos.gender == props.person.gender
						),
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "candidate_start_date",
						id: "candidate_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати кандидата",
				purpose: "addCandidate",
			},
			remove_candidate: {
				form: [
					{
						input: "select",
						label: "Стадія",
						name: "candidate_stage",
						id: "candidate_stage",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.candidate_stage,
						arrayItems: props.candidateStages,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "candidate_end_date",
						id: "candidate_end_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "select",
						label: "Причина виходу",
						name: "departure_reason",
						id: "departure_reason",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.departure_reason,
						arrayItems: props.departureReasons,
					},
					{
						input: "text",
						type: "text",
						multiline: true,
						label: "Комментарі виходу",
						name: "departure_remarks",
						id: "departure_remarks",
						value: props.person.departure_remarks,
						required: false,
						autoFocus: false,
						defaultValue: "",
					},
				],
				title: "Видалити кандидата",
				purpose: "removeCandidate",
			},
			add_live_force: {
				form: [
					{
						input: "select",
						label: "Логос",
						name: "live_force_logos",
						id: "live_force_logos",
						required: true,
						autoFocus: true,
						// disabled: props.person.isProbationist ? true : false,
						value: props.person.candidate_logos,
						arrayItems: props.livingForceLogos.filter(
							(logos) => logos.gender == props.person.gender
						),
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "live_force_start_date",
						id: "live_force_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати ЖС",
				purpose: "addLiveForce",
			},
			add_live_force_logos: {
				form: [
					{
						input: "select",
						label: "Логос",
						name: "live_force_logos",
						id: "live_force_logos",
						required: true,
						autoFocus: true,
						requireError: "Це поле обов`язкове",
						// disabled: props.person.isProbationist ? true : false,
						// value: props.person.candidate_logos,
						arrayItems: props.livingForceLogos.filter(
							(logos) =>
								logos.gender == props.person.gender &&
								logos._id != props.person.live_force_logos
						),
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "logos_start_date",
						id: "logos_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати Логос",
				purpose: "addLiveForceLogos",
			},
			remove_live_force: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "live_force_end_date",
						id: "live_force_end_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Видалити ЖС",
				purpose: "removeLiveForce",
			},
			add_instructor_candidate: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "instructor_candidate_start_date",
						id: "instructor_candidate_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "text",
						type: "text",
						multiline: false,
						label: "Группи викладання",
						name: "instructor_candidate_study_groups",
						id: "instructor_candidate_study_groups",
						// value: props.person.birthday,
						required: false,
						autoFocus: true,
						// defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати кандидата в Інструктори",
				purpose: "addInstructorCandidate",
			},
			remove_instructor_candidate: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "instructor_candidate_end_date",
						id: "instructor_candidate_end_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Видалити кандидата в Інструктори",
				purpose: "removeInstructorCandidate",
			},
			add_instructor: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "instructor_start_date",
						id: "instructor_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
					{
						input: "text",
						type: "text",
						multiline: false,
						label: "Группи викладання",
						name: "instructor_study_groups",
						id: "instructor_study_groups",
						// value: props.person.birthday,
						required: false,
						autoFocus: true,
						defaultValue: props.person.instructor_candidate_study_groups,
					},
				],
				title: "Додати Інструктора",
				purpose: "addInstructor",
			},
			add_bonfire: {
				form: [
					{
						input: "select",
						label: "Факел",
						name: "bonfire",
						id: "bonfire",
						required: true,
						autoFocus: true,
						disabled: true,
						value: props.person.current_bonfire
							? props.bonfires[
									props.bonfires.find(
										(bonfire) => bonfire._id == props.person.current_bonfire
									)._id
							  ]._id
							: 1,
						arrayItems:
							props.person.isAxeBearer && props.person.bonfires.length > 0
								? props.bonfires.filter(
										(bonfire) => bonfire._id > props.person.current_bonfire
								  )
								: props.bonfires,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата присвоєння",
						name: "bonfire_start_date",
						id: "bonfire_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати Інструкторський факел",
				purpose: "addBonfire",
			},
			add_axe_bearer: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата присвоєння",
						name: "axe_bearer_start_date",
						id: "axe_bearer_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати Сикіріча",
				purpose: "addAxeBearer",
			},
			remove_axe_bearer: {
				form: [
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата завершення",
						name: "axe_bearer_end_date",
						id: "axe_bearer_end_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Видалити Сикіріча",
				purpose: "removeAxeBearer",
			},
			add_axe: {
				form: [
					{
						input: "select",
						label: "Cікіра",
						name: "axe",
						id: "axe",
						disabled: true,
						required: true,
						autoFocus: true,
						value: props.person.isAxeBearer
							? props.person.axe_bearer_current_axe >= props.axes.length - 1
								? null
								: props.person.axe_bearer_current_axe
								? props.axes[
										props.axes.find(
											(axe) => axe._id == props.person.axe_bearer_current_axe
										)._id + 1
								  ]._id
								: null
							: null,
						arrayItems: props.axes,
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата присвоєння",
						name: "axe_start_date",
						id: "axe_start_date",
						// value: props.person.birthday,
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Додати Сікіру",
				purpose: "addAxe",
			},
			change_branch: {
				form: [
					{
						input: "select",
						label: "Осередок",
						name: "branch",
						id: "branch",
						// required: true,
						autoFocus: true,
						// requireError: "Це поле обов`язкове",
						disabled: true,
						value: props.person.branch,
						arrayItems: props.branches,
					},
					{
						input: "select",
						label: "Новий осередок",
						name: "newBranch",
						id: "newBranch",
						required: true,
						autoFocus: false,
						requireError: "Це поле обов`язкове",
						arrayItems: props.branches.filter(
							(branch) => branch._id != props.person.branch
						),
					},
					{
						input: "text",
						type: "date",
						multiline: false,
						label: "Дата початку",
						name: "newBranch_date",
						id: "newBranch_date",
						required: true,
						autoFocus: false,
						defaultValue: Moment().format("YYYY-MM-DD"),
					},
				],
				title: "Змінити осередок",
				purpose: "changeBranch",
			},
		};

		setModalsStructure(tempModalStructure);
	};

	useEffect(() => {
		fillModalStructure();
	}, [props.person]);

	const dialogOpenHandler = () => {
		setDialogOpen(true);
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		setDialogOpen(false);
		setNewItem({});
	};

	const handleInputChange = (event) => {
		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const validateForm = (data) => {
		let valid = true;

		let tempFormStructure = currentFormStructure;

		console.log("validate test: ", currentFormStructure);

		currentFormStructure.form.forEach((element, i) => {
			let value = currentFormStructure.form[i].value
				? currentFormStructure.form[i].value
				: currentFormStructure.form[i].defaultValue;

			if (element.required) {
				console.log("validation required: ", i, data, element.name, value);
				if (value == null) {
					element.error = true;
					valid = false;
				} else {
					element.error = false;
					// valid = true;
				}
			}
		});

		dialogFormOpen(formOpened, tempFormStructure);

		return valid;
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const data = new FormData(event.target);
		var item = {
			_id: props.person._id,
		};

		console.log("valid hard: ", data.get("bonfire"));
		let valid = validateForm(data);

		currentFormStructure.form.forEach((element) => {
			item[element.name] = data.get(element.name);
		});

		if (valid) {
			let request = {
				purpose: endpointPurposes[modalPurpose],
				body: item,
			};

			console.log("request to update test: ", request);

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					setSnackBarSeverity("success");
					setSnackBarMessage("Профіль успішно змінено");
					setSnackBarOpen(true);
					setDrawerOpen(false);
					setDialogOpen(false);
					setNewItem({
						isVolunteer_date: "",
						isVolunteer_endDate: "",
						probationist_origin: "",
						probationist_subscribtion_mean: "",
						study_group: "",
						probationist_start_date: "",
					});
					props.getPersonData(props.person.slug);
				} else {
					setDrawerOpen(false);
					setDialogOpen(false);
					setSnackBarSeverity("error");
					setSnackBarMessage("Сталася помилка");
					setSnackBarOpen(true);
					props.getPersonData(props.person.slug);
				}
			});
		}
	};

	const dialogFormOpen = (formName, structureObjectPreset) => {
		if (structureObjectPreset) {
			var structureObject = structureObjectPreset;
		} else {
			var structureObject = modalsStructure[formName];
		}

		var formHeader = structureObject.title;

		var formBody = structureObject.form.map((item) => {
			switch (item.input) {
				case "text":
					let inputProps = {};
					let rows = item.multiline ? 4 : 1;
					if (item.type == "date") {
						inputProps = {
							shrink: true,
						};
					}
					return (
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								multiline={item.multiline}
								rows={rows}
								required={item.required}
								autoFocus={item.autoFocus}
								type={item.type}
								id={item.id}
								name={item.name}
								value={item.value}
								defaultValue={item.defaultValue}
								// onBlur={handleFormValidation}
								onChange={handleInputChange}
								label={item.label}
								error={formValidation[item.name]}
								// helperText={formValidation[item.name] ? "Поле обов`язкове" : ""}
								// error={formValidation.fields.title.isError}
								InputLabelProps={inputProps}
								helperText={item.error ? "Поле обов'язкове" : ""}
								// error={item.error}
							/>
						</FormControl>
					);
					break;
				case "select":
					return (
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id={item.id + "-label"}>
								{item.label + ` ${item.required ? "*" : ""}`}
							</InputLabel>
							<Select
								labelId={item.id + "-label"}
								id={item.id}
								name={item.name}
								value={item.value}
								disabled={item.disabled}
								required={item.required}
								// onSelect={handleInputChange}
								// onBlur={handleFormValidation}
								helperText="Це поле обов`язкове"
								onChange={handleInputChange}
								error={item.error}
								// helperText={formValidation[item.name] ? "Поле обов`язкове" : ""}
								// IconComponent={() => {
								// 	return <p className="require-error">Обов`язково</p>;
								// }}
							>
								{item.arrayItems.map(function(arrayItem, i) {
									return (
										<MenuItem value={arrayItem._id}>{arrayItem.title}</MenuItem>
									);
								})}
							</Select>
							{item.error && (
								<FormHelperText className="red">
									Поле обов'язкове
								</FormHelperText>
							)}
						</FormControl>
					);
					break;
				default:
					console.log("wrong field type");
					break;
			}
		});

		setModalHeader(formHeader);
		setModalBody(formBody);
		setModalPurpose(structureObject.purpose);
		setCurrentFormStructure(structureObject);
		setDialogOpen(true);

		setFormOpened(formName);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setDrawerOpen(open);
	};

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Людину було видалено");

				history.push("/people");
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обрану людину?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	return (
		<>
			<Button
				onClick={toggleDrawer(true)}
				variant="outlined"
				// color="white"
				startIcon={<SettingsEthernetIcon />}
				className="header-drawer-button"
			>
				Дії з профайлом
			</Button>
			<SwipeableDrawer
				anchor="right"
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				<div className="drawer-body-wrapper">
					<div className="drawer-header">
						<h2>Дії з профайлом</h2>
					</div>
					<div className="drawer-section row">
						<div className="drawer-button-wrapper">
							<Button
								variant="outlined"
								color="primary"
								startIcon={<LocationOnIcon />}
								className="drawer-button"
								size="small"
								onClick={() => dialogFormOpen("change_branch")}
							>
								Змінити осередок
							</Button>
						</div>
					</div>
					<div className="drawer-section row">
						<div className="drawer-button-wrapper">
							{props.person.isVolunteer ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_volunteer")}
								>
									Волонтерство
								</Button>
							) : (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_volunteer")}
								>
									Волонтерство
								</Button>
							)}
						</div>
						<div className="drawer-button-wrapper">
							{!props.person.isMember && !props.person.isProbationist ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_probationist")}
								>
									Пробаціоніст
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{!props.person.isMember && props.person.isProbationist ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_probationist")}
								>
									Пробаціоніст
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isMember ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_member")}
								>
									Членство
								</Button>
							) : (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_member")}
								>
									Членство
								</Button>
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isMember ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_member_activity")}
								>
									Членська активність
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isMember &&
							!props.person.isCandidate &&
							!props.person.isLiveForce ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_candidate")}
								>
									Кандидат
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isCandidate ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_candidate")}
								>
									Кандидат
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isMember && !props.person.isLiveForce ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_live_force")}
								>
									ЖС
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isLiveForce ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_live_force_logos")}
								>
									Додати Логос
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isLiveForce ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_live_force")}
								>
									ЖС
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isLiveForce &&
							!props.person.isInstructorCandidate &&
							!props.person.isInstructor ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_instructor_candidate")}
								>
									Кандидат Інструктор
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isInstructorCandidate ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_instructor_candidate")}
								>
									Кандидат Інструктор
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isLiveForce && !props.person.isInstructor ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_instructor")}
								>
									Інструктор
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isInstructor &&
							props.person.bonfires.length < props.bonfires.length ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_bonfire")}
								>
									Факел
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isInstructor && !props.person.isAxeBearer ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_axe_bearer")}
								>
									Сікіріч
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isAxeBearer ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<RemoveIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("remove_axe_bearer")}
								>
									Сікіріч
								</Button>
							) : (
								""
							)}
						</div>
						<div className="drawer-button-wrapper">
							{props.person.isAxeBearer &&
							props.person.axe_bearer_axes.length < props.axes.length ? (
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									className="drawer-button"
									size="small"
									onClick={() => dialogFormOpen("add_axe")}
								>
									Сікіра
								</Button>
							) : (
								""
							)}
						</div>
						{/*                         
						<div className="drawer-button-wrapper">
							<Button
								variant="outlined"
								color="primary"
								startIcon={<AddIcon />}
								className="drawer-button"
								size="small"
							>
								Єкзамен
							</Button>
						</div>
						<div className="drawer-button-wrapper">
							<Button
								variant="outlined"
								color="primary"
								startIcon={<AddIcon />}
								className="drawer-button"
								size="small"
							>
								Диплом
							</Button>
						</div> */}
						{/* <div className="drawer-button-wrapper">
							<Button
								variant="outlined"
								color="primary"
								startIcon={<AddIcon />}
								className="drawer-button"
								size="small"
							>
								Коло
							</Button>
						</div> */}
					</div>
					<div className="drawer-section row">
						<Button
							variant="outlined"
							color="primary"
							startIcon={<DeleteIcon />}
							className="drawer-button"
							size="small"
							onClick={() => deleteItemHandler(props.person._id)}
						>
							Видалити
						</Button>
					</div>
				</div>
			</SwipeableDrawer>
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								{modalHeader}
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<form onSubmit={handleFormSave} noValidate>
							<div class="modal-body">{modalBody}</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-default btn-flat"
									data-dismiss="modal"
									onClick={dialogCancelHandler}
								>
									Скасувати
								</button>

								<button
									type="submit"
									class="btn btn-primary"
									// onClick={handleFormSave}
									// onClick={validateForm}
								>
									OK
								</button>
							</div>
						</form>
					</div>
				</div>
			</Dialog>
			<Dialog
				open={alertOpen}
				onClose={alertCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div className="flamingo-alert-body-wrapper">
					<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
					{alertWarningMessage}
				</div>
				<div className="flamingo-alert-buttons-wrapper">
					<button
						type="button"
						class="btn btn-default btn-flat"
						data-dismiss="modal"
						onClick={alertCloseHandler}
					>
						Скасувати
					</button>
					{alertActionButton}
				</div>
			</Dialog>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity={snackBarSeverity}
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default PeopleProfileActionButtons;
