import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/lab/Autocomplete";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../actions";

const EventView = (props) => {
	let themeImageInput = null;
	let graphicMaterialsInput = null;

	const fileUploadHost = "PeopleUpload";
	const downloadHost = "PeopleDownload";

	const history = useHistory();

	// const [mode, setMode] = useState("create");
	const [eventIdToEdit, setEventIdToEdit] = useState();

	const [item, setItem] = useState({});

	const [eventConstants, setEventConstants] = useState({});

	const host = "Events";
	// const fileUploadHost = "BookCoverUpload";
	const endpointPurposes = {
		getData: "get",
		getItem: "getOneItem",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
	};

	const [speakersList, setSpeakersList] = useState();

	const [titleError, setTitleError] = useState(false);

	useEffect(() => {
		const request = props.match.params.param;

		getItemsFromApi(request);
	}, []);

	const getItemsFromApi = (eventId) => {
		let request = {
			purpose: endpointPurposes.getItem,
			body: {
				id: eventId,
			},
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let speakersListTemp = [];
				let selectedListTemp = [];

				result.people.forEach((person) => {
					let selected = false;
					result.item.speakers.forEach((speaker) => {
						if (speaker == person._id) {
							selected = true;
						}
					});

					if (!selected) {
						speakersListTemp.push(person);
					} else {
						selectedListTemp.push(person);
					}
				});

				setSpeakersList(speakersListTemp);

				setItem(result.item);
				setEventConstants({
					event_types: result.event_types,
					branches: result.branches,
					people: result.people,
					promo_media: result.promo_media,
				});
				props.setLoading(false);
			}
		});
	};

	const themeImageClickHandler = () => {
		themeImageInput.click();
	};

	const graphicsMaterialsClickHandler = () => {
		graphicMaterialsInput.click();
	};

	const fileRemoveHandler = (event, name) => {
		props.setLoading(true);
		itemUpdateApiCall(null, null, {
			name: name,
			value: "",
		});
	};

	const fileUploadHandler = (event, bucket) => {
		props.setLoading(true);
		let imageUrl = URL.createObjectURL(event.target.files[0]);

		const formData = new FormData();
		formData.append("file", event.target.files[0]);
		formData.append("bucket", bucket);

		axios
			.post(apiConfig[fileUploadHost], formData, {
				onUploadProgress: (ProgressEvent) => {
					let progress =
						Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
						"%";
				},
			})
			.then((result) => {
				if (result.data.success) {
					itemUpdateApiCall(event.target.name, result.data.path);
				}
			});
	};

	const downloadHandler = (fileName, folder, fileType) => {
		props.setLoading(true);
		let request = {
			filename: fileName,
			folder: folder,
			fileType: fileType,
		};

		axios
			.post(apiConfig[downloadHost], request, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				// let nameToReturn = request.filename.replace(`/${folder}/`, "");
				let userFriendlyFileName = `${item._id}-${fileType}.${fileName
					.split(".")
					.pop()}`;
				link.setAttribute("download", userFriendlyFileName);
				document.body.appendChild(link);
				link.click();
				props.setLoading(false);
			});
	};

	const validateForm = () => {
		let valid = true;

		if (!item.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		return valid;
	};

	const inputBlurHandler = (event) => {
		if (event.target.name == "title") {
			if (event.target.value == "") {
				setTitleError(true);
			} else {
				setTitleError(false);
				itemUpdateApiCall(event.target.name, event.target.value);
			}
		} else {
			itemUpdateApiCall(event.target.name, event.target.value);
		}
	};

	const promoMediaBlurHandler = (event) => {
		let promo_media = item.promo_media ? item.promo_media : {};

		promo_media = {
			...promo_media,
			[event.target.name]: event.target.value,
		};

		itemUpdateApiCall("promo_media", promo_media);
	};

	const autocompleteHandler = (event, newValue) => {
		let speakersArray = item.speakers;
		if (newValue) {
			props.setLoading(true);
			speakersArray.push(newValue[0]._id);

			itemUpdateApiCall("speakers", speakersArray);
		}
	};

	const handleSpeakerDelete = (speakerId) => {
		let speakersArray = item.speakers;

		if (speakerId) {
			props.setLoading(true);
			let index = speakersArray.indexOf(speakerId);

			if (index > -1) {
				speakersArray.splice(index, 1);
				itemUpdateApiCall("speakers", speakersArray);
			}
		}
	};

	const itemUpdateApiCall = (name, value, newFileField) => {
		let eventObject = {
			[name]: value,
			_id: item._id,
		};

		let request = {
			purpose: endpointPurposes.updateItem,
			// body: person,
			body: eventObject,
			newFileField: newFileField,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				getItemsFromApi(item._id);
			} else {
				console.log("Error updating");
			}
		});
	};

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm book-create-edit-header">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<div className="card-actions-left-wrapper test">
											<NavLink
												to={
													"/events/" +
													(item.isExternal ? "external" : "internal")
												}
											>
												<IconButton
													aria-label="Перегляд/Редагувати"
													// onClick={() => deleteItemHandler(value)}
												>
													<ArrowBackIcon className="header-back-arrow" />
												</IconButton>
											</NavLink>

											<h1>{item.title}</h1>
										</div>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20 books-edit-wrapper">
						<div class="row">
							<div class="col-xs-12">
								<div class="content">
									<header class="content-heading"></header>
									<div class="content-body m-t-20">
										{item.title && eventConstants.event_types ? (
											<>
												<div className="col-md-6 books-edit-column-wrapper">
													<h2>Інформація</h2>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="title"
																	name="title"
																	onBlur={inputBlurHandler}
																	defaultValue={item.title}
																	label="Назва"
																	helperText={
																		titleError ? "Поле обов'язкове" : ""
																	}
																	error={titleError}
																/>
															</FormControl>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<InputLabel id="event_type-label">
																	Тип події
																</InputLabel>
																<Select
																	labelId="event_type-label"
																	id="event_type"
																	name="event_type"
																	defaultValue={item.event_type}
																	// onChange={props.handleInputChange}
																	onBlur={inputBlurHandler}
																>
																	{eventConstants.event_types.map(function (
																		item,
																		i
																	) {
																		return (
																			<MenuItem value={item._id}>
																				{item.title}
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<InputLabel id="branch-label">
																	Осередок
																</InputLabel>
																<Select
																	labelId="branch-label"
																	id="branch"
																	name="branch"
																	disabled
																	defaultValue={item.branch}
																	// onChange={props.handleInputChange}
																	onBlur={inputBlurHandler}
																>
																	{eventConstants.branches.map(function (
																		item,
																		i
																	) {
																		return (
																			<MenuItem value={item._id}>
																				{item.title}
																			</MenuItem>
																		);
																	})}
																</Select>
															</FormControl>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="location"
																	name="location"
																	onBlur={inputBlurHandler}
																	defaultValue={item.location}
																	label="Локація"
																	// helperText={formValidation.fields.title.text}
																	// error={formValidation.fields.title.isError}
																/>
															</FormControl>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="start_date"
																	name="start_date"
																	label="Дата"
																	type="date"
																	defaultValue={item.start_date}
																	// onChange={props.handleInputChange}
																	onBlur={inputBlurHandler}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															</FormControl>
														</div>
														<div className="col-md-6">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="end_date"
																	name="end_date"
																	label="Дата завершення"
																	type="date"
																	defaultValue={item.end_date}
																	// onChange={props.handleInputChange}
																	onBlur={inputBlurHandler}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															</FormControl>
														</div>
													</div>
													<div className="row">
														<div className="row acordion-row col-md-12">
															<div className="files-wrapper events-files-wrapper">
																<div
																	className={
																		"file-item-wrapper " +
																		(item.theme_image ? "file-exist" : "")
																	}
																>
																	<span className="file-item-name">
																		Обкладинка
																	</span>
																	<div className="file-item-icons-wrapper">
																		{item.theme_image ? (
																			<>
																				<Tooltip title="Завантажити">
																					<GetAppIcon
																						className="file-item-icons-icon"
																						onClick={() =>
																							downloadHandler(
																								item.theme_image,
																								"eventsThemeImages",
																								"theme_image"
																							)
																						}
																					/>
																				</Tooltip>
																				<Tooltip title="Видалити">
																					<ClearIcon
																						className="file-item-icons-icon"
																						onClick={(event) =>
																							fileRemoveHandler(
																								event,
																								"theme_image"
																							)
																						}
																					/>
																				</Tooltip>
																			</>
																		) : (
																			<Tooltip title="Додати">
																				<PublishIcon
																					className="file-item-icons-icon"
																					// fontSize="large"
																					onClick={themeImageClickHandler}
																				/>
																			</Tooltip>
																		)}

																		<input
																			type="file"
																			style={{ display: "none" }}
																			ref={(input) => {
																				themeImageInput = input;
																			}}
																			// accept="image/x-png,image/gif,image/jpeg"
																			onChange={(event) =>
																				fileUploadHandler(
																					event,
																					"eventsThemeImages"
																				)
																			}
																			name="theme_image"
																			// onBlur={inputBlurHandler}
																		/>
																	</div>
																</div>
																<div
																	className={
																		"file-item-wrapper " +
																		(item.graphic_materials ? "file-exist" : "")
																	}
																>
																	<span className="file-item-name">
																		Графічні матеріали
																	</span>
																	<div className="file-item-icons-wrapper">
																		{item.graphic_materials ? (
																			<>
																				<Tooltip title="Завантажити">
																					<GetAppIcon
																						className="file-item-icons-icon"
																						onClick={() =>
																							downloadHandler(
																								item.graphic_materials,
																								"eventsGraphicsMaterials",
																								"graphic_materials"
																							)
																						}
																					/>
																				</Tooltip>
																				<Tooltip title="Видалити">
																					<ClearIcon
																						className="file-item-icons-icon"
																						onClick={(event) =>
																							fileRemoveHandler(
																								event,
																								"graphic_materials"
																							)
																						}
																					/>
																				</Tooltip>
																			</>
																		) : (
																			<Tooltip title="Додати">
																				<PublishIcon
																					className="file-item-icons-icon"
																					// fontSize="large"
																					onClick={
																						graphicsMaterialsClickHandler
																					}
																				/>
																			</Tooltip>
																		)}
																		<input
																			type="file"
																			style={{ display: "none" }}
																			ref={(input) => {
																				graphicMaterialsInput = input;
																			}}
																			// accept="image/x-png,image/gif,image/jpeg"
																			onChange={(event) =>
																				fileUploadHandler(
																					event,
																					"eventsGraphicsMaterials"
																				)
																			}
																			name="graphic_materials"
																			// onBlur={inputBlurHandler}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="summary"
																	name="summary"
																	onBlur={inputBlurHandler}
																	defaultValue={item.summary}
																	multiline
																	label="Опис"
																	// helperText={formValidation.fields.title.text}
																	// error={formValidation.fields.title.isError}
																/>
															</FormControl>
														</div>
													</div>
													<div className="row p-t-20 p-b-20">
														{/* <div className="col-md-12"> */}
														<div className="col-md-6">
															{speakersList ? (
																<Autocomplete
																	multiple
																	id="tags-standard"
																	// options={eventConstants.people}
																	options={speakersList}
																	getOptionLabel={(option) =>
																		`${option.name} ${option.surname}`
																	}
																	// onBlur={inputBlurHandler}
																	onChange={(event, newValue) => {
																		autocompleteHandler(event, newValue);
																	}}
																	value={[]}
																	renderTags={(value, getTagProps) =>
																		value.map((option, index) => (
																			<Chip
																				variant="outlined"
																				label={`${option.name} ${option.surname}`}
																				{...getTagProps({ index })}
																				style={{ display: "none" }}
																			/>
																		))
																	}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			// variant="filled"
																			label="Спікери"
																		/>
																	)}
																/>
															) : (
																""
															)}
														</div>
														{/* </div> */}
														<div className="col-md-6">
															{eventConstants.people && item.speakers
																? item.speakers.map((speaker) => {
																		return (
																			<Chip
																				label={`${
																					eventConstants.people.find(
																						(p) => p._id == speaker
																					).name
																				} ${
																					eventConstants.people.find(
																						(p) => p._id == speaker
																					).surname
																				}`}
																				onDelete={() =>
																					handleSpeakerDelete(speaker)
																				}
																			/>
																		);
																  })
																: ""}
														</div>
													</div>

													<h2 className="m-t-50">Додаткова інформація</h2>
												</div>
												<div className="col-md-6 books-right-column-wrapper">
													{item.isExternal && item.title ? (
														<>
															<h2>Медіа промо</h2>
															<div className="row">
																{eventConstants.promo_media
																	? eventConstants.promo_media.map(
																			(p_media) => {
																				return (
																					<div className="col-md-6">
																						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																							<TextField
																								id={p_media.title}
																								name={`${p_media.title}-link`}
																								onBlur={promoMediaBlurHandler}
																								defaultValue={
																									item.promo_media
																										? item.promo_media[
																												`${p_media.title}-link`
																										  ]
																										: ""
																								}
																								label={`${p_media.title} посилання`}
																								// helperText={formValidation.fields.title.text}
																								// error={formValidation.fields.title.isError}
																							/>
																						</FormControl>
																					</div>
																				);
																			}
																	  )
																	: ""}
															</div>

															<div className="row">
																{eventConstants.promo_media
																	? eventConstants.promo_media.map(
																			(p_media) => {
																				return (
																					<div className="col-md-6">
																						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																							<TextField
																								id={p_media.title}
																								name={`${p_media.title}-count`}
																								type="number"
																								onBlur={promoMediaBlurHandler}
																								defaultValue={
																									item.promo_media
																										? item.promo_media[
																												`${p_media.title}-count`
																										  ]
																										: ""
																								}
																								label={`Участників з ${p_media.title}`}
																								// helperText={formValidation.fields.title.text}
																								// error={formValidation.fields.title.isError}
																							/>
																						</FormControl>
																					</div>
																				);
																			}
																	  )
																	: ""}
																{/* </div> */}
															</div>
														</>
													) : (
														""
													)}

													<h2 className="m-t-50">Статистика участників</h2>
													<div className="row">
														<div className="col-md-12">
															<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																<TextField
																	id="participants_total"
																	className="event-participants_total"
																	name="participants_total"
																	type="number"
																	disabled
																	onBlur={inputBlurHandler}
																	value={item.participants_total}
																	label="Всього участників"
																	// helperText={formValidation.fields.title.text}
																	// error={formValidation.fields.title.isError}
																/>
															</FormControl>
														</div>
													</div>
													{item.isExternal ? (
														<div className="row">
															<div className="col-md-12">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="subscribed_to_course"
																		name="subscribed_to_course"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.subscribed_to_course}
																		label="Зареєструвались на перший цикл"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_first_time"
																		name="participants_first_time"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_first_time}
																		label="Беруть участь вперше"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_returning"
																		name="participants_returning"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_returning}
																		label="Участники що повернулись"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_friends"
																		name="participants_friends"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_friends}
																		label="Друзів школи"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_probationists"
																		name="participants_probationists"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={
																			item.participants_probationists
																		}
																		label="Пробаціоністів"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_members"
																		name="participants_members"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_members}
																		label="Членів"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_ffvv"
																		name="participants_ffvv"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_ffvv}
																		label="FFVV"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
															<div className="col-md-6">
																<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
																	<TextField
																		id="participants_instructors"
																		name="participants_instructors"
																		type="number"
																		onBlur={inputBlurHandler}
																		defaultValue={item.participants_instructors}
																		label="З яких інструктори"
																		// helperText={formValidation.fields.title.text}
																		// error={formValidation.fields.title.isError}
																	/>
																</FormControl>
															</div>
														</div>
													) : (
														<div className="row">Внутрішні</div>
													)}
												</div>
											</>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default connect(
	(store) => ({}),
	(dispatch) =>
		bindActionCreators(
			{
				setLoading: Loading,
			},
			dispatch
		)
)(EventView);
