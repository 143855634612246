import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const PeopleInstructorCandidateInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="instructor_candidate_start_date"
								name="instructor_candidate_start_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.instructor_candidate_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="instructor_candidate_study_groups"
								name="instructor_candidate_study_groups"
								label="Групи викладання"
								defaultValue={props.person.instructor_candidate_study_groups}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							/>
						</FormControl>
					</div>
				</div>
			</form>
		</>
	);
};

export default PeopleInstructorCandidateInfo;
