import React, { useState, useEffect } from "react";

import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../../api/apiRequest";
import { tableSettings } from "../../../configs/visualizationConfig";

const BranchesSettings = (props) => {
	let fileInput = null;

	const host = "BranchesConfig";
	const endpointPurposes = {
		getData: "getBranches",
		createItem: "createBranch",
		updateItem: "updateBranch",
		deleteItem: "deleteBranch",
		uploadFile: "uploadFileBranches",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	//// !!! Change the structure !!!
	const [newItem, setNewItem] = useState({
		title: "",
		parentBranchId: "",
		parentBranch: "",
		appearanceOrder: "",
		status: true,
		reportName: "",
		reportStatus: true,
	});

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState({
		id: "",
		title: "",
		parentBranchId: "",
		parentBranch: "",
		appearanceOrder: "",
		status: true,
		reportName: "",
		reportStatus: true,
	});

	const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const [titleError, setTitleError] = useState(false);
	const [appearanceOrderError, setAppearanceOrderError] = useState(false);
	const [reportNameError, setReportNameError] = useState(false);

	const getItemsFromApi = () => {
		let request = {
			purpose: endpointPurposes.getData,
		};

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let tempArray = [];
					tempArray.push(element.title);
					tempArray.push(element.parentBranch);
					tempArray.push(element.appearanceOrder);
					tempArray.push(element.status);
					tempArray.push(element.reportName);
					tempArray.push(element.reportStatus);
					tempArray.push(element.id);

					dataArray.push(tempArray);
				});

				setItems(result.items);
				setTableData(dataArray);
			}
		});
	};

	useEffect(() => {
		getItemsFromApi();
	}, []);

	//// !!! Change the structure !!!
	const columns = [
		"Назва",
		"Материнський осередок",
		"Порядок",
		{
			name: "Активний",
			label: "Активний",
			options: {
				customBodyRender: (value) => {
					if (value) {
						return "Так";
					} else {
						return "Ні";
					}
				},
			},
		},
		"Назва звіту",
		{
			name: "Звіти",
			label: "Звіти",
			options: {
				customBodyRender: (value) => {
					if (value) {
						return "Так";
					} else {
						return "Ні";
					}
				},
			},
		},

		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<Tooltip title="Редагувати">
							<IconButton
								aria-label="Редагувати"
								onClick={() => editItemHandler(value)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Видалити">
							<IconButton
								aria-label="Видалити"
								onClick={() => deleteItemHandler(value)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		if (!updateMode) {
			setNewItemStateSave({
				...newItem,
				[event.target.name]: event.target.value,
			});
		}

		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const handleSwitcherChange = (event) => {
		if (!updateMode) {
			setNewItemStateSave({
				...newItem,
				[event.target.name]: event.target.checked,
			});
		}

		setNewItem({
			...newItem,
			[event.target.name]: event.target.checked,
		});
	};

	const handleParentCheck = (event) => {
		let parentTitle = "";
		let parentId = event.target.value;

		if (event.target.value != null) {
			items.forEach((element) => {
				if (element.id == event.target.value) {
					parentTitle = element.title;
				}
			});
		}

		setNewItem({
			...newItem,
			parentBranch: parentTitle,
			parentBranchId: parentId,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
		setUpdateMode(false);
	};

	const dialogCloseHandler = () => {
		if (updateMode) {
			setNewItem(newItemStateSave);
		}
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem({
			title: "",
			reportName: "",
		});
		setDialogOpen(false);

		setTitleError(false);
		setAppearanceOrderError(false);
		setReportNameError(false);

		if (updateMode) {
			setSaveStateItemInput(true);
		} else {
			setSaveStateItemInput(false);
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!newItem.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!newItem.appearanceOrder) {
			valid = false;
			setAppearanceOrderError(true);
		} else {
			setAppearanceOrderError(false);
		}

		if (!newItem.reportName) {
			valid = false;
			setReportNameError(true);
		} else {
			setReportNameError(false);
		}

		return valid;
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let isValid = validateForm();
		if (isValid) {
			let newItemObject = {
				title: newItem.title,
				parentBranchId: newItem.parentBranchId,
				parentBranch: newItem.parentBranch,
				appearanceOrder: newItem.appearanceOrder,
				status: newItem.status,
				reportName: newItem.reportName,
				reportStatus: newItem.reportStatus,
			};

			let request = {
				purpose: endpointPurposes.createItem,
				body: newItemObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				getItemsFromApi();
				dialogCancelHandler();
				setUpdateMode(false);
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Осередок " + newItemObject.title + " успішно створено!"
				);
			});
		}
	};

	// Edit dialog handlers

	const editItemHandler = (id) => {
		let itemToEdit = {};

		items.forEach((element) => {
			if (element.id == id) {
				itemToEdit = element;
			}
		});

		setUpdateMode(true);
		setNewItemStateSave(newItem);
		// setEditCategory(categoryToEdit);
		setNewItem(itemToEdit);
		setDialogOpen(true);

		setTitleError(false);
		setAppearanceOrderError(false);
		setReportNameError(false);
	};

	const handleUpdateSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let isValid = validateForm();
		if (isValid) {
			let newItemObject = {
				id: newItem.id,
				title: newItem.title,
				parentBranchId: newItem.parentBranchId,
				parentBranch: newItem.parentBranch,
				appearanceOrder: newItem.appearanceOrder,
				status: newItem.status,
				reportName: newItem.reportName,
				reportStatus: newItem.reportStatus,
			};

			let request = {
				purpose: endpointPurposes.updateItem,
				body: newItemObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				getItemsFromApi();
				dialogCancelHandler();
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Осередок " + newItemObject.title + " успішно оновлено!"
				);
			});
		}
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi();
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Видалено " + itemsWaitingForDelete.length + " осередки(ів)"
				);
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);
			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage(
				"Ви впевнені що хочете видалити обраний осередок?"
			);
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index].id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " осередки?"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				let parentItem = "";
				let parentItemId = "";
				let excelParentItem = data[index]["Материнський осередок"];

				if (excelParentItem) {
					items.forEach((element) => {
						if (element.title == excelParentItem) {
							parentItem = element.title;
							parentItemId = element.id;
						}
					});
				}

				itemsUpload.push({
					title: data[index]["Осередок"],
					appearanceOrder: data[index]["Порядок сортування"],
					status: data[index]["Активний"] == "Так" ? true : false,
					reportName: data[index]["Назва звіту"],
					reportStatus: data[index]["Звіт активний"] == "Так" ? true : false,
					parentBranch: parentItem,
					parentBranchId: parentItemId,
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					setSnackBarOpen(true);
					setSnackBarMessage("Було завантажено " + counter + " записи(ів)!");
				}
			});
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "Branches-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			dataArray.push({
				Осередок: element.title,
				"Порядок сортування": element.appearanceOrder,
				"Материнський осередок": element.parentBranch,
				Активний: element.status ? "Так" : "Ні",
				"Назва звіту": element.reportName,
				"Звіт активний": element.reportStatus ? "Так" : "Ні",
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;

	const options = tableSettingsLocal;

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Книги - Налаштування</h1>
										<ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Осередки</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="categories">
							<div class="row">
								<div class="col-xs-12">
									<div class="content">
										<header class="content-heading">
											<div className="card-actions-left-wrapper">
												<button
													class="btn btn-green"
													onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Додати осередок
												</button>
											</div>
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={fileClickHandler}
													>
														<i class="zmdi zmdi-upload"></i>
														<input
															type="file"
															style={{ display: "none" }}
															ref={(input) => {
																fileInput = input;
															}}
															accept=".csv, .xls, .xlsx"
															onChange={fileUploadHandler}
														/>
														{/* /> */}
													</button>
												</li>
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={prepareDataToExport}
													>
														<i class="zmdi zmdi-download"></i>
													</button>
												</li>
											</ul>
										</header>
										<div class="content-body m-t-20">
											<MUIDataTable
												title={"Осередки"}
												data={tableData}
												columns={columns}
												options={options}
											/>
										</div>
									</div>
								</div>

								{/* Create dialog */}
								<Dialog
									open={dialogOpen}
									onClose={dialogCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div class="modal-dialog">
										<div class="modal-content">
											<DialogTitle className="modal-header">
												<h4 class="modal-title" id="myModalLabel-2">
													{updateMode
														? "Редагувати інформацію"
														: "Додати осередок"}
												</h4>
												<ul class="card-actions icons right-top">
													<a
														href="javascript:void(0)"
														data-dismiss="modal"
														class="text-white"
														aria-label="Close"
														onClick={dialogCloseHandler}
													>
														<i class="zmdi zmdi-close"></i>
													</a>
												</ul>
											</DialogTitle>
											<div class="modal-body">
												<form
													onSubmit={
														updateMode ? handleUpdateSave : handleFormSave
													}
												>
													<FormGroup row>
														<FormControlLabel
															control={
																<Switch
																	checked={newItem.status}
																	onChange={handleSwitcherChange}
																	name="status"
																	color="primary"
																/>
															}
															label="Активний"
														/>
														<FormControlLabel
															control={
																<Switch
																	checked={newItem.reportStatus}
																	onChange={handleSwitcherChange}
																	name="reportStatus"
																	color="primary"
																/>
															}
															label="Звіт"
														/>
													</FormGroup>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															autoFocus
															id="branch-title-input"
															name="title"
															value={newItem.title}
															onChange={handleInputChange}
															label="Назва"
															defaultValue=""
															helperText={titleError ? "Поле обов'язкове" : ""}
															error={titleError}
														/>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-branch-parrent-label">
															Материнський осередок
														</InputLabel>
														<Select
															labelId="settings-modal-input-branch-parrent-label"
															id="settings-modal-input-branch-parrent"
															name="parent"
															label="Материнський осередок"
															value={newItem.parentBranchId}
															onChange={(event) => handleParentCheck(event)}
														>
															<MenuItem selected value={null}>
																-- немає --
															</MenuItem>
															{items.map(function(item, i) {
																if (item.id != newItem.id) {
																	return (
																		<MenuItem value={item.id}>
																			{item.title}
																		</MenuItem>
																	);
																}
															})}
														</Select>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															type="number"
															id="branch-appearance-order-input"
															name="appearanceOrder"
															value={newItem.appearanceOrder}
															onChange={handleInputChange}
															label="Порядок сортування"
															defaultValue=""
															helperText={
																appearanceOrderError ? "Поле обов'язкове" : ""
															}
															error={appearanceOrderError}
														/>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															id="branch-report-input"
															name="reportName"
															value={newItem.reportName}
															onChange={handleInputChange}
															label="Назва звіту"
															defaultValue=""
															helperText={
																reportNameError ? "Поле обов'язкове" : ""
															}
															error={reportNameError}
														/>
													</FormControl>
												</form>
											</div>
											<div class="modal-footer">
												<button
													type="button"
													class="btn btn-default btn-flat"
													data-dismiss="modal"
													onClick={dialogCancelHandler}
												>
													Скасувати
												</button>
												{updateMode ? (
													<button
														type="button"
														class="btn btn-primary"
														onClick={handleUpdateSave}
														// onClick={validateForm}
													>
														Зберегти
													</button>
												) : (
													<button
														type="button"
														class="btn btn-primary"
														onClick={handleFormSave}
														// onClick={validateForm}
													>
														Створити
													</button>
												)}
											</div>
										</div>
									</div>
								</Dialog>

								<Dialog
									open={alertOpen}
									onClose={alertCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div className="flamingo-alert-body-wrapper">
										<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
										{alertWarningMessage}
									</div>
									<div className="flamingo-alert-buttons-wrapper">
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={alertCloseHandler}
										>
											Скасувати
										</button>
										{alertActionButton}
									</div>
								</Dialog>
								<Snackbar
									open={snackBarOpen}
									autoHideDuration={6000}
									onClose={handleSnackBarClose}
								>
									<MuiAlert
										onClose={handleSnackBarClose}
										severity="success"
										elevation={6}
										variant="filled"
									>
										{snackBarMessage}
									</MuiAlert>
								</Snackbar>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BranchesSettings;
