import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const PeopleCandidateInfo = (props) => {
	console.log("Candidate instructor test: ", props.candidateStages);
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="candidate_logos-label">Логос</InputLabel>
							<Select
								labelId="candidate_logos-label"
								id="candidate_logos"
								name="candidate_logos"
								disabled
								defaultValue={props.person.candidate_logos}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.instructorOrigins.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="candidate_stage-label">Стадія</InputLabel>
							<Select
								labelId="candidate_stage-label"
								id="candidate_stage"
								name="candidate_stage"
								defaultValue={props.person.candidate_stage}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.candidateStages.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="candidate_start_date"
								name="candidate_start_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.candidate_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
			</form>
		</>
	);
};

export default PeopleCandidateInfo;
