import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import apiConfig from "../../api/apiConfig";
import * as ApiRequest from "../../api/apiRequest";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../actions";
import PeopleMemberActivitiesInfo from "./PeopleMemberActivitiesInfo";

const PeopleMemberInfo = (props) => {
	const [qualificationsSelects, setQualificationsSelect] = useState([]);
	const [qualifications, setQualifications] = useState({});

	const [loading, setLoading] = useState("");

	useEffect(() => {
		setQualifications(props.person.member_completedCourses_qualifications);
		formQualificationSelects();
	}, [props.person]);

	const formQualificationSelects = () => {
		let qualificationsArray =
			props.person.member_completedCourses_qualifications;
		let courses = props.courses;

		let selectsArray = [];

		qualificationsArray.forEach((element, index) => {
			selectsArray.push(
				<>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="member_currentCourse-label">
								{element.courseName}
							</InputLabel>
							<Select
								labelId="member_currentCourse-label"
								id={"member_currentCourse-" + element.courseId}
								name={"member_currentCourse-" + element.courseId}
								value={element.qualification}
								onChange={(event) =>
									handleQualificationChange(event, element.courseId)
								}
								// onBlur={props.handleItemUpdate}
							>
								{props.courseQualification.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</>
			);
		});

		setQualificationsSelect(selectsArray);
	};

	const handleQualificationChange = (event, courseSelected) => {
		props.setLoading(true);

		setLoading("loading-box");

		let qualificationObject = {
			course: courseSelected,
			qualification: event.target.value,
			person: props.person._id,
		};

		let request = {
			purpose: "updateCourseQualification",
			body: qualificationObject,
		};
		console.log("qualification change: ", request);

		ApiRequest.apiRequest("People", request).then((result) => {
			if (result.success) {
				setLoading(false);
				console.log(props.person.slug);
				props.getPersonData(props.person.slug);
			} else {
				console.log("Error updating");
			}
		});
	};

	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="study_group"
								name="study_group"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.study_group}
								// onChange={props.handleInputChange}
								label="Группа навчання"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="member_currentCourse-label">
								Прослухує курс
							</InputLabel>
							<Select
								labelId="member_currentCourse-label"
								id="member_currentCourse"
								name="member_currentCourse"
								defaultValue={props.person.member_currentCourse}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.courses.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className={"row acordion-row col-md-12 " + loading}>
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<InputLabel id="settings-modal-input-branch-parrent-label">
							Прослухані курси
						</InputLabel>
						<Select
							labelId="member_completedCourses-label"
							multiple
							multiline
							rows="4"
							id="member_completedCourses"
							name="member_completedCourses"
							defaultValue={props.person.member_completedCourses}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
							renderValue={(selected) => (
								<div className="multiline-chip-select">
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												props.courses.find((element) => element._id == value)
													.title
											}
											className="multiline-chip-select-chip"
										/>
									))}
								</div>
							)}
						>
							{/* <MenuItem value={null}>-- немає --</MenuItem> */}

							{props.courses.map(function(item, i) {
								return <MenuItem value={item._id}>{item.title}</MenuItem>;
							})}
						</Select>
					</FormControl>
				</div>
				<div className={"row acordion-row " + loading}>
					<h3 className="people-acordeon-inside-header col-md-12">
						Успішність пройдених курсів
					</h3>
					{qualificationsSelects}
				</div>
				<div>
					{props.person.member_activities.length == 0 ? (
						""
					) : (
						<>
							<h3 className="people-acordeon-inside-header">
								Членська активність
							</h3>
							<PeopleMemberActivitiesInfo
								person={props.person}
								branches={props.branches}
								hierarchy={props.hierarchy}
								getPersonData={props.getPersonData}
								secretary={props.secretary}
								// getPersonData={props.getPersonData}
							/>
						</>
					)}
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="member_newsletter-label">Розсилка</InputLabel>
							<Select
								labelId="member_newsletter-label"
								id="member_newsletter"
								name="member_newsletter"
								defaultValue={props.person.member_newsletter}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.internalNewsletters.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="member_memberSince"
								name="member_memberSince"
								label="Дата початку"
								type="date"
								defaultValue={props.person.member_memberSince}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
			</form>
		</>
	);
};

export default connect(
	(store) => ({}),
	(dispatch) =>
		bindActionCreators(
			{
				setLoading: Loading,
			},
			dispatch
		)
)(PeopleMemberInfo);
