import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";

import InputMask from "react-input-mask";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";

const PeopleThirdCircleInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="initialContactDate"
								name="initialContactDate"
								label="Дата першого контакту"
								type="date"
								defaultValue={props.person.initialContactDate}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="settings-modal-input-branch-parrent-label">
								Канал першого контакту
							</InputLabel>
							<Select
								labelId="person-input-initial-contact-channel-label"
								id="initial-contact-channel"
								name="third_initialContactChannel"
								defaultValue={props.person.third_initialContactChannel}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{/* <MenuItem value={null}>-- немає --</MenuItem> */}
								{props.initialContactChannels.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="settings-modal-input-branch-parrent-label">
								Медіа першого контакту
							</InputLabel>
							<Select
								labelId="person-input-initial-contact-media-label"
								id="initial-contact-media"
								name="third_initialContactMedia"
								defaultValue={props.person.third_initialContactMedia}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{/* <MenuItem value={null}>-- немає --</MenuItem> */}

								{props.initialContactMedia.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<InputLabel id="settings-modal-input-branch-parrent-label">
							Інтереси
						</InputLabel>
						<Select
							labelId="person-input-initial-contact-media-label"
							multiple
							id="interests"
							name="third_interests"
							defaultValue={props.person.third_interests}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
							renderValue={(selected) => (
								<div>
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												props.interests.find((element) => element._id == value)
													.title
											}
										/>
									))}
								</div>
							)}
						>
							{/* <MenuItem value={null}>-- немає --</MenuItem> */}

							{props.interests.map(function (item, i) {
								return <MenuItem value={item._id}>{item.title}</MenuItem>;
							})}
						</Select>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<InputLabel id="settings-modal-input-branch-parrent-label">
							Розсилки
						</InputLabel>
						<Select
							labelId="person-input-initial-contact-media-label"
							multiple
							id="third-newsletter"
							name="third_newsletters"
							defaultValue={props.person.third_newsletters}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
							renderValue={(selected) => (
								<div>
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												props.externalNewsletters.find(
													(element) => element._id == value
												).title
											}
										/>
									))}
								</div>
							)}
						>
							{/* <MenuItem value={null}>-- немає --</MenuItem> */}

							{props.externalNewsletters.map(function (item, i) {
								return <MenuItem value={item._id}>{item.title}</MenuItem>;
							})}
						</Select>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							multiline
							rows="4"
							id="remarks"
							name="notes"
							onBlur={props.handleItemUpdate}
							defaultValue={props.person.notes}
							// onChange={props.handleInputChange}
							label="Ремарки"
							// helperText={formValidation.fields.title.text}
							// error={formValidation.fields.title.isError}
						/>
					</FormControl>
				</div>
			</form>
		</>
	);
};

export default PeopleThirdCircleInfo;
