import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import InputMask from "react-input-mask";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";

const PeoplePrivateDetails = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_placeOfBirth-input"
								name="private_placeOfBirth"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_placeOfBirth}
								// onChange={props.handleInputChange}
								label="Місце народження"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_nationality-input"
								name="private_nationality"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_nationality}
								// onChange={props.handleInputChange}
								label="Національність"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_ID-input"
								name="private_ID"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_ID}
								// onChange={props.handleInputChange}
								label="ID"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_passportName-input"
								name="private_passportName"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_passportName}
								// onChange={props.handleInputChange}
								label="Ім'я з паспорту"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_passportSurname-input"
								name="private_passportSurname"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_passportSurname}
								// onChange={props.handleInputChange}
								label="Призвище з паспорту"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_passportNumber-input"
								name="private_passportNumber"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_passportNumber}
								// onChange={props.handleInputChange}
								label="Номер паспорту"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="private_memberCardNumber-input"
								name="private_memberCardNumber"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.private_memberCardNumber}
								// onChange={props.handleInputChange}
								label="Номер картки Акропольця"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
				</div>

				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControlLabel
							// value="start"
							control={
								<Switch
									color="primary"
									name="private_deceased"
									checked={props.person.private_deceased}
									onBlur={props.handleItemUpdate}
									onChange={props.handleSwitchChange}
								/>
							}
							label="Хвороба"
							labelPlacement="start"
							className="p-t-20"
						/>
					</div>
					<div className="col-md-6">
						{props.person.private_deceased ? (
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									id="deceased-date"
									name="private_deceaseDate"
									label="Дата захворювання"
									type="date"
									defaultValue={props.person.private_deceaseDate}
									// onChange={props.handleInputChange}
									onBlur={props.handleItemUpdate}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</FormControl>
						) : (
							""
						)}
					</div>
				</div>
			</form>
		</>
	);
};

export default PeoplePrivateDetails;
