import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@mui/x-date-pickers";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import InputMask from "react-input-mask";
import Moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../api/apiRequest";
import { tableSettings } from "../../configs/visualizationConfig";

const Events = (props) => {
	let fileInput = null;

	const host = "Events";
	const endpointPurposes = {
		getData: "get",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [isExternal, setIsExternal] = useState(false);
	const [isExternalText, setIsExternalText] = useState("");

	//// !!! Change the structure !!!
	const [newItem, setNewItem] = useState({
		name: "",
		surname: "",
		gender: true,
		email: "",
		phone: "",
		branch: "",
		initialContactDate: "",
		remarks: "",
	});

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState({
		id: "",
		name: "",
		surname: "",
		gender: "",
		email: "",
		phone: "",
		branch: "",
		initialContactDate: "",
		remarks: "",
	});

	const [branches, setBranches] = useState([]);

	// const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [eventTypes, setEventTypes] = useState();
	const [eventCategories, setEventCategories] = useState();

	const [titleError, setTitleError] = useState(false);
	const [eventTypeError, setEventTypeError] = useState(false);
	const [branchError, setBranchError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);

	const getItemsFromApi = (external) => {
		// let external = isExternal;
		// if (external == undefined) {
		// 	external = externalCall;
		// }
		let request = {
			purpose: endpointPurposes.getData,
			isExternal: external,
		};

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let branchName = "";

					let tempArray = [];
					tempArray.push(element.title);
					tempArray.push(element.location);
					tempArray.push(
						result.event_types.find((type) => type._id == element.event_type)
							? result.event_types.find(
									(type) => type._id == element.event_type
							  ).title
							: ""
					);
					tempArray.push(
						element.branch
							? result.branches.find((branch) => branch._id == element.branch)
								? result.branches.find((branch) => branch._id == element.branch)
										.title
								: ""
							: ""
					);
					tempArray.push(element.start_date);
					tempArray.push(element.participants_total);
					tempArray.push(element._id);

					dataArray.push(tempArray);
				});

				setItems(result.items);
				setTableData(dataArray);
				setBranches(result.branches);
				setEventTypes(result.event_types);
				setEventCategories(result.event_categories);
			}
		});
	};

	useEffect(() => {
		const isExternalUrl = props.match.params.param == "external" ? true : false;

		setIsExternal(isExternal);
		setIsExternalText(isExternalUrl ? "Зовнішні" : "Внутрішні");
		getItemsFromApi(isExternalUrl);
	}, [props.match.params.param]);

	//// !!! Change the structure !!!
	const columns = [
		"Подія",
		"Локація",
		{
			name: "Тип",
			label: "Тип",
			options: {
				// customBodyRender: (value) =>
				// 	eventTypes.find((type) => type._id == value).title,
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Осередок",
			label: "Осередок",
			options: {
				// customBodyRender: (value) =>
				// 	branches.find((branch) => branch._id == value).title,
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Дата",
			label: "Дата",
		},
		{
			name: "Участників",
			label: "Участників",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<div className="table-row-action-people-buttons-wrapper">
							<Tooltip title="Видалити">
								<IconButton
									aria-label="Видалити"
									onClick={() => deleteItemHandler(value)}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Перегляд/Редагувати">
								<NavLink to={"/events/view/" + value}>
									<IconButton
										aria-label="Перегляд/Редагувати"
										// onClick={() => deleteItemHandler(value)}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</NavLink>
							</Tooltip>
						</div>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		setNewItemStateSave({
			...newItem,
			[event.target.name]: event.target.value,
		});

		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const handleSwitcherChange = (event) => {
		setNewItemStateSave({
			...newItem,
			[event.target.name]: event.target.checked,
		});

		setNewItem({
			...newItem,
			[event.target.name]: event.target.checked,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem({});
		setDialogOpen(false);
		setNewItem({});
		setSaveStateItemInput(false);

		setTitleError(false);
		setEventTypeError(false);
		setBranchError(false);
		setStartDateError(false);
	};

	const validateForm = () => {
		let valid = true;

		if (!newItem.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!newItem.event_type) {
			valid = false;
			setEventTypeError(true);
		} else {
			setEventTypeError(false);
		}

		if (!newItem.branch) {
			valid = false;
			setBranchError(true);
		} else {
			setBranchError(false);
		}

		if (!newItem.start_date) {
			valid = false;
			setStartDateError(true);
		} else {
			setStartDateError(false);
		}

		return valid;
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const data = new FormData(event.target);
		var item = {
			// _id: props.person._id,
		};

		// currentFormStructure.form.forEach((element) => {
		// 	item[element.name] = data.get(element.name);
		// });
		item.title = data.get("title");
		item.event_type = data.get("event_type");
		item.branch = data.get("branch");
		item.location = data.get("location");
		item.start_date = data.get("start_date");
		item.end_date = data.get("end_date");
		item.summary = data.get("summary");
		item.isExternal = isExternal;

		let valid = true;

		if (!item.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!item.event_type) {
			valid = false;
			setEventTypeError(true);
		} else {
			setEventTypeError(false);
		}

		if (!item.branch) {
			valid = false;
			setBranchError(true);
		} else {
			setBranchError(false);
		}

		if (!item.start_date) {
			valid = false;
			setStartDateError(true);
		} else {
			setStartDateError(false);
		}

		if (valid) {
			let request = {
				purpose: endpointPurposes.createItem,
				body: item,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi(isExternal);
					dialogCancelHandler();
					// setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage(`Подія ${newItem.title} успішно додан(а)!`);
				} else if (result.message == "exist") {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(`Подія ${newItem.title} вже існує`);
				}
			});
		}
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi(isExternal);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Видалено " + itemsWaitingForDelete.length + " подій"
				);
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обрану подію?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index]._id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " подій?"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				// let parentItem = "";
				// let parentItemId = "";
				// let excelParentItem = data[index]["Материнський осередок"];

				// if (excelParentItem) {
				// 	items.forEach((element) => {
				// 		if (element.title == excelParentItem) {
				// 			parentItem = element.title;
				// 			parentItemId = element.id;
				// 		}
				// 	});
				// }

				itemsUpload.push({
					title: data[index]["Подія"],
					location: data[index]["Локація"],
					event_type: data[index]["Тип"],
					branch: data[index]["Осередок"],
					start_date: data[index]["Дата"],
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi(isExternal);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage("Було завантажено " + counter + " людей!");
				}
			});
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "People-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			dataArray.push({
				Подія: element.title,
				Локація: element.location,
				Тип: element.event_type,
				Осередок: element.branch,
				Дата: element.start_date,
				"Кількість участників": element.participants_total,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-md-12">
									<header id="header">
										<h1>Події</h1>
										<ol class="breadcrumb">
											{/* <li>
												<a href="index.html">Головна</a>
											</li> */}
											{/* <li>
												<a href="javascript:void(0)">Налаштування</a>
											</li> */}
											<li class="active">{isExternalText}</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="categories">
							<div class="row">
								<div class="col-xs-12">
									<div class="content">
										<header class="content-heading">
											<div className="card-actions-left-wrapper">
												<button
													class="btn btn-green"
													onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Додати подію
												</button>
											</div>
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={fileClickHandler}
													>
														<i class="zmdi zmdi-upload"></i>
														<input
															type="file"
															style={{ display: "none" }}
															ref={(input) => {
																fileInput = input;
															}}
															accept=".csv, .xls, .xlsx"
															onChange={fileUploadHandler}
														/>
														{/* /> */}
													</button>
												</li>
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={prepareDataToExport}
													>
														<i class="zmdi zmdi-download"></i>
													</button>
												</li>
											</ul>
										</header>
										<div class="content-body m-t-20">
											<MUIDataTable
												title={"Події"}
												data={tableData}
												columns={columns}
												options={options}
											/>
										</div>
									</div>
								</div>

								{/* Create dialog */}
								{eventTypes ? (
									<Dialog
										open={dialogOpen}
										onClose={dialogCloseHandler}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										className="flamingo-modal"
									>
										<div class="modal-dialog">
											<form onSubmit={handleFormSave} noValidate>
												<div class="modal-content">
													<DialogTitle className="modal-header">
														<h4 class="modal-title" id="myModalLabel-2">
															Додати подію
														</h4>
														<ul class="card-actions icons right-top">
															<a
																href="javascript:void(0)"
																data-dismiss="modal"
																class="text-white"
																aria-label="Close"
																onClick={dialogCloseHandler}
															>
																<i class="zmdi zmdi-close"></i>
															</a>
														</ul>
													</DialogTitle>
													<div class="modal-body">
														{/* <form onSubmit={handleFormSave}> */}
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																required
																autoFocus
																id="title"
																name="title"
																value={newItem.title}
																onChange={handleInputChange}
																label="Подія"
																defaultValue=""
																helperText={
																	titleError ? "Поле обов'язкове" : ""
																}
																error={titleError}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<InputLabel
																id="event_type-label"
																className={eventTypeError && "red"}
															>
																Тип події *
															</InputLabel>
															<Select
																labelId="event_type-label"
																id="event_type"
																name="event_type"
																value={newItem.event_type}
																onChange={handleInputChange}
																required
																error={eventTypeError}
															>
																{eventTypes
																	// .filter(
																	// 	(type) => type.isExternal == isExternal
																	// )
																	.map(function(item, i) {
																		return (
																			<MenuItem value={item._id}>
																				{item.title}
																			</MenuItem>
																		);
																	})}
															</Select>
															{eventTypeError && (
																<FormHelperText className="red">
																	Поле обов'язкове
																</FormHelperText>
															)}
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<InputLabel
																id="branch-label"
																className={branchError && "red"}
															>
																Осередок *
															</InputLabel>
															<Select
																labelId="branch-label"
																id="branch"
																name="branch"
																value={newItem.branch}
																onChange={handleInputChange}
																required
																error={branchError}
															>
																{branches.map(function(item, i) {
																	return (
																		<MenuItem value={item._id}>
																			{item.title}
																		</MenuItem>
																	);
																})}
															</Select>
															{branchError && (
																<FormHelperText className="red">
																	Поле обов'язкове
																</FormHelperText>
															)}
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="location"
																name="location"
																value={newItem.location}
																onChange={handleInputChange}
																label="Локація"
																defaultValue=""
																// helperText={formValidation.fields.title.text}
																// error={formValidation.fields.title.isError}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="start_date"
																name="start_date"
																label="Дата"
																type="date"
																defaultValue={Moment().format("YYYY-MM-DD")}
																value={newItem.start_date}
																onChange={handleInputChange}
																InputLabelProps={{
																	shrink: true,
																}}
																helperText={
																	startDateError ? "Поле обов'язкове" : ""
																}
																error={startDateError}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="end_date"
																name="end_date"
																label="Дата завершення"
																type="date"
																defaultValue={Moment().format("YYYY-MM-DD")}
																value={newItem.end_date}
																onChange={handleInputChange}
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="summary"
																name="summary"
																value={newItem.summary}
																onChange={handleInputChange}
																label="Опис"
																defaultValue=""
																// helperText={formValidation.fields.title.text}
																// error={formValidation.fields.title.isError}
															/>
														</FormControl>
														{/* </form> */}
													</div>
													<div class="modal-footer">
														<button
															type="button"
															class="btn btn-default btn-flat"
															data-dismiss="modal"
															onClick={dialogCancelHandler}
														>
															Скасувати
														</button>

														<button
															type="submit"
															class="btn btn-primary"
															// onClick={handleFormSave}
															// onClick={validateForm}
														>
															Створити
														</button>
													</div>
												</div>
											</form>
										</div>
									</Dialog>
								) : (
									""
								)}
								<Dialog
									open={alertOpen}
									onClose={alertCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div className="flamingo-alert-body-wrapper">
										<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
										{alertWarningMessage}
									</div>
									<div className="flamingo-alert-buttons-wrapper">
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={alertCloseHandler}
										>
											Скасувати
										</button>
										{alertActionButton}
									</div>
								</Dialog>
								<Snackbar
									open={snackBarOpen}
									autoHideDuration={6000}
									onClose={handleSnackBarClose}
								>
									<MuiAlert
										onClose={handleSnackBarClose}
										severity={snackBarSeverity}
										elevation={6}
										variant="filled"
									>
										{snackBarMessage}
									</MuiAlert>
								</Snackbar>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Events;
