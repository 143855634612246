import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import RoomIcon from "@mui/icons-material/Room";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";

import * as ApiRequest from "../../api/apiRequest";

const PeopleBonfiresInfo = (props) => {
	const host = "People";
	const endpointPurposes = {
		updateItem: "updateBonfire",
		deleteItem: "deleteBonfire",
	};

	const [item, setItem] = useState({});

	const [bonfiresCards, setBonfiresCards] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	useEffect(() => {
		fillTable();
	}, [props.person]);

	const onRowClick = (bonfire) => {
		if (bonfire) {
			setDialogOpen(true);
			setItem(bonfire);
		}
	};

	const fillTable = () => {
		let bonfiresCards = [];

		props.person.bonfires.forEach((bonfire, index) => {
			bonfiresCards.push(
				<>
					<div
						className="member-activities-table-wrapper-item"
						onClick={() => onRowClick(bonfire)}
					>
						<div className="col-md-12 member-activities-table-wrapper-item-first-line">
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								<WhatshotIcon className="member-activities-table-wrapper-item-cell-icon" />
								{
									props.bonfires.find((item) => item._id == bonfire.bonfire)
										.title
								}
							</div>
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								{/* <EmojiFlagsIcon className="member-activities-table-wrapper-item-cell-icon" /> */}
								{bonfire.status ? (
									<>
										<div className="member-activities-table-wrapper-item-cell-active">
											<CheckIcon className="member-activities-table-wrapper-item-cell-icon" />
										</div>
										Активний
									</>
								) : (
									<>
										<LoopIcon className="member-activities-table-wrapper-item-cell-icon" />
										Змінений
									</>
								)}
							</div>
						</div>
						<div className="col-md-12 member-activities-table-wrapper-item-second-line">
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
								{bonfire.granted}
							</div>
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								{props.person.bonfires[index + 1] ? (
									<>
										<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
										{props.person.bonfires[index + 1].granted}
									</>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</>
			);
		});

		setBonfiresCards(bonfiresCards);
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const handleInputChange = (event) => {
		setItem({
			...item,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let bonfire = item;

		let request = {
			purpose: endpointPurposes.updateItem,
			body: bonfire,
			_id: props.person._id,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Факел успішно оновлено");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const HandleFormDelete = (event) => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: { _id: props.person._id, bonfire: item.bonfire },
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Факел успішно видалено");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	return (
		<>
			<div className="row member-activities-table-wrapper">{bonfiresCards}</div>
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								Редагувати факел
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<div class="modal-body">
							<form onSubmit={handleFormSave}>
								<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
									<InputLabel id="bonfire-label">Факел</InputLabel>
									<Select
										labelId="bonfire-label"
										id="bonfire"
										name="bonfire"
										disabled
										value={item.bonfire}
										onChange={handleInputChange}
									>
										{props.bonfires.map(function (item, i) {
											return <MenuItem value={item._id}>{item.title}</MenuItem>;
										})}
									</Select>
								</FormControl>
								<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
									<TextField
										id="granted"
										name="granted"
										label="Надано"
										type="date"
										onChange={handleInputChange}
										// defaultValue="2017-05-24"
										value={item.granted}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</FormControl>

								<div class="modal-footer">
									{item.bonfire == props.person.current_bonfire ? (
										<button
											type="button"
											class="btn btn-warning dialog-modal-footer-button-left"
											onClick={HandleFormDelete}
										>
											Видалити
										</button>
									) : (
										""
									)}
									<button
										type="button"
										class="btn btn-default btn-flat"
										data-dismiss="modal"
										onClick={dialogCloseHandler}
									>
										Скасувати
									</button>

									<button
										type="submit"
										class="btn btn-primary"
										// onClick={handleFormSave}
										// onClick={validateForm}
									>
										OK
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Dialog>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity={snackBarSeverity}
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default PeopleBonfiresInfo;
