import React, { useState, useEffect, useCallback } from "react";

import MUIDataTable from "mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/lab/Autocomplete";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { Loading } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Moment from "moment";

import * as ApiRequest from "../../api/apiRequest";
import { tableSettings } from "../../configs/visualizationConfig";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";

const Books = (props) => {
	let fileInput = null;

	const cookies = new Cookies();

	const host = "Books";
	const loanHost = "Loan";
	const endpointPurposes = {
		getData: "get",
		deleteItem: "delete",
		uploadFile: "uploadFile",
		addLoan: "addLoan",
		returnLoan: "return",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [bookSettings, setBooksSettings] = useState({
		languages: [],
		categories: [],
		permissions: [],
		values: [],
		origins: [],
	});
	const [loans, setLoans] = useState([]);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [restrictionWarning, setRestrictionError] = useState(false);
	const [restrictionWarningText, setRestrictionErrorText] = useState("");

	const [loan, setLoan] = useState({
		person: "",
		librarian: "",
		loanData: "",
		dueDate: "",
		book: "",
	});

	const [people, setPeople] = useState();

	const [loanDialogOpen, setLoanDialogOpen] = useState(false);

	const [personError, setPersonError] = useState(false);
	const [dueDateError, setDueDateError] = useState(false);

	const [closeLoan, setCloseLoan] = useState({});

	const [closeLoanDialogOpen, setCloseLoanDialogOpen] = useState(false);

	const [pageNumber, setPageNumber] = useState(0);
	const [booksPerPage, setBooksPerPage] = useState(10);
	const [booksCount, setBooksCount] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");

	const [serachTypingTymeout, setSerachTypingTymeout] = useState(null);

	// let serachTypingTymeout;

	// useEffect(() => {
	// 	console.log("changed", restrictionWarningText);
	// }, [restrictionWarningText]);

	const getItemsFromApi = (pageChangeNumber, rowsPerPage, searchQuery) => {
		let request = {
			purpose: endpointPurposes.getData,
			pageNumber: pageChangeNumber ? pageChangeNumber : 0,
			booksPerPage: rowsPerPage ? rowsPerPage : booksPerPage,
			searchQuery: searchQuery,
		};

		props.Loading(true);

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			console.log("response: ", result);
			if (result.success) {
				let dataArray = [];

				result.items.forEach((element) => {
					let tempArray = [];
					let language = "";
					let category = "";
					let bookPermission = "";
					let bookValue = "";
					let library = "";
					let origin = "";

					result.languages.forEach((lang) => {
						if (lang.id == element.language) {
							language = lang.title;
						}
					});

					result.categories.forEach((cat) => {
						if (cat.id == element.category) {
							category = cat.title;
						}
					});

					result.booksPermissions.forEach((permission) => {
						if (permission.id == element.permission) {
							bookPermission = permission.title;
						}
					});

					result.values.forEach((val) => {
						if (val.id == element.value) {
							bookValue = val.title;
						}
					});

					result.libraries.forEach((lib) => {
						if (lib._id == element.library) {
							library = lib.title;
						}
					});

					tempArray.push(element.title);
					tempArray.push(element.bookIdentifier);
					// tempArray.push(element.origin);
					tempArray.push({
						isLoaned: element.isLoaned,
						isloanedTo:
							element.isLoaned && element.loanedTo
								? result.people.find((p) => p._id == element.loanedTo).name +
								  " " +
								  result.people.find((p) => p._id == element.loanedTo).surname
								: "",
						dueDate:
							element.isLoaned && element.activeLoanId
								? result.loans.find((l) => l._id == element.activeLoanId)
										.dueDate
								: false,
						activeLoanId: element.activeLoanId,
					});
					// tempArray.push(element.isLoaned ? true : false);
					// tempArray.push(element.library);
					tempArray.push(library);
					tempArray.push(bookPermission);
					tempArray.push(category);
					tempArray.push(language);
					tempArray.push(element.author);
					tempArray.push(element.publisher);
					tempArray.push(element.publicationCity);
					tempArray.push(element.publicationYear);
					tempArray.push(element.translator);
					tempArray.push(element.booksCollection);
					tempArray.push(element.keywords);
					tempArray.push(element.excertp);
					tempArray.push(element.pages);
					tempArray.push(bookValue);
					tempArray.push(element.id);

					dataArray.push(tempArray);
				});

				setItems(result.items);
				setTableData(dataArray);
				setBooksSettings({
					permissions: result.booksPermissions,
					languages: result.languages,
					categories: result.categories,
					values: result.values,
					origins: result.origins,
					libraries: result.libraries,
				});
				setPeople(result.people);
				setLoans(result.loans);
				setBooksCount(result.booksCount);
				setPageNumber(pageChangeNumber ? pageChangeNumber : 0);
				props.Loading(false);
			}
		});
	};

	useEffect(() => {
		props.Loading(true);
		getItemsFromApi();
	}, []);

	const columns = [
		{
			name: "Книга",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "ID",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		// {
		// 	name: "Стан",
		// 	options: {
		// 		display: false,
		// 		filter: true,
		// 		sort: true,
		// 	},
		// },
		{
			name: "Доступність",
			options: {
				customBodyRender: (value) => (
					<>
						{value.isLoaned ? (
							<Tooltip title={`${value.isloanedTo}, ${value.dueDate}`}>
								<span
									className={
										Moment().isSameOrBefore(value.dueDate) ? "" : "red"
									}
								>
									{value.isLoaned ? "Видана" : "Доступна"}
								</span>
							</Tooltip>
						) : (
							<span>{value.isLoaned ? "Видана" : "Доступна"}</span>
						)}
					</>
				),
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Бібліотека",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Обмеження",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Категорія",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Мова",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Автор",
			options: {
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Видавництво",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Місто видання",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Рік видання",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Перекладач",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Коллекція",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Ключові слова",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Преамбула",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Сторінок",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Цінність",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						{/* <Tooltip title="Редагувати"> */}
						{/* <IconButton
								aria-label="Редагувати"
								onClick={() => editItemHandler(value)}
							>
								<EditIcon />
							</IconButton> */}
						{/* </Tooltip> */}
						<div className="table-row-action-buttons-wrapper">
							<Tooltip title="Видалити">
								<IconButton
									aria-label="Видалити"
									onClick={() => deleteItemHandler(value)}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							{items.find((item) => item.id == value) ? (
								items.find((item) => item.id == value).isLoaned ? (
									<Tooltip title="Повернути книгу">
										<IconButton
											aria-label="Повернути книгу"
											onClick={() =>
												closeLoanDialogOpenHandler(
													items.find((item) => item.id == value).activeLoanId
												)
											}
										>
											<AlarmOffIcon />
										</IconButton>
									</Tooltip>
								) : (
									<Tooltip title="Видати книгу">
										<IconButton
											aria-label="Видати книгу"
											onClick={() => loanDialogOpenHandler(value)}
											// disabled={
											// 	!items.filter((item) => item.id === value).isLoaned
											// }
										>
											<AlarmAddIcon />
										</IconButton>
									</Tooltip>
								)
							) : (
								<></>
							)}
							<Tooltip title="Перегляд/Редагувати">
								<NavLink to={"/books/" + value}>
									<IconButton
										aria-label="Перегляд/Редагувати"
										// onClick={() => deleteItemHandler(value)}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</NavLink>
							</Tooltip>
						</div>
					</>
				),
				filter: false,
				sort: false,
				display: true,
			},
		},
	];

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Було видалено " + itemsWaitingForDelete.length + " книг!"
				);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обрану книгу?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index].id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " книг"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let successFlag = true;

			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				let categoryId = "";
				let isLoaned = false;

				if (data[index]["Status"] == "Available") {
					isLoaned = false;
				} else {
					isLoaned = true;
				}

				if (data[index]["Title"] == undefined) {
					successFlag = false;
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(
						"Не вірний формат, перевірте файл, рядок: " + index
					);
					break;
				}

				let category = "";
				let language = "";
				let permission = 0;
				let value = "";
				let origin = 0;
				let library = "";

				bookSettings.permissions.forEach((perm) => {
					if (perm.title == data[index]["Permission"]) {
						permission = perm.id;
					}
				});

				bookSettings.languages.forEach((lang) => {
					if (lang.title == data[index]["Language"]) {
						language = lang.id;
					}
				});

				bookSettings.categories.forEach((cat) => {
					if (cat.title == data[index]["Category"]) {
						category = cat.id;
					}
				});

				bookSettings.origins.forEach((orig) => {
					if (orig.title == data[index]["Origin"]) {
						origin = orig.id;
					}
				});

				bookSettings.libraries.forEach((lib) => {
					if (lib.title == data[index]["Library"]) {
						library = lib._id;
					}
				});

				// bookSettings.values.forEach((val) => {
				// 	if (val.title == data[index]["Цінність"]) {
				// 		value = val.id;
				// 	}
				// });

				itemsUpload.push({
					title: data[index]["Title"],
					bookIdentifier: data[index]["Book ID"],
					isLoaned: isLoaned,
					library: library,
					permission: permission,
					category: category,
					language: language,
					author: data[index]["Author Name"],
					// value: value,
					publisher: data[index]["Publisher"],
					publicationCity: data[index]["Publication City"],
					publicationYear: data[index]["Publication Year"],
					translator: data[index]["Translator"],
					booksCollection: data[index]["Collection"],
					keywords: data[index]["Keywords"],
					excertp: data[index]["Excerpt"],
					pages: data[index]["Book Pages"],
					origin: origin,
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			console.log("books upload: ", itemsUpload);

			if (successFlag) {
				ApiRequest.apiRequest(host, request).then((result) => {
					if (result.success) {
						getItemsFromApi();
						setSnackBarOpen(true);
						setSnackBarSeverity("success");
						setSnackBarMessage("Успішно завантажено " + counter + " книг!");
					} else {
						setSnackBarOpen(true);
						setSnackBarSeverity("error");
						setSnackBarMessage("Сталася помилка");
					}
				});
			}
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "Books-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			let isLoaned = element.isLoaned ? "Видана" : "Доступна";
			let category = "";
			let language = "";
			let permission = "";
			let value = "";

			bookSettings.permissions.forEach((perm) => {
				if (perm.id == element.permission) {
					permission = perm.title;
				}
			});

			bookSettings.languages.forEach((lang) => {
				if (lang.id == element.language) {
					language = lang.title;
				}
			});

			bookSettings.categories.forEach((cat) => {
				if (cat.id == element.category) {
					category = cat.title;
				}
			});

			bookSettings.values.forEach((val) => {
				if (val.id == element.value) {
					value = val.title;
				}
			});

			dataArray.push({
				Книга: element.title,
				ID: element.bookIdentifier,
				Доступність: isLoaned,
				Бібліотека: element.library,
				Обмеження: permission,
				Категорія: category,
				Мова: language,
				Автор: element.author,
				Видавництво: element.publisher,
				"Місто видання": element.publicationCity,
				"Рік видання": element.publicationYear,
				Перекладач: element.translator,
				Цінність: value,
				Коллекція: element.booksCollection,
				"Ключові слова": element.keywords,
				Цитата: element.excertp,
				Сторінок: element.pages,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	const debounce = (fn) => {
		let timerId;
		return (...args) => {
			clearTimeout(timerId);
			timerId = setTimeout(() => fn(...args), 400);
		};
	};

	const searchHandler = (query) => {
		setSearchQuery(query ? query.toLowerCase() : "");
		getItemsFromApi(0, booksPerPage, query ? query.toLowerCase() : "");
	};

	const debouncedHandler = useCallback(debounce(searchHandler, 400), [
		searchHandler,
	]);

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.expandableRows = true;
	tableSettingsLocal.count = booksCount;
	tableSettingsLocal.page = pageNumber;
	tableSettingsLocal.serverSide = true;
	tableSettingsLocal.sort = false;
	tableSettingsLocal.filter = false;
	tableSettingsLocal.onChangePage = (currentPage) => {
		setPageNumber(currentPage);
		getItemsFromApi(currentPage, booksPerPage, searchQuery);
	};
	tableSettingsLocal.rowsPerPage = booksPerPage;
	tableSettingsLocal.onChangeRowsPerPage = (numberOfRows) => {
		setBooksPerPage(numberOfRows);
		getItemsFromApi(pageNumber, numberOfRows, searchQuery);
	};
	tableSettingsLocal.onSearchChange = (searchQueryText) => {
		debouncedHandler(searchQueryText);
	};
	// tableSettingsLocal.expandableRowsOnClick = true;
	tableSettingsLocal.isRowExpandable = (dataIndex, expandedRows) => {
		// if (dataIndex === 3 || dataIndex === 4) return false;

		// Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
		if (
			expandedRows.data.length > 4 &&
			expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
		)
			return false;
		return true;
	};
	// tableSettingsLocal.rowsExpanded = [0, 1];
	tableSettingsLocal.renderExpandableRow = (rowData, rowMeta) => {
		const colSpan = rowData.length + 1;

		console.log("expandable row: ", rowData, rowMeta);

		const booksAdditionalData = {
			title: rowData[0],
			bookIdentifier: rowData[1],
			// origin: rowData[2],
			isLoaned: rowData[2].props.children.props.children.props.children
				? rowData[2].props.children.props.children.props.children
				: "Не доступна",
			library: rowData[3],
			permission: rowData[4],
			category: rowData[5] ? rowData[5] : "",
			language: rowData[6] ? rowData[6] : "",
			author: rowData[7] ? rowData[7] : "",
			publisher: rowData[8] ? rowData[8] : "",
			publicationCity: rowData[9] ? rowData[9] : "",
			publicationYear: rowData[10] ? rowData[10] : "",
			translator: rowData[11] ? rowData[11] : "",
			booksCollection: rowData[12] ? rowData[12] : "",
			keywords: rowData[13] ? rowData[13] : "",
			excertp: rowData[14] ? rowData[14] : "",
			pages: rowData[15] ? rowData[15] : "",
			value: rowData[16] ? rowData[16] : "",
		};

		const items = (
			<>
				<span className="table-expandable-row-item">
					{"Назва: " + booksAdditionalData.title}
				</span>
				<span className="table-expandable-row-item">
					{"ID: " + booksAdditionalData.bookIdentifier}
				</span>
				{/* <span className="table-expandable-row-item">
					{"Стан: " + booksAdditionalData.origin}
				</span> */}
				<span className="table-expandable-row-item">
					{"Доступність: " + booksAdditionalData.isLoaned}
				</span>
				<span className="table-expandable-row-item">
					{"Бібліотека: " + booksAdditionalData.library}
				</span>
				<span className="table-expandable-row-item">
					{"Обмеження: " + booksAdditionalData.permission}
				</span>
				<span className="table-expandable-row-item">
					{"Категорія: " + booksAdditionalData.category}
				</span>
				<span className="table-expandable-row-item">
					{"Мова: " + booksAdditionalData.language}
				</span>
				<span className="table-expandable-row-item">
					{"Автор: " + booksAdditionalData.author}
				</span>
				<span className="table-expandable-row-item">
					{"Видавництво: " + booksAdditionalData.publisher}
				</span>
				<span className="table-expandable-row-item">
					{"Місто видання: " + booksAdditionalData.publicationCity}
				</span>
				<span className="table-expandable-row-item">
					{"Рік видання: " + booksAdditionalData.publicationYear}
				</span>
				<span className="table-expandable-row-item">
					{"Перекладач: " + booksAdditionalData.translator}
				</span>
				<span className="table-expandable-row-item">
					{"Цінність: " + booksAdditionalData.value}
				</span>
				<span className="table-expandable-row-item">
					{"Коллекція: " + booksAdditionalData.booksCollection}
				</span>
				<span className="table-expandable-row-item">
					{"Ключові слова: " + booksAdditionalData.keywords}
				</span>
				<span className="table-expandable-row-item">
					{"Цитата: " + booksAdditionalData.excertp}
				</span>
				<span className="table-expandable-row-item">
					{"Сторінок: " + booksAdditionalData.pages}
				</span>
			</>
		);

		return (
			<TableRow>
				<TableCell colSpan={colSpan}>
					<div className="table-expandable-row-wrapper">{items}</div>
				</TableCell>
			</TableRow>
		);
	};

	// tableSettingsLocal.onRowExpansionChange = (
	// 	curExpanded,
	// 	allExpanded,
	// 	rowsExpanded
	// ) => console.log(curExpanded, allExpanded, rowsExpanded);

	// setOptions(tableSettingsLocal);
	const options = tableSettingsLocal;

	const loanDialogOpenHandler = (bookId) => {
		setLoan({
			...loan,
			book: bookId,
		});
		setLoanDialogOpen(true);

		setPersonError(false);
		setDueDateError(false);
	};

	const loanDialodCloseHandler = () => {
		setLoanDialogOpen(false);
	};

	const validateForm = (data) => {
		let valid = true;

		let dueDate = data.get("dueDate");

		if (!loan.person) {
			valid = false;
			setPersonError(true);
		} else {
			setPersonError(false);
		}

		if (!dueDate) {
			valid = false;
			setDueDateError(true);
		} else {
			setDueDateError(false);
		}

		return valid;
	};

	const loanSaveHandler = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const data = new FormData(event.target);

		let isValid = validateForm(data);

		let librarian = cookies.get("person", { path: "/" });

		if (isValid) {
			// const data = new FormData(event.target);
			var item = {
				person: loan.person._id,
				librarian: librarian,
				loanData: Moment().format("YYYY-MM-DD"),
				dueDate: data.get("dueDate"),
				book: loan.book,
			};

			let request = {
				purpose: endpointPurposes.addLoan,
				body: item,
			};

			//// !!! Change the structure !!!
			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage("Видачу книги збережено");
					setLoanDialogOpen(false);
				} else {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage("Сталася помилка");
					setLoanDialogOpen(false);
				}
			});
		}
	};

	const loanBlurHandler = (event) => {
		setLoan({
			...loan,
			[event.target.name]: event.target.value,
		});
	};

	const checkPersonRestriction = (event, value) => {
		if (value) {
			let reader = value;
			if (reader) {
				setLoan({
					...loan,
					person: reader,
				});
			}
			let book = items.find((b) => b.id == loan.book);

			let permissionError = false;
			let permissionErrorText = "";

			switch (book.permission) {
				case 2: {
					if (!reader.isMember) {
						permissionError = true;
						permissionErrorText = "Читач не є членом НА";
					}
					break;
				}
				case 3: {
					if (!reader.isLiveForce) {
						permissionError = true;
						permissionErrorText = "Читач не є членом ЖС";
					}
					break;
				}
				case 4: {
					if (!reader.isInstructor) {
						permissionError = true;
						permissionErrorText = "Читач не є інструктором";
					}
					break;
				}
				default: {
					permissionError = false;
					permissionErrorText = "";
					break;
				}
			}

			// if (permissionError) {
			setRestrictionError(permissionError);
			setRestrictionErrorText(permissionErrorText);
			// }else{
			// 	set
			// }
		} else {
			setLoan({
				...loan,
				person: null,
			});
		}
	};

	const closeLoanDialogOpenHandler = (loanId) => {
		let loanToOpen = loans.find((l) => l._id == loanId);

		loanToOpen.personName = people.find((p) => p._id == loanToOpen.person).name;
		loanToOpen.personSurname = people.find(
			(p) => p._id == loanToOpen.person
		).surname;

		setCloseLoan(loanToOpen);
		setCloseLoanDialogOpen(true);
	};

	const closeLoanDialodCloseHandler = () => {
		setCloseLoanDialogOpen(false);
	};

	const closeLoanSaveHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const data = new FormData(e.target);

		let librarian = cookies.get("person", { path: "/" });

		// const data = new FormData(event.target);
		var item = {
			librarian: librarian,
			loanReturnDate: Moment().format("YYYY-MM-DD"),
			loan: closeLoan._id,
		};

		let request = {
			purpose: endpointPurposes.returnLoan,
			body: item,
		};

		// !!! Change the structure !!!
		ApiRequest.apiRequest(loanHost, request).then((result) => {
			if (result.success) {
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Повернення книги збережено");
				setCloseLoanDialogOpen(false);
				getItemsFromApi();
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
				setCloseLoanDialogOpen(false);
			}
		});
	};

	return (
		<>
			{/* <Header /> */}
			{/* <MainNavigation /> */}
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Книги</h1>
										{/* <ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li class="active">Книги</li>
										</ol> */}
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="row">
							<div class="col-xs-12">
								<div class="content">
									<header class="content-heading">
										<div className="card-actions-left-wrapper">
											<NavLink to="/books/create">
												<button
													class="btn btn-green"
													// onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Додати книгу
												</button>
											</NavLink>
										</div>
										<ul class="card-actions icons right-top">
											<li className="page-controlls-right-item">
												<button
													class="btn btn-default btn-fab"
													onClick={fileClickHandler}
												>
													<i class="zmdi zmdi-upload"></i>
													<input
														type="file"
														style={{ display: "none" }}
														ref={(input) => {
															fileInput = input;
														}}
														accept=".csv, .xls, .xlsx"
														onChange={fileUploadHandler}
													/>
													{/* /> */}
												</button>
											</li>
											<li className="page-controlls-right-item">
												<button
													class="btn btn-default btn-fab"
													onClick={prepareDataToExport}
												>
													<i class="zmdi zmdi-download"></i>
												</button>
											</li>
										</ul>
									</header>
									<div class="content-body m-t-20">
										<MUIDataTable
											title={"Книги"}
											data={tableData}
											columns={columns}
											options={options}
										/>
									</div>
								</div>
							</div>

							<Dialog
								open={alertOpen}
								onClose={alertCloseHandler}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								className="flamingo-modal"
							>
								<div className="flamingo-alert-body-wrapper">
									<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
									{alertWarningMessage}
								</div>
								<div className="flamingo-alert-buttons-wrapper">
									<button
										type="button"
										class="btn btn-default btn-flat"
										data-dismiss="modal"
										onClick={alertCloseHandler}
									>
										Скасувати
									</button>
									{alertActionButton}
								</div>
							</Dialog>
							{people && loan && items ? (
								<>
									<Dialog
										open={loanDialogOpen}
										onClose={loanDialodCloseHandler}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										className="flamingo-modal"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<DialogTitle className="modal-header">
													<h4 class="modal-title" id="myModalLabel-2">
														Видати книгу
													</h4>
													<ul class="card-actions icons right-top">
														<a
															href="javascript:void(0)"
															data-dismiss="modal"
															class="text-white"
															aria-label="Close"
															onClick={loanDialodCloseHandler}
														>
															<i class="zmdi zmdi-close"></i>
														</a>
													</ul>
												</DialogTitle>
												<form onSubmit={loanSaveHandler}>
													<div class="modal-body">
														<h3>
															{loan.book
																? items.find((book) => book.id == loan.book)
																		.title
																: ""}
														</h3>

														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<Autocomplete
																id="person"
																// className="admin-select-person-autocomplete-field"
																options={people}
																// disabled={updateMode ? true : false}
																getOptionLabel={(option) =>
																	`${option.name} ${option.surname}`
																}
																// value={newItem.user}
																// style={{ width: 300 }}
																onChange={(e, v) =>
																	checkPersonRestriction(e, v)
																}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Кому видається"
																		// variant="outlined"
																	/>
																)}
															/>
															{personError && (
																<FormHelperText className="red">
																	Поле обов'язкове
																</FormHelperText>
															)}
															{restrictionWarning && (
																<FormHelperText className="yellow">
																	{`Попередження: ${restrictionWarningText}`}
																</FormHelperText>
															)}
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="dueDate"
																name="dueDate"
																label="Термін повернення"
																type="date"
																required
																defaultValue={Moment()
																	.add(7, "days")
																	.format("YYYY-MM-DD")}
																// onChange={props.handleInputChange}
																// onBlur={loanBlurHandler}
																InputLabelProps={{
																	shrink: true,
																}}
																helperText={
																	dueDateError ? "Поле обов'язкове" : ""
																}
																error={dueDateError}
															/>
														</FormControl>
													</div>
													<div class="modal-footer">
														<button
															type="button"
															class="btn btn-default btn-flat"
															data-dismiss="modal"
															onClick={loanDialodCloseHandler}
														>
															Скасувати
														</button>

														<button
															type="submit"
															class="btn btn-primary"
															// onClick={handleFormSave}
															// onClick={validateForm}
														>
															Видати
														</button>
													</div>
												</form>
											</div>
										</div>
									</Dialog>
									{/* {closeLoan.person ? ( */}
									<Dialog
										open={closeLoanDialogOpen}
										onClose={closeLoanDialodCloseHandler}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
										className="flamingo-modal"
									>
										<div class="modal-dialog">
											<div class="modal-content">
												<DialogTitle className="modal-header">
													<h4 class="modal-title" id="myModalLabel-2">
														{`Повернути книгу: ${
															closeLoan.book
																? items.find(
																		(book) => book.id == closeLoan.book
																  ).title
																: ""
														}`}
													</h4>
													<ul class="card-actions icons right-top">
														<a
															href="javascript:void(0)"
															data-dismiss="modal"
															class="text-white"
															aria-label="Close"
															onClick={closeLoanDialodCloseHandler}
														>
															<i class="zmdi zmdi-close"></i>
														</a>
													</ul>
												</DialogTitle>
												<form onSubmit={closeLoanSaveHandler}>
													<div class="modal-body">
														<h3></h3>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="dueDate"
																name="dueDate"
																label="Читач"
																required
																disabled
																defaultValue={`${closeLoan.personName} ${closeLoan.personSurname}`}
																// onChange={props.handleInputChange}
																// onBlur={loanBlurHandler}
															/>
														</FormControl>
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																id="dueDate"
																name="dueDate"
																label="Дата повернення"
																type="date"
																required
																defaultValue={Moment().format("YYYY-MM-DD")}
																// onChange={props.handleInputChange}
																// onBlur={loanBlurHandler}
																InputLabelProps={{
																	shrink: true,
																}}
															/>
														</FormControl>
													</div>
													<div class="modal-footer">
														<button
															type="button"
															class="btn btn-default btn-flat"
															data-dismiss="modal"
															onClick={closeLoanDialodCloseHandler}
														>
															Скасувати
														</button>

														<button
															type="submit"
															class="btn btn-primary"
															// onClick={handleFormSave}
															// onClick={validateForm}
														>
															Повернути
														</button>
													</div>
												</form>
											</div>
										</div>
									</Dialog>
									{/* ) : (
										""
									)} */}
								</>
							) : (
								""
							)}

							<Dialog
								open={alertOpen}
								onClose={alertCloseHandler}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
								className="flamingo-modal"
							>
								<div className="flamingo-alert-body-wrapper">
									<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
									{alertWarningMessage}
								</div>
								<div className="flamingo-alert-buttons-wrapper">
									<button
										type="button"
										class="btn btn-default btn-flat"
										data-dismiss="modal"
										onClick={alertCloseHandler}
									>
										Скасувати
									</button>
									{alertActionButton}
								</div>
							</Dialog>
							<Snackbar
								open={snackBarOpen}
								autoHideDuration={6000}
								onClose={handleSnackBarClose}
							>
								<MuiAlert
									onClose={handleSnackBarClose}
									severity={snackBarSeverity}
									elevation={6}
									variant="filled"
								>
									{snackBarMessage}
								</MuiAlert>
							</Snackbar>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default connect(
	(store) => ({
		isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Loading,
			},
			dispatch
		)
)(Books);
