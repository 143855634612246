import { combineReducers } from "redux";
// import isLoadingReducer from "./loader";
import sessionReducer from "./session";
import isLoadingReducer from "./loader";
import loginErrorReducer from "./loginError";

const reducers = combineReducers({
	session: sessionReducer,
	isLoading: isLoadingReducer,
	loginError: loginErrorReducer,
});

export default reducers;
