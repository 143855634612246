import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import RoomIcon from "@mui/icons-material/Room";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import ScheduleIcon from "@mui/icons-material/Schedule";

import * as ApiRequest from "../../api/apiRequest";
import { tableSettings } from "../../configs/visualizationConfig";
import Paper from "@mui/material/Paper";

const PeopleMemberActivitiesInfo = (props) => {
	const host = "People";
	const endpointPurposes = {
		updateItem: "updateMemberActivity",
		deleteItem: "deleteMemberActivity",
	};

	const [item, setItem] = useState({});

	const [memberActivitiesCards, setMemberActivitiesCards] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	useEffect(() => {
		fillTable();
	}, [props.person]);

	const onRowClick = (activity) => {
		if (activity) {
			setDialogOpen(true);
			setItem(activity);
		}
	};

	const fillTable = () => {
		let activitiesCards = [];

		props.person.member_activities.forEach((activity) => {
			activitiesCards.push(
				<>
					<div
						className="member-activities-table-wrapper-item"
						onClick={() => onRowClick(activity)}
					>
						<div className="col-md-12 member-activities-table-wrapper-item-first-line">
							<div className="col-md-4 member-activities-table-wrapper-item-cell">
								<BusinessCenterIcon className="member-activities-table-wrapper-item-cell-icon" />
								{
									props.hierarchy.find((item) => item._id == activity.hierarchy)
										.title
								}
							</div>
							<div className="col-md-4 member-activities-table-wrapper-item-cell">
								<EmojiFlagsIcon className="member-activities-table-wrapper-item-cell-icon" />
								{
									props.secretary.find((item) => item._id == activity.secretary)
										.title
								}
							</div>
							<div className="col-md-4 member-activities-table-wrapper-item-cell">
								<RoomIcon className="member-activities-table-wrapper-item-cell-icon" />
								{
									props.branches.find((item) => item._id == activity.branch)
										.title
								}
							</div>
						</div>
						<div className="col-md-12 member-activities-table-wrapper-item-second-line">
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
								{"З: " + activity.start_date}
							</div>
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								{activity.end_date == "" ? (
									<span className="member-activities-table-wrapper-item-cell-active">
										Активна діяльність
									</span>
								) : (
									<>
										<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
										{"По: " + activity.end_date}
									</>
								)}
							</div>
						</div>
					</div>
				</>
			);
		});

		setMemberActivitiesCards(activitiesCards);
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const handleInputChange = (event) => {
		setItem({
			...item,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let activity = item;

		let request = {
			purpose: endpointPurposes.updateItem,
			body: activity,
			_id: props.person._id,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Активність успішно оновлена");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const HandleFormDelete = (event) => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: { _id: props.person._id, activity: item._id },
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Активність успішно видалено");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	return (
		<>
			<div className="row member-activities-table-wrapper">
				{memberActivitiesCards}
			</div>
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								Редагувати членську активність
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<div class="modal-body">
							{item.secretary ? (
								<form onSubmit={handleFormSave}>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<InputLabel id="settings-modal-input-branch-parrent-label">
											Осередок
										</InputLabel>
										<Select
											labelId="settings-modal-input-branch-parrent-label"
											id="settings-modal-input-branch-parrent"
											name="branch"
											value={item.branch}
											onChange={handleInputChange}
										>
											{props.branches.map(function (item, i) {
												return (
													<MenuItem value={item._id}>{item.title}</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<InputLabel id="settings-modal-input-branch-parrent-label">
											Ієрархія
										</InputLabel>
										<Select
											labelId="settings-modal-input-branch-parrent-label"
											id="settings-modal-input-branch-parrent"
											name="hierarchy"
											value={item.hierarchy}
											onChange={handleInputChange}
										>
											{props.hierarchy.map(function (item, i) {
												return (
													<MenuItem value={item._id}>{item.title}</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<InputLabel id="settings-modal-input-branch-parrent-label">
											Секретаріат
										</InputLabel>
										<Select
											labelId="settings-modal-input-branch-parrent-label"
											id="settings-modal-input-branch-parrent"
											name="secretary"
											value={item.secretary}
											onChange={handleInputChange}
										>
											{props.secretary.map(function (item, i) {
												return (
													<MenuItem value={item._id}>{item.title}</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<TextField
											id="start_date"
											name="start_date"
											label="Дата початку"
											type="date"
											onChange={handleInputChange}
											// defaultValue="2017-05-24"
											value={item.start_date}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<TextField
											id="end_date"
											name="end_date"
											label="Дата завершення"
											type="date"
											onChange={handleInputChange}
											// defaultValue="2017-05-24"
											value={item.end_date}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
									<div class="modal-footer">
										<button
											type="button"
											class="btn btn-warning dialog-modal-footer-button-left"
											onClick={HandleFormDelete}
										>
											Видалити
										</button>
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={dialogCloseHandler}
										>
											Скасувати
										</button>

										<button
											type="submit"
											class="btn btn-primary"
											// onClick={handleFormSave}
											// onClick={validateForm}
										>
											OK
										</button>
									</div>
								</form>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</Dialog>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity={snackBarSeverity}
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default PeopleMemberActivitiesInfo;
