import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";

import Paper from "@mui/material/Paper";

const ErrorScreen = (props) => {
	const history = useHistory();

	const goToMainHandler = () => {
		window.location.replace("/");
	};
	return (
		<>
			<section class="login-body-wrapper">
				<Paper elevation={3}>
					<div className="login-form-wrapper">
						<div className="login-logo error-logo"></div>
						<h2 className="login-wellcome-text error-header">Щось не те</h2>
						<div className="error-body">
							<p className="error-body-text">
								Те, що ви шукаєте не існує, або сталась помилка
							</p>
							<p className="error-body-text error-body-text-action">
								Будь-ласка перевірте запит ще раз, або зверніться до
								адміністратора
							</p>
							<button
								onClick={goToMainHandler}
								className="btn btn-primary error-body-button"
							>
								На головну
							</button>
						</div>
					</div>
				</Paper>
			</section>
		</>
	);
};

export default ErrorScreen;
