import axios from "axios";
import apiConfig from "./apiConfig";
import Cookies from "universal-cookie";
import { isExpired, decodeToken } from "react-jwt";

export const apiRequest = (purpose, payload) => {
	const cookies = new Cookies();

	// console.log({
	// 	apiKey: apiConfig.key,
	// 	request: payload,
	// });

	const token = cookies.get("flamingo");
	// let decoded = decodeToken(token);
	// let isTokenExpired = isExpired(token);
	// console.log("token: ", decoded, isTokenExpired);

	// if(isTokenExpired){

	// }

	console.log("api call test: ", apiConfig[purpose], "token: ", token);

	return axios
		.post(
			apiConfig[purpose],
			{
				apiKey: apiConfig.key,
				request: payload,
				token: token,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => {
			if (response.data.token) {
				cookies.set("flamingo", response.data.token, { path: "/" });
			}
			return response.data;
		})
		.catch((error) => {
			if (error.response.status == 401) {
				console.log("error response 401", error.response, apiConfig[purpose]);
			} else {
				return error;
			}
		});
	// ).then(res => {
	//         result = res.data;
	//     });

	// const data = await res.data;
	// return data;
};
