import React, { useEffect, useState } from "react";
import { Input } from "../shared";
import { parserInputForm } from "../../utils/formParsers";
import { actionLogin, Loading } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

const Login = (props) => {
	let history = useHistory();

	const [emailError, setEmailError] = useState(false);
	const [emailErrorText, setEmailErrorText] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [passwordErrorText, setPasswordErrorText] = useState("");

	useEffect(() => {
		if (props.loginError.payload) {
			if (props.loginError.payload.message == "no user") {
				setEmailError(true);
				setEmailErrorText("Такої адреси не знайдено");
			} else {
				setEmailError(false);
				setEmailErrorText("");
			}

			if (props.loginError.payload.message == "wrong password") {
				setPasswordError(true);
				setPasswordErrorText("Не вірний пароль, перевірте будь-ласка");
			} else {
				setPasswordError(false);
				setPasswordErrorText("");
			}
		}
	}, [props.loginError]);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		props.Loading(true);

		props.actionLogin(
			"Login",
			parserInputForm(event),
			props.location.state ? props.location.state.from.pathname : "/"
		);
	};

	// if (props.session) {
	// 	// history.push("/");
	// }

	return (
		<>
			<section class="login-body-wrapper">
				<Paper elevation={3}>
					<div className="login-form-wrapper">
						<div className="login-logo"></div>
						<h2 className="login-wellcome-text">
							Увійдіть будь-ласка в систему
						</h2>

						<form onSubmit={handleFormSubmit}>
							<FormControl className="login-input-wrapper">
								<TextField
									required
									autoFocus
									type="email"
									id="login-email-input"
									name="email"
									// value={newItem.title}
									// onChange={handleInputChange}
									label="Ємейл"
									error={emailError}
									helperText={emailErrorText}
								/>
							</FormControl>
							<FormControl className="login-input-wrapper">
								<TextField
									required
									// autoFocus
									type="password"
									id="login-password-input"
									name="password"
									// value={newItem.title}
									// onChange={handleInputChange}
									label="Пароль"
									error={passwordError}
									helperText={passwordErrorText}
								/>
							</FormControl>
							<div className="forgot-password-wrapper">
								<NavLink to="/forgot">Забули пароль?</NavLink>
							</div>
							<div className="login-buttons-wrapper">
								<button
									// type="submit"
									class="btn btn-primary login-buttons-login"
									// onClick={handleUpdateSave}
									// onClick={validateForm}
								>
									Логін
								</button>
							</div>
						</form>
					</div>
				</Paper>
			</section>
		</>
	);
};

// export default Login;

export default connect(
	(store) => ({
		session: store.session,
		isLoad: store.isLoad,
		loginError: store.loginError,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				actionLogin,
				Loading,
			},
			dispatch
		)
)(Login);
