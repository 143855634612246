import React, { useState, useEffect } from "react";

import InternalEventsSettings from "../../settings/Events/InternalEventsSettings";
import ExternalEventsSettings from "../../settings/Events/ExternalEventsSettings";
import PromoEventSettings from "../../settings/Events/PromoEventSettings";

const EventsSettings = (props) => {
	const [] = useState();

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Події - Налаштування</h1>
										<ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Заходи</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
					<div class="tabpanel tab-header">
						<ul class="nav nav-tabs p-l-30 flamingo-tab-panel ">
							<li class="active" role="presentation">
								<a href="#internal" data-toggle="tab">
									Внутрішні
								</a>
							</li>
							<li role="presentation">
								<a href="#external" data-toggle="tab">
									Зовнішні
								</a>
							</li>
							<li role="presentation">
								<a href="#propagandamedia" data-toggle="tab">
									Промо медіа
								</a>
							</li>
						</ul>
					</div>
					<div id="content" class="container-fluid m-t-0">
						<div class="content-body">
							<div id="card_content" class="tab-content">
								<div class="tab-pane fade active in" id="internal">
									<div class="row">
										<InternalEventsSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="external">
									<div class="row">
										<ExternalEventsSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="propagandamedia">
									<div class="row">
										<PromoEventSettings />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default EventsSettings;
