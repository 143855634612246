import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PeopleAxesInfo from "./PeopleAxesInfo";

const PeopleAxeBearerInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="axe_bearer_start_date"
								name="axe_bearer_start_date"
								label="Дата присвоєння"
								type="date"
								defaultValue={props.person.axe_bearer_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
				{props.person.axe_bearer_axes.length > 0 ? (
					<>
						<div className="row acordion-row col-md-12">
							<h3 className="people-acordeon-inside-header">Історія Сікір</h3>
							<PeopleAxesInfo
								person={props.person}
								bonfires={props.bonfires}
								getPersonData={props.getPersonData}
								axes={props.axes}
							/>
						</div>
						<div className="row acordion-row col-md-12">
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									id="axe_bearer_extra_year"
									name="axe_bearer_extra_year"
									label="Додатковий рік"
									defaultValue={props.person.axe_bearer_extra_year}
									// onChange={props.handleInputChange}
									onBlur={props.handleItemUpdate}
								/>
							</FormControl>
						</div>
					</>
				) : (
					""
				)}
			</form>
		</>
	);
};

export default PeopleAxeBearerInfo;
