import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const Input = (props) => {
	return (
		<>
			<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
				<TextField {...props} />
			</FormControl>
		</>
	);
};

export default Input;
