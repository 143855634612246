import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";

import Nav from "../main/Nav";

const Home = (props) => {
	const [] = useState();

	const { location, history } = props;

	useEffect(() => {
		if (location.pathname !== "/") {
			history.push("/");
		}
	}, []);

	// window.location.replace("/");

	return (
		<>
			<Nav />
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-md-12">
									<header id="header">
										<h1>Домашня сторінка</h1>
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="categories">
							<div class="row">
								<div class="col-xs-12">
									<div class="content">
										<div class="content-body m-t-20"></div>
										<div className="row">
											<div className="col-xs-12">
												<div className="card">
													{/* <div className="card-header">

														</div> */}
													<div className="card-body home-page-body-wrapper">
														<h2 className="home-page-header">
															Вітаємо у Прометеї
														</h2>
														<div className="row">
															<div className="col-md-4 home-page-button-wrapper">
																<NavLink to="/people">
																	<button className="btn btn-primary home-page-button">
																		Люди
																	</button>
																</NavLink>
															</div>
															<div className="col-md-4 home-page-button-wrapper">
																<NavLink to="/events/external">
																	<button className="btn btn-primary home-page-button">
																		Події
																	</button>
																</NavLink>
															</div>
															<div className="col-md-4 home-page-button-wrapper">
																<NavLink to="/books">
																	<button className="btn btn-primary home-page-button">
																		Книги
																	</button>
																</NavLink>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default withRouter(Home);
