import { SHOW_LOADER, HIDE_LOADER } from "../actions/actionsType";

export default function isLoading(state = false, action) {
	switch (action.type) {
		case SHOW_LOADER: {
			return true;
		}

		case HIDE_LOADER: {
			return false;
		}

		default:
			return state;
	}
}
