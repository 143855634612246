import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@mui/x-date-pickers";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputMask from "react-input-mask";
import Moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../api/apiRequest";
import { tableSettings } from "../../configs/visualizationConfig";
import Permissions from "../support/getAdminData";

import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../actions";

const People = (props) => {
	let fileInput = null;

	const [permissions, setPermissions] = useState(Permissions());

	const host = "People";
	const endpointPurposes = {
		getData: "get",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	//// !!! Change the structure !!!
	const [newItem, setNewItem] = useState({
		name: "",
		surname: "",
		gender: true,
		email: "",
		phone: "",
		branch: "",
		initialContactDate: "",
		remarks: "",
	});

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState({
		id: "",
		name: "",
		surname: "",
		gender: "",
		email: "",
		phone: "",
		branch: "",
		initialContactDate: "",
		remarks: "",
	});

	const [branches, setBranches] = useState([]);
	const [statuses, setStatuses] = useState([]);

	// const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [nameError, setNameError] = useState(false);
	const [surnameError, setSurnameError] = useState(false);
	const [branchError, setBranchError] = useState(false);
	const [initialContactDateError, setInitialContactDateError] = useState(false);

	const [branchFilter, setBranchFilter] = useState();
	const [statusFilter, setStatusFilter] = useState();

	const getItemsFromApi = (branch, status) => {
		let request = {
			purpose: endpointPurposes.getData,
			branch: permissions.onlyAdminBranch
				? permissions.adminBranch
				: branch
				? branch
				: null,
			status: status ? status : statusFilter,
		};

		props.setLoading(true);

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			console.log("people result: ", result);
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let initialContactDate = Moment(element.initialContactDate).format(
						"D-MM-yyyy"
					);

					let branchName = "";

					result.branches.forEach((el) => {
						if (el._id == element.branch) {
							branchName = el.title;
						}
					});

					let tempArray = [];
					tempArray.push(element.name);
					tempArray.push(element.surname);
					tempArray.push(element.gender);
					tempArray.push(element.email);
					tempArray.push(element.phone);
					tempArray.push(branchName);
					tempArray.push(initialContactDate);
					tempArray.push(element.notes);
					// tempArray.push(element.id);
					tempArray.push({ slug: element.slug, id: element._id });

					dataArray.push(tempArray);
				});

				setItems(result.items);
				setTableData(dataArray);
				setBranches(result.branches);
				setStatuses(result.statuses);
				props.setLoading(false);
			} else {
				props.setLoading(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Помилка завантаження");
			}
		});
	};

	useEffect(() => {
		getItemsFromApi();
	}, []);

	//// !!! Change the structure !!!
	const columns = [
		"Ім'я",
		"Призвище",
		{
			name: "Стать",
			label: "Стать",
			options: {
				customBodyRender: (value) => (value ? "Чоловіча" : "Жіноча"),
				display: true,
				filter: true,
				sort: true,
			},
		},
		{
			name: "Email",
			label: "Email",
			options: {
				customBodyRender: (value) => (
					<div className="table-column-people-email">{value}</div>
				),
			},
		},
		{
			name: "Телефон",
			label: "Телефон",
			options: {
				customBodyRender: (value) => (
					<div className="table-column-people-phone">{value}</div>
				),
			},
		},
		"Осередок",
		{
			name: "Перший контакт",
			label: "Перший контакт",
			options: {
				display: false,
				filter: true,
				sort: true,
			},
		},
		"Комментарі",
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<div className="table-row-action-people-buttons-wrapper">
							<Tooltip title="Видалити">
								<IconButton
									aria-label="Видалити"
									onClick={() => deleteItemHandler(value.id)}
									disabled={permissions.canDeletePerson ? false : true}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Перегляд/Редагувати">
								<NavLink to={"/people/view/" + value.slug}>
									<IconButton
										aria-label="Перегляд/Редагувати"
										// onClick={() => deleteItemHandler(value)}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</NavLink>
							</Tooltip>
						</div>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		setNewItemStateSave({
			...newItem,
			[event.target.name]: event.target.value,
		});

		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const handleSwitcherChange = (event) => {
		setNewItemStateSave({
			...newItem,
			[event.target.name]: event.target.checked,
		});

		setNewItem({
			...newItem,
			[event.target.name]: event.target.checked,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
		// setUpdateMode(false);
	};

	const dialogCloseHandler = () => {
		// if (updateMode) {
		// setNewItem(newItemStateSave);
		// }
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem({
			title: "",
			reportName: "",
		});
		setDialogOpen(false);
		setNewItem({});

		setNameError(false);
		setSurnameError(false);
		setBranchError(false);
		setInitialContactDateError(false);

		// if (updateMode) {
		// 	setSaveStateItemInput(true);
		// } else {
		setSaveStateItemInput(false);
		// }
	};

	const validateForm = () => {
		let valid = true;

		if (!newItem.name) {
			valid = false;
			setNameError(true);
		} else {
			setNameError(false);
		}

		if (!newItem.surname) {
			valid = false;
			setSurnameError(true);
		} else {
			setSurnameError(false);
		}

		if (!newItem.branch) {
			valid = false;
			setBranchError(true);
		} else {
			setBranchError(false);
		}

		if (!newItem.initialContactDate) {
			valid = false;
			setInitialContactDateError(true);
		} else {
			setInitialContactDateError(false);
		}

		return valid;
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let isValid = validateForm();
		if (isValid) {
			let newItemObject = {
				name: newItem.name,
				surname: newItem.surname,
				gender: newItem.gender,
				email: newItem.email,
				phone: newItem.phone,
				branch: newItem.branch,
				initialContactDate: newItem.initialContactDate,
				remarks: newItem.remarks,
			};

			let request = {
				purpose: endpointPurposes.createItem,
				body: newItemObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					dialogCancelHandler();
					// setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage(
						newItemObject.name + newItemObject.surname + " успішно додан(а)!"
					);
				} else if (result.message == "exist") {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(
						newItemObject.name + newItemObject.surname + " вже існує"
					);
				}
			});
		}
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Видалено " + itemsWaitingForDelete.length + " людей"
				);
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обрану людину?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index].id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " людей?"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const handleBranchFilterChange = (event) => {
		setBranchFilter(event.target.value);
		getItemsFromApi(event.target.value, statusFilter);
	};

	const handleStatusFilterChange = (event) => {
		setStatusFilter(event.target.value);
		getItemsFromApi(branchFilter, event.target.value);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				let personBranch = branches.find(
					(b) => b.title == data[index]["Branch"]
				)._id;

				itemsUpload.push({
					name: data[index]["First Name"],
					surname: data[index]["Last Name"],
					branch: personBranch,
					gender: data[index]["Gender"] == "Male" ? 1 : 0,
					birthday: data[index]["Birthday Format"],
					email: data[index]["Email"],
					phone: data[index]["Phone"],
					homePhone: data[index]["Home Phone"],
					address: data[index]["Address"],
					studyGroup: data[index]["Study Group"],
					status: data[index]["Status"],
					add_profession: data[index]["Profession"],
					private_passportName: data[index]["Passport First Name"],
					private_passportSurname: data[index]["Passport Last Name"],
					private_passportNumber: data[index]["Passport Number"],
					private_memberCardNumber: data[index]["Member Card Number"],
					creationDate: data[index]["Creation Date Format"],
					third_initialContactDate: data[index]["Initial Contact Date Format"],
					third_initialContactChannel: data[index]["Initial Contact Channel"],
					third_initialContactMedia: data[index]["Initial Contact Media"],
					third_interests: data[index]["Interested in"],
					third_newsletters: data[index]["Newsletter"],
					probationist_start_date: data[index]["Probationist Since Format"],
					member_memberSince: data[index]["Member Since Format"],
					live_force_start_date: data[index]["Living Force Since Format"],
					instructor_start_date: data[index]["Instructor Since Format"],
					axe_bearer_start_date: data[index]["Axe Bearer Since Format"],
					notes: data[index]["Remarks"],
					/// candidates
					fullname: data[index]["Full Name"],
					candidate_logos:
						data[index]["Candidate Logos"] == "First Logos"
							? 1
							: data[index]["Candidate Logos"] == "Second Logos"
							? 2
							: data[index]["Candidate Logos"] == "Third Logos"
							? 3
							: null,
					candidate_stage: data[index]["Candidate Stage"],
					candidate_start_date: data[index]["Candidate Since Format"],
					candidate_end_date: data[index]["Candidate End Date Format"],
					candidate_branch_start_date:
						data[index]["Candidate Branch Start Date"],
					candidate_study_group: data[index]["Candidate Study Group"],
					candidate_branch: data[index]["Candidate Branch"],
					/// Living Force
					isLiveForce: data[index]["LF Status"] == "Active" ? true : false,
					live_force_leadership_level:
						data[index]["LF Leadership Level"] == "None"
							? 1
							: data[index]["LF Leadership Level"] == "Secretary"
							? 2
							: data[index]["LF Leadership Level"] == "Leader"
							? 3
							: 0,
					live_force_logos:
						data[index]["LF Logos"] == "First Logos"
							? 1
							: data[index]["LF Logos"] == "Second Logos"
							? 2
							: data[index]["LF Logos"] == "Third Logos"
							? 3
							: 0,
					live_force_start_date: data[index]["LF Living Force Since Format"],
					live_force_currect_course: data[index]["LF Current Course"],
					live_force_end_date: data[index]["LF End Date Format"],
					/// Instructor
					isInstructor:
						data[index]["Instructor Status"] == "Active" ? true : false,
					instructor_study_groups: data[index]["Instructor Study Group"],
					current_bonfire:
						data[index]["Instructor Bonfire"] == "Copper"
							? 1
							: data[index]["Instructor Bonfire"] == "Silver"
							? 2
							: data[index]["Instructor Bonfire"] == "Gold"
							? 3
							: 0,
					instructor_current_teaching_courses:
						data[index]["Instructor Current Teaching Course"],
					instructor_past_teaching_courses:
						data[index]["Instructor Past Teaching Course"],
					instructor_start_date:
						data[index]["Instructor Instructor Since Format"],
					instructor_end_date: data[index]["Instructor End Date Format"],
					/// Axe Bearers
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage("Було завантажено " + counter + " людей!");
				} else {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage("Сталась помилка");
				}
			});
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "People-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			dataArray.push({
				"Ім'я": element.name,
				Призвище: element.surname,
				Стать: element.gender,
				Email: element.email,
				Телефон: element.phone,
				Осередок: element.branch,
				"Дата першого контакту": element.initialContactDate,
				Комментарі: element.remarks,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	const changePageHandler = (pageNumber) => {
		window.history.replaceState({}, "", "/" + `people/${pageNumber + 1}`);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.jumpToPage = true;
	if (props.match.params.page) {
		tableSettingsLocal.page = props.match.params.page - 1;
	}
	tableSettingsLocal.onChangePage = changePageHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-md-12">
									<header id="header">
										<h1>Люди</h1>
										{/* <ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Осередки</li>
										</ol> */}
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="categories">
							<div class="row">
								<div class="col-xs-12">
									<div class="content">
										<header class="content-heading">
											<div className="card-actions-left-wrapper">
												<button
													class="btn btn-green"
													onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Додати людину
												</button>
											</div>
											<div className="card-actions-filters">
												<FormControl className="card-actions-filters-item-wrapper">
													<InputLabel
														id="people-filter-branch-label"
														className="card-actions-filters-item-label"
													>
														Осередок
													</InputLabel>
													<Select
														labelId="people-filter-branch-label"
														id="people-filter-branch"
														className="card-actions-filters-item-select"
														value={
															permissions.onlyAdminBranch
																? permissions.adminBranch
																: branchFilter
														}
														label="Осередок"
														onChange={handleBranchFilterChange}
														disabled={
															permissions.onlyAdminBranch ? true : false
														}
													>
														<MenuItem value={null}>Всі</MenuItem>
														{branches.map(function(item, i) {
															return (
																<MenuItem value={item._id}>
																	{item.title}
																</MenuItem>
															);
														})}
													</Select>
												</FormControl>
												<FormControl className="card-actions-filters-item-wrapper">
													<InputLabel
														id="people-filter-status-label"
														className="card-actions-filters-item-label"
													>
														Статус
													</InputLabel>
													<Select
														labelId="people-filter-status-label"
														id="people-filter-status"
														className="card-actions-filters-item-select"
														name="statusFilter"
														value={statusFilter}
														label="Статус"
														onChange={handleStatusFilterChange}
													>
														<MenuItem value={null}>Всі</MenuItem>
														{statuses.map(function(item, i) {
															return (
																<MenuItem value={item._id}>
																	{item.title}
																</MenuItem>
															);
														})}
													</Select>
												</FormControl>
											</div>
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={fileClickHandler}
													>
														<i class="zmdi zmdi-upload"></i>
														<input
															type="file"
															style={{ display: "none" }}
															ref={(input) => {
																fileInput = input;
															}}
															accept=".csv, .xls, .xlsx"
															onChange={fileUploadHandler}
														/>
														{/* /> */}
													</button>
												</li>
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={prepareDataToExport}
													>
														<i class="zmdi zmdi-download"></i>
													</button>
												</li>
											</ul>
										</header>
										<div class="content-body m-t-20">
											<MUIDataTable
												title={"Люди"}
												data={tableData}
												columns={columns}
												options={options}
											/>
										</div>
									</div>
								</div>

								{/* Create dialog */}
								<Dialog
									open={dialogOpen}
									onClose={dialogCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div class="modal-dialog">
										<div class="modal-content">
											<DialogTitle className="modal-header">
												<h4 class="modal-title" id="myModalLabel-2">
													Додати людину
												</h4>
												<ul class="card-actions icons right-top">
													<a
														href="javascript:void(0)"
														data-dismiss="modal"
														class="text-white"
														aria-label="Close"
														onClick={dialogCloseHandler}
													>
														<i class="zmdi zmdi-close"></i>
													</a>
												</ul>
											</DialogTitle>
											<div class="modal-body">
												<form onSubmit={handleFormSave}>
													<FormGroup row>
														<Grid
															component="label"
															container
															alignItems="center"
															spacing={1}
														>
															<Grid item>Жіноча</Grid>
															<Grid item>
																<Switch
																	checked={newItem.gender}
																	onChange={handleSwitcherChange}
																	name="gender"
																/>
															</Grid>
															<Grid item>Чоловіча</Grid>
														</Grid>
													</FormGroup>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															autoFocus
															id="name-input"
															name="name"
															value={newItem.name}
															onChange={handleInputChange}
															label="Ім'я"
															defaultValue=""
															helperText={nameError ? "Поле обов'язкове" : ""}
															error={nameError}
														/>
													</FormControl>

													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															id="surname-input"
															name="surname"
															value={newItem.surname}
															onChange={handleInputChange}
															label="Призвище"
															defaultValue=""
															helperText={
																surnameError ? "Поле обов'язкове" : ""
															}
															error={surnameError}
														/>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															type="email"
															id="email-input"
															name="email"
															value={newItem.email}
															onChange={handleInputChange}
															label="Email"
															defaultValue=""
															// helperText={formValidation.fields.shortName.text}
															// error={formValidation.fields.shortName.isError}
														/>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputMask
															mask="\+380 (99\)\ 999 9999"
															value={newItem.phone}
															disabled={false}
															maskChar="_"
															onChange={handleInputChange}
														>
															{() => (
																<TextField
																	id="phone-input"
																	name="phone"
																	value={newItem.phone}
																	label="Телефон"
																	defaultValue=""
																	// helperText={formValidation.fields.shortName.text}
																	// error={formValidation.fields.shortName.isError}
																/>
															)}
														</InputMask>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel
															id="settings-modal-input-branch-parrent-label"
															className={branchError && "red"}
														>
															Осередок *
														</InputLabel>
														<Select
															labelId="settings-modal-input-branch-parrent-label"
															id="settings-modal-input-branch-parrent"
															name="branch"
															variant="outlined"
															label="Осередок*"
															value={newItem.branch}
															onChange={handleInputChange}
															required
															error={branchError}
														>
															{branches.map(function(item, i) {
																return (
																	<MenuItem value={item._id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
														{branchError && (
															<FormHelperText className="red">
																Поле обов'язкове
															</FormHelperText>
														)}
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="initialContactDate"
															name="initialContactDate"
															label="Дата першого контакту"
															type="date"
															// defaultValue="2017-05-24"
															value={newItem.initialContactDate}
															onChange={handleInputChange}
															InputLabelProps={{
																shrink: true,
															}}
															helperText={
																initialContactDateError
																	? "Поле обов'язкове"
																	: ""
															}
															error={initialContactDateError}
														/>
													</FormControl>
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															multiline
															rows="4"
															id="comments-input"
															name="remarks"
															value={newItem.remarks}
															onChange={handleInputChange}
															label="Комментарі"
															defaultValue=""
															// helperText={formValidation.fields.shortName.text}
															// error={formValidation.fields.shortName.isError}
														/>
													</FormControl>
												</form>
											</div>
											<div class="modal-footer">
												<button
													type="button"
													class="btn btn-default btn-flat"
													data-dismiss="modal"
													onClick={dialogCancelHandler}
												>
													Скасувати
												</button>

												<button
													type="button"
													class="btn btn-primary"
													onClick={handleFormSave}
													// onClick={validateForm}
												>
													Створити
												</button>
											</div>
										</div>
									</div>
								</Dialog>

								<Dialog
									open={alertOpen}
									onClose={alertCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div className="flamingo-alert-body-wrapper">
										<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
										{alertWarningMessage}
									</div>
									<div className="flamingo-alert-buttons-wrapper">
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={alertCloseHandler}
										>
											Скасувати
										</button>
										{alertActionButton}
									</div>
								</Dialog>
								<Snackbar
									open={snackBarOpen}
									autoHideDuration={6000}
									onClose={handleSnackBarClose}
								>
									<MuiAlert
										onClose={handleSnackBarClose}
										severity={snackBarSeverity}
										elevation={6}
										variant="filled"
									>
										{snackBarMessage}
									</MuiAlert>
								</Snackbar>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default connect(
	(store) => ({}),
	(dispatch) =>
		bindActionCreators(
			{
				setLoading: Loading,
			},
			dispatch
		)
)(withRouter(People));
