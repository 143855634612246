import React, { useState } from "react";

import BookCategoriesSettings from "../../settings/Books/BookCategories";
import BookLangSettings from "../../settings/Books/BookLangSettings";
import BookLibrariesSettings from "../../settings/Books/BookLibrariesSettings";

const BookSettings = (props) => {
	const [] = useState();

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Книги - Налаштування</h1>
										<ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Книги</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
					<div class="tabpanel tab-header">
						<ul class="nav nav-tabs p-l-30 flamingo-tab-panel">
							<li class="active" role="presentation">
								<a href="#categories" data-toggle="tab">
									Категорії
								</a>
							</li>
							<li role="presentation">
								<a href="#languages" data-toggle="tab">
									Миви видання
								</a>
							</li>
							<li role="presentation">
								<a href="#libraries" data-toggle="tab">
									Бібліотеки
								</a>
							</li>
						</ul>
					</div>
					<div id="content" class="container-fluid m-t-0">
						<div class="content-body">
							<div id="card_content" class="tab-content">
								<div class="tab-pane fade active in" id="categories">
									<div class="row">
										<BookCategoriesSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="languages">
									<div class="row">
										<BookLangSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="libraries">
									<div class="row">
										<BookLibrariesSettings />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BookSettings;
