import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tooltip from "@mui/material/Tooltip";
import logo from "../../flamingo.png";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import * as ApiRequest from "../../api/apiRequest";
import { Session } from "../../actions";

const Navigation = (props) => {
	const cookies = new Cookies();

	const [user, setUser] = useState({});

	useEffect(() => {
		if (props.session.name) {
			setUser(props.session);
		} else {
			let userId = cookies.get("user");
			getUserData(userId);
		}
	}, []);

	// useEffect(() => {
	// 	console.log("session", props.session);
	// }, [props.session]);

	const getUserData = (userId) => {
		if (userId) {
			let request = {
				purpose: "getUserDate",
				userId: userId,
			};
			ApiRequest.apiRequest("AdminConfig", request).then((result) => {
				if (result.success) {
					let userObject = {
						...result.user,
						roleName: result.permissions.title,
					};
					setUser(userObject);

					props.Session(userObject);
				}
			});
		}
	};

	let navigationArray = {
		people: {
			url: "/people",
			parent: null,
			className: "",
		},
		events: {
			url: "/events",
			parent: null,
			className: "",
		},
		eventsExternal: {
			url: "/events/external",
			parent: "events",
			className: "",
		},
		eventsInternal: {
			url: "/events/internal",
			parent: "events",
			className: "",
		},
		books: {
			url: "/books",
			parent: null,
			className: "",
		},
		reports: {
			url: "/reports",
			parent: null,
			className: "",
		},
		branchesSettings: {
			url: "/settings/branches",
			parent: null,
			className: "",
		},
		peopleSettings: {
			url: "/settings/people",
			parent: null,
			className: "",
		},
		peopleSettingsGeneral: {
			url: "/settings/people/general",
			parent: "peopleSettings",
			className: "",
		},
		peopleThirdCircleSettings: {
			url: "/settings/people/thirdcircle",
			parent: "peopleSettings",
			className: "",
		},
		peopleSecondCircleSettings: {
			url: "/settings/people/secondcircle",
			parent: "peopleSettings",
			className: "",
		},
		bookSettings: {
			url: "/settings/books",
			parent: null,
			className: "",
		},
		eventsSettings: {
			url: "/settings/events",
			parent: null,
			className: "",
		},
		mediaSettings: {
			url: "/settings/media",
			parent: null,
			className: "",
		},
		permisionsSettings: {
			url: "/settings/admin",
			parent: "permisionsSettings",
			className: null,
		},
	};

	const logoutHandler = () => {
		cookies.remove("flamingo");
		cookies.remove("user");
		window.location.replace("/login");
	};

	// const [navigationObject, setNavigationObject] = useState(navigationArray);

	useEffect(() => {
		getUserData();
	});

	const defineActiveNavigationItems = () => {
		const url = window.location.pathname;

		let newNavObject = navigationArray;
		// 	let newNavObject = navigationObject;

		for (let i in newNavObject) {
			if (newNavObject[i].url == url) {
				newNavObject[i].className = "active";
				if (newNavObject[i].parent) {
					newNavObject[newNavObject[i].parent].className = "active open";
				}
			} else {
				newNavObject[i].className = "";
			}
		}

		navigationArray = newNavObject;
	};

	defineActiveNavigationItems();

	const [navigationArrayState, setNavigationArrayState] =
		useState(navigationArray);

	// const navigationObject = newNavObject;

	const linkClick = (navObjectName, parentName) => {
		// console.log("link click", navObjectName, parentName);
	};

	return (
		<>
			<aside id="app_sidebar-left">
				<div id="logo_wrapper">
					<ul>
						<li class="logo-icon">
							<Link to="/">
								<div class="logo">
									<img src={logo} alt="Logo" />
								</div>
								<h1 class="brand-text">Flamingo</h1>
							</Link>
						</li>
						{/* <li class="menu-icon">
							<a
								href="javascript:void(0)"
								role="button"
								data-toggle-state="app_sidebar-menu-collapsed"
								data-key="leftSideBar"
							>
								<i class="mdi mdi-backburger"></i>
							</a>
						</li> */}
					</ul>
				</div>
				{user.name ? (
					<div className="nav-admin-wrapper">
						<div className="nav-admin-person-wrapper">
							<span className="nav-admin-person-name">{`${user.name} ${user.surname}`}</span>
							<span className="nav-admin-person-role">{user.roleName}</span>
						</div>
						<div className="nav-admin-logout-wrapper">
							<Tooltip title="Log out" placement="right">
								<IconButton aria-label="delete" onClick={logoutHandler}>
									<ExitToAppIcon
										className="nav-admin-logout-icon"
										fontSize="large"
									/>
								</IconButton>
							</Tooltip>
						</div>
					</div>
				) : (
					""
				)}
				<nav id="app_main-menu-wrapper" class="scrollbar">
					<div class="sidebar-inner sidebar-push">
						<ul class="nav nav-pills nav-stacked">
							<li class="sidebar-header">Робоча зона</li>
							<li
								className={navigationArrayState.people.className}
								onClick={() => linkClick("people", null)}
							>
								{/* <li className="active"> */}
								{/* <a href="index.html">
									<i class="zmdi zmdi-face"></i>Люди
								</a> */}
								<Link to="/people">
									<i class="zmdi zmdi-face"></i>Люди
								</Link>
							</li>
							<li
								className={
									"nav-dropdown " + navigationArrayState.events.className
								}
							>
								<a name="Заходи">
									<i class="zmdi zmdi-cocktail"></i>Заходи
								</a>
								<ul class="nav-sub">
									<li className={navigationArrayState.eventsExternal.className}>
										<Link to="/events/external">Зовнішні</Link>
										{/* <a href="ecommerce-dashboard.html">Книги</a> */}
									</li>
									<li className={navigationArrayState.eventsInternal.className}>
										<Link to="/events/internal">Внутрішні</Link>
										{/* <a href="ecommerce-dashboard.html">Книги</a> */}
									</li>
									{/* <li>
										<a href="ecommerce-products.html">Быбліотеки</a>
									</li> */}
								</ul>
							</li>
							<li
								className={
									"nav-dropdown " + navigationArrayState.books.className
								}
							>
								<a href="">
									<i class="zmdi zmdi-library"></i>Книги
								</a>
								<ul class="nav-sub">
									<li>
										<Link to="/books">Книги</Link>
										{/* <a href="ecommerce-dashboard.html">Книги</a> */}
									</li>
									{/* <li>
										<a href="ecommerce-products.html">Видача</a>
									</li>
									<li>
										<a href="ecommerce-products.html">Быбліотеки</a>
									</li> */}
								</ul>
							</li>
							<li className={navigationArrayState.reports.className}>
								<a href="index.html">
									<i class="zmdi zmdi-trending-up"></i>Звіти
								</a>
							</li>

							<li class="sidebar-header">Налаштування</li>
							{/* <li>
								<a href="app-file-manager.html">
									<i class="zmdi zmdi-settings"></i>Основне
								</a>
							</li> */}
							<li className={navigationArrayState.permisionsSettings.className}>
								{/* <a href=""> */}

								<Link to="/settings/admin">
									<i class="zmdi zmdi-lock"></i>Адміністратори
								</Link>
								{/* </a> */}
								{/* <ul class="nav-sub">
									<li>
										<a href="ecommerce-dashboard.html">Ролі</a>
									</li>
									<li>
										<Link to="/settings/admin">Адміністратори</Link>
									</li>
								</ul> */}
							</li>
							<li className={navigationArrayState.branchesSettings.className}>
								<Link to="/settings/branches">
									<i class="zmdi zmdi-book"></i>Осередки
								</Link>
							</li>
							<li
								className={
									"nav-dropdown " +
									navigationArrayState.peopleSettings.className
								}
							>
								<a name="">
									<i class="zmdi zmdi-account-circle"></i>Слухачі
								</a>
								<ul class="nav-sub">
									<li
										className={
											navigationArrayState.peopleSettingsGeneral.className
										}
									>
										<Link to="/settings/people/general">Загальне</Link>
									</li>
									<li
										className={
											navigationArrayState.peopleThirdCircleSettings.className
										}
									>
										<Link to="/settings/people/thirdcircle">Третє коло</Link>
									</li>
									<li
										className={
											navigationArrayState.peopleSecondCircleSettings.className
										}
									>
										<Link to="/settings/people/secondcircle">Друге коло</Link>
									</li>
								</ul>
							</li>
							<li className={navigationArrayState.bookSettings.className}>
								<Link to="/settings/books">
									<i class="zmdi zmdi-book"></i>Книги
								</Link>
							</li>

							<li className={navigationArrayState.eventsSettings.className}>
								<Link to="/settings/events">
									<i class="zmdi zmdi-cocktail"></i>Заходи
								</Link>

								{/* <ul class="nav-sub">
									<li>
										<a href="ecommerce-dashboard.html">Зовнішні заходи</a>
									</li>
									<li>
										<a href="ecommerce-products.html">Внутрішні заходи</a>
									</li>
								</ul> */}
							</li>
							<li className={navigationArrayState.mediaSettings.className}>
								<Link to="/settings/media">
									<i class="zmdi zmdi-thumb-up"></i>Медіа
								</Link>
							</li>
						</ul>
					</div>
				</nav>
			</aside>
		</>
	);
};

// export default Navigation;

export default connect(
	(store) => ({
		session: store.session,
		isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Session,
			},
			dispatch
		)
)(Navigation);
