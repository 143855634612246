import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PeopleLogosHistory from "./PeopleLogosHistory";

const PeopleLiveForceInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="live_force_logos-label">Логос</InputLabel>
							<Select
								labelId="live_force_logos-label"
								id="initial-live_force_logos-channel"
								name="live_force_logos"
								disabled
								defaultValue={props.person.live_force_logos}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.livingForceLogos.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="live_force_origin-label">Походження</InputLabel>
							<Select
								labelId="live_force_origin-label"
								id="live_force_origin"
								name="live_force_origin"
								disabled
								defaultValue={props.person.live_force_origin}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.liveForceOrigins.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="live_force_leadership_level-label">
								Лідерський рівень
							</InputLabel>
							<Select
								labelId="live_force_leadership_level-label"
								id="live_force_leadership_level"
								name="live_force_leadership_level"
								defaultValue={props.person.live_force_leadership_level}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.liveForceLeadershipLevels.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="live_force_international_role-label">
								Міжнародна роль
							</InputLabel>
							<Select
								labelId="live_force_international_role-label"
								id="live_force_international_role"
								name="live_force_international_role"
								defaultValue={props.person.live_force_international_role}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.liveForceInternationalRoles.map(function (item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="live_force_start_date"
								name="live_force_start_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.live_force_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row col-md-12">
					{props.person.live_force_logoses_history.length == 0 &&
					props.livingForceLogos ? (
						""
					) : (
						<>
							<h3 className="people-acordeon-inside-header">Історія Логосів</h3>
							<PeopleLogosHistory
								person={props.person}
								livingForceLogos={props.livingForceLogos}
							/>
						</>
					)}
				</div>
			</form>
		</>
	);
};

export default PeopleLiveForceInfo;
