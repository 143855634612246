import React, { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import PeopleBonfiresInfo from "./PeopleBonfiresInfo";

const PeopleInstructorInfo = (props) => {
	return (
		<>
			<form>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="instructor_study_groups"
							name="instructor_study_groups"
							label="Групи навчання"
							defaultValue={props.person.instructor_study_groups}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<InputLabel id="instructor_origin-label">Логос</InputLabel>
							<Select
								labelId="instructor_origin-label"
								id="instructor_origin"
								name="instructor_origin"
								disabled
								defaultValue={props.person.instructor_origin}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
							>
								{props.instructorOrigins.map(function(item, i) {
									return <MenuItem value={item._id}>{item.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="instructor_start_date"
								name="instructor_start_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.instructor_start_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
				<div className="row acordion-row col-md-12">
					{props.person.bonfires.length > 0 ? (
						<>
							<h3 className="people-acordeon-inside-header">Історія Факелів</h3>
							<PeopleBonfiresInfo
								person={props.person}
								bonfires={props.bonfires}
								getPersonData={props.getPersonData}
							/>
						</>
					) : (
						""
					)}
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<InputLabel id="instructor_current_teaching_courses-label">
							Курси, що зараз веде
						</InputLabel>
						<Select
							labelId="instructor_current_teaching_courses-label"
							multiple
							multiline
							rows="4"
							id="instructor_current_teaching_courses"
							name="instructor_current_teaching_courses"
							defaultValue={props.person.instructor_current_teaching_courses}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
							renderValue={(selected) => (
								<div className="multiline-chip-select">
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												props.courses.find((element) => element._id == value)
													.title
											}
											className="multiline-chip-select-chip"
										/>
									))}
								</div>
							)}
						>
							{/* <MenuItem value={null}>-- немає --</MenuItem> */}

							{props.courses.map(function(item, i) {
								return <MenuItem value={item._id}>{item.title}</MenuItem>;
							})}
						</Select>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<InputLabel id="instructor_past_teaching_courses-label">
							Курси, що вів/вела раніше
						</InputLabel>
						<Select
							labelId="instructor_past_teaching_courses-label"
							multiple
							multiline
							rows="4"
							id="instructor_past_teaching_courses"
							name="instructor_past_teaching_courses"
							defaultValue={props.person.instructor_past_teaching_courses}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
							renderValue={(selected) => (
								<div className="multiline-chip-select">
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												props.courses.find((element) => element._id == value)
													.title
											}
											className="multiline-chip-select-chip"
										/>
									))}
								</div>
							)}
						>
							{props.courses.map(function(item, i) {
								return <MenuItem value={item._id}>{item.title}</MenuItem>;
							})}
						</Select>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="instructor_courses_received"
							name="instructor_courses_received"
							label="Отримані курси (дати)"
							multiline
							defaultValue={props.person.instructor_courses_received}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="instructor_courses_imparted"
							name="instructor_courses_imparted"
							label="Курси що вів/вела (дати)"
							multiline
							defaultValue={props.person.instructor_courses_imparted}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="instructor_scholastic_works"
							name="instructor_scholastic_works"
							label="Автор схоластичних робіт"
							multiline
							defaultValue={props.person.instructor_scholastic_works}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
						/>
					</FormControl>
				</div>
				<div className="row acordion-row col-md-12">
					<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
						<TextField
							id="instructor_collaborator_magazines"
							name="instructor_collaborator_magazines"
							label="Автор в журналах, публікаціях"
							multiline
							defaultValue={props.person.instructor_collaborator_magazines}
							// onChange={props.handleInputChange}
							onBlur={props.handleItemUpdate}
						/>
					</FormControl>
				</div>
			</form>
		</>
	);
};

export default PeopleInstructorInfo;
