import "./App.css";
import React from "react";

import Router from "./components/Router";
// import Router2 from "./components/Router2";

import Loader from "./components/main/Loader";

import { Provider } from "react-redux";
import store from "./store";

import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "./components/ErrorScreen";

function App() {
	return (
		// <div className="App">
		<>
			<Provider store={store}>
				<Loader />
				<Router />
				{/* <Router2 /> */}
			</Provider>
		</>

		// </div>
	);
}

export default App;
