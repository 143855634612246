import React, { useState, useEffect } from "react";

import InterestSettings from "../../../settings/People/InterestsSettings";
import NewsLettersExternalSettings from "../../../settings/People/NewsLettersExternalSettings";
import InitialContactMediaSettings from "../../../settings/People/InitialContactMediaSettings";
import InitialContactChannelSettings from "../../../settings/People/InitialContactChannelSettings";

const ThirdCircleSettings = (props) => {
	const [] = useState();

	return (
		<>
			{/* <Header /> */}
			{/* <MainNavigation /> */}
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Слухачі - Налаштування</h1>
										<ol class="breadcrumb">
											<li>
												<a href="index.html"></a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Третє коло</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
					<div class="tabpanel tab-header">
						<ul class="nav nav-tabs p-l-30 flamingo-tab-panel">
							<li class="active" role="presentation">
								<a href="#leavereason" data-toggle="tab">
									Інтереси
								</a>
							</li>
							<li role="presentation">
								<a href="#externalnewsletters" data-toggle="tab">
									Зовнішні розсилки
								</a>
							</li>
							<li role="presentation">
								<a href="#initialcontactchannels" data-toggle="tab">
									Канали першого контакту
								</a>
							</li>
							<li role="presentation">
								<a href="#initialcontactmedias" data-toggle="tab">
									Медіа першого контакту
								</a>
							</li>
						</ul>
					</div>
					<div id="content" class="container-fluid m-t-0">
						<div class="content-body">
							<div id="card_content" class="tab-content">
								<div class="tab-pane fade active in" id="leavereason">
									<div class="row">
										<InterestSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="externalnewsletters">
									<div class="row">
										<NewsLettersExternalSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="initialcontactchannels">
									<div class="row">
										<InitialContactChannelSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="initialcontactmedias">
									<div class="row">
										<InitialContactMediaSettings />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ThirdCircleSettings;
