import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../../api/apiRequest";
import { tableSettings } from "../../../configs/visualizationConfig";

const ExternalEventsSettings = (props) => {
	let fileInput = null;

	const host = "EventsConfig";
	const endpointPurposes = {
		getData: "getEventTypes",
		createItem: "createEventType",
		updateItem: "updateEventType",
		deleteItem: "deleteEventTypes",
		uploadFile: "uploadFileEventType",
	};

	const isExternal = true;

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	//// !!! Change the structure !!!
	const [newItem, setNewItem] = useState({
		title: "",
		categoryId: "",
		category: "",
		description: "",
	});

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState({
		title: "",
		categoryId: "",
		category: "",
		description: "",
	});

	const [itemCategories, setItemCategories] = useState([]);

	const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [titleError, setTitleError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);

	const getItemsFromApi = () => {
		let request = {
			purpose: endpointPurposes.getData,
			isExternal: isExternal,
		};

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let tempArray = [];
					tempArray.push(element.title);
					tempArray.push(element.category);
					tempArray.push(element.description);
					tempArray.push(element.id);

					dataArray.push(tempArray);
				});

				setItems(result.items);
				setItemCategories(result.categories);
				setTableData(dataArray);
			}
		});
	};

	useEffect(() => {
		getItemsFromApi();
	}, []);

	//// !!! Change the structure !!!
	const columns = [
		"Тип",
		"Категорія",
		"Деталі",
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<Tooltip title="Редагувати">
							<IconButton
								aria-label="Редагувати"
								onClick={() => editItemHandler(value)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Видалити">
							<IconButton
								aria-label="Видалити"
								onClick={() => deleteItemHandler(value)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		if (!updateMode) {
			setNewItemStateSave({
				...newItem,
				[event.target.name]: event.target.value,
			});
		}
		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const handleParentCheck = (event) => {
		let categoryTitle = "";
		let categoryId = event.target.value;

		if (categoryId != null) {
			itemCategories.forEach((element) => {
				if (element.id == categoryId) {
					categoryTitle = element.title;
				}
			});
		}

		setNewItem({
			...newItem,
			category: categoryTitle,
			categoryId: categoryId,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
		setUpdateMode(false);
	};

	const dialogCloseHandler = () => {
		if (updateMode) {
			setNewItem(newItemStateSave);
		}
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem({
			title: "",
			categoryId: "",
			category: "",
			description: "",
		});
		setDialogOpen(false);

		setTitleError(false);
		setCategoryError(false);

		if (updateMode) {
			setSaveStateItemInput(true);
		} else {
			setSaveStateItemInput(false);
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!newItem.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!newItem.categoryId) {
			valid = false;
			setCategoryError(true);
		} else {
			setCategoryError(false);
		}

		return valid;
	};

	const handleFormSave = () => {
		let isValid = validateForm();
		if (isValid) {
			let newItemObject = {
				title: newItem.title,
				categoryId: newItem.categoryId,
				description: newItem.description,
				isExternal: isExternal,
			};

			let request = {
				purpose: endpointPurposes.createItem,
				body: newItemObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					dialogCancelHandler();
					setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage("Успішно додано " + newItemObject.title);
				} else {
					let message = "Помилка серверу";

					if (result.message == "exist") {
						message = "Тип з таким ім'ям вже існує";
					}

					getItemsFromApi();
					dialogCancelHandler();
					setUpdateMode(false);
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(message);
				}
			});
		}
	};

	// Edit dialog handlers

	const editItemHandler = (id) => {
		let itemToEdit = {};

		items.forEach((element) => {
			if (element.id == id) {
				itemToEdit = element;
			}
		});

		setUpdateMode(true);
		setNewItemStateSave(newItem);
		setNewItem(itemToEdit);
		setDialogOpen(true);

		setTitleError(false);
		setCategoryError(false);
	};

	const handleUpdateSave = () => {
		let isValid = validateForm();
		if (isValid) {
			let newItemObject = {
				id: newItem.id,
				title: newItem.title,
				categoryId: newItem.categoryId,
				description: newItem.description,
				isExternal: isExternal,
			};

			let request = {
				purpose: endpointPurposes.updateItem,
				body: newItemObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getItemsFromApi();
					dialogCancelHandler();
					setSnackBarOpen(true);
					setSnackBarSeverity("success");
					setSnackBarMessage(
						newItemObject.title + " тип заходу успішно оновлено!"
					);
				} else {
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage("Сталася помилка");
				}
			});
		}
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Було видалено " + itemsWaitingForDelete.length + " типів!"
				);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage("Ви впевнені що хочете видалити обраний тип?");
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index].id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " запис(ів)"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let successFlag = true;

			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				let categoryId = "";

				if (data[index]["Тип"] == undefined) {
					successFlag = false;
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(
						"Не вірний формат, перевірте файл, рядок: " + index
					);
					break;
				}

				itemCategories.forEach((element) => {
					if (data[index]["Категорія"] == element.title) {
						categoryId = element.id;
					}
				});

				itemsUpload.push({
					title: data[index]["Тип"],
					category: data[index]["Категорія"],
					description: data[index]["Деталі"],
					categoryId: categoryId,
					isExternal: isExternal,
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			if (successFlag) {
				ApiRequest.apiRequest(host, request).then((result) => {
					if (result.success) {
						getItemsFromApi();
						setSnackBarOpen(true);
						setSnackBarSeverity("success");
						setSnackBarMessage("Успішно завантажено " + counter + " мови!");
					} else {
						setSnackBarOpen(true);
						setSnackBarSeverity("error");
						setSnackBarMessage("Сталася помилка");
					}
				});
			}
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "EventTypes-Internal-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			dataArray.push({
				Тип: element.title,
				Категорія: element.category,
				Деталі: element.description,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<div class="col-xs-12">
				<div class="content">
					<header class="content-heading">
						<div className="card-actions-left-wrapper">
							<button
								class="btn btn-green"
								onClick={dialogOpenHandler}
								data-toggle="modal"
								data-target="#basic_modal"
							>
								+ Додати тип заходу
							</button>
						</div>
						<ul class="card-actions icons right-top">
							<li className="page-controlls-right-item">
								<button
									class="btn btn-default btn-fab"
									onClick={fileClickHandler}
								>
									<i class="zmdi zmdi-upload"></i>
									<input
										type="file"
										style={{ display: "none" }}
										ref={(input) => {
											fileInput = input;
										}}
										accept=".csv, .xls, .xlsx"
										onChange={fileUploadHandler}
									/>
									{/* /> */}
								</button>
							</li>
							<li className="page-controlls-right-item">
								<button
									class="btn btn-default btn-fab"
									onClick={prepareDataToExport}
								>
									<i class="zmdi zmdi-download"></i>
								</button>
							</li>
						</ul>
					</header>
					<div class="content-body m-t-20">
						<MUIDataTable
							title={"Типи зовнішніх заходів"}
							data={tableData}
							columns={columns}
							options={options}
						/>
					</div>
				</div>
			</div>

			{/* Create dialog */}
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								{updateMode ? "Редагувати тип заходу" : "Додати тип заходу"}
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<div class="modal-body">
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									required
									autoFocus
									id="event-type-title-input"
									name="title"
									value={newItem.title}
									onChange={handleInputChange}
									label="Тип заходу"
									defaultValue=""
									helperText={titleError ? "Поле обов'язкове" : ""}
									error={titleError}
								/>
							</FormControl>
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<InputLabel
									id="settings-modal-input-branch-parrent-label"
									className={categoryError && "red"}
								>
									Категорія *
								</InputLabel>
								<Select
									labelId="settings-modal-input-event-type-category-label"
									id="settings-modal-input-event-type-category"
									name="category"
									label="Категорія"
									value={newItem.categoryId}
									onChange={(event) => handleParentCheck(event)}
									required
									error={categoryError}
								>
									{itemCategories.map(function(item, i) {
										return <MenuItem value={item.id}>{item.title}</MenuItem>;
									})}
								</Select>
								{categoryError && (
									<FormHelperText className="red">
										Поле обов'язкове
									</FormHelperText>
								)}
							</FormControl>
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									multiline
									rows={4}
									id="event-type-report-input"
									name="description"
									value={newItem.description}
									onChange={handleInputChange}
									label="Деталі"
									defaultValue=""
									// helperText={formValidation.fields.shortName.text}
									// error={formValidation.fields.shortName.isError}
								/>
							</FormControl>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-default btn-flat"
								data-dismiss="modal"
								onClick={dialogCancelHandler}
							>
								Скасувати
							</button>
							{updateMode ? (
								<button
									type="button"
									class="btn btn-primary"
									onClick={handleUpdateSave}
									// onClick={validateForm}
								>
									Зберегти
								</button>
							) : (
								<button
									type="button"
									class="btn btn-primary"
									onClick={handleFormSave}
									// onClick={validateForm}
								>
									Створити
								</button>
							)}
						</div>
					</div>
				</div>
			</Dialog>

			<Dialog
				open={alertOpen}
				onClose={alertCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div className="flamingo-alert-body-wrapper">
					<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
					{alertWarningMessage}
				</div>
				<div className="flamingo-alert-buttons-wrapper">
					<button
						type="button"
						class="btn btn-default btn-flat"
						data-dismiss="modal"
						onClick={alertCloseHandler}
					>
						Скасувати
					</button>
					{alertActionButton}
				</div>
			</Dialog>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity={snackBarSeverity}
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default ExternalEventsSettings;
