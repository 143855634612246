const pipe = (...funct) => (data) => funct.reduce((r, f) => f(r), data);

const getDomElements = (evt) => [...evt.target];

const getInputs = (fields) => fields.filter((_) => _.localName === "input");
const getTextAreas = (fields) =>
	fields.filter((_) => _.localName === "textarea");

const createListOption = (data) =>
	data.map((d) => ({ [d.name]: d.value.trim() }));

const createObjOption = (data) =>
	data.reduce((cash, item) => ({ ...cash, ...item }), {});

export const parserInputForm = pipe(
	getDomElements,
	getInputs,
	createListOption,
	createObjOption
);

export const parserTextAreaForm = pipe(
	getDomElements,
	getTextAreas,
	createListOption,
	createObjOption
);
