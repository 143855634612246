import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import { useHistory } from "react-router";
import { useErrorHandler } from "react-error-boundary";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";

import * as ApiRequest from "../../api/apiRequest";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiConfig from "../../api/apiConfig";

const BookCreateEdit = (props) => {
	const handleError = useErrorHandler();

	let fileInput = null;
	const history = useHistory();

	const [mode, setMode] = useState("create");
	const [bookIdToEdit, setBookIdToEdit] = useState();

	const [bookItem, setBookItem] = useState({
		id: "",
		title: "",
		bookIdentifier: "",
		origin: 0,
		isLoaned: false,
		library: "",
		permission: "",
		category: "",
		language: "",
		author: "",
		publisher: "",
		publicationCity: "",
		publicationYear: "",
		translator: "",
		booksCollection: "",
		keywords: "",
		excertp: "",
		pages: "",
		value: "",
		coverImage: "",
	});

	const [bookConstants, setBookConstants] = useState({
		libraries: [],
		permissions: [],
		categories: [],
		languages: [],
		values: [],
	});

	const host = "Books";
	const fileUploadHost = "BookCoverUpload";
	const endpointPurposes = {
		getData: "get",
		getItem: "getOneItem",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
		getDropdownData: "getDropdownData",
		uploadImageFile: "bookCover",
	};

	const [bookCoverImage, setBookCoverImage] = useState({
		url: "",
		file: null,
	});

	const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

	const [titleError, setTitleError] = useState(false);

	const getDropdownData = () => {
		let request = {
			purpose: endpointPurposes.getDropdownData,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			try {
				if (result.success) {
					setBookConstants({
						libraries: result.libraries,
						permissions: result.permissions,
						categories: result.categories,
						languages: result.languages,
						values: result.values,
					});
				} else {
					throw new Error("not exist");
				}
			} catch (error) {
				handleError(error);
			}
		});
	};

	useEffect(() => {
		const request = props.match.params.param;

		if (request == "create") {
			setMode("create");
		} else {
			setMode("edit");
			getBookEditData(request);
		}
		getDropdownData();
	}, []);

	const getBookEditData = (bookId) => {
		let request = {
			purpose: endpointPurposes.getItem,
			body: {
				id: bookId,
			},
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			try {
				if (result.success) {
					if (result.book.coverImage) {
						let imageUrl = apiConfig.Main + result.book.coverImage;
						result.book.coverImage = imageUrl;
					}
					setBookItem(result.book);
				} else {
					throw new Error("not exist");
				}
			} catch (error) {
				handleError(error);
			}
		});
	};

	const fileClickHandler = () => {
		fileInput.click();
	};

	const inputValidation = (event) => {
		setBookItem({
			...bookItem,
			[event.target.name]: event.target.value,
		});
	};

	const validateForm = () => {
		let valid = true;

		if (!bookItem.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		return valid;
	};

	const saveItemHandler = (createAnotherOne) => {
		let isValid = validateForm();
		if (isValid) {
			if (bookCoverImage.url != "") {
				const formData = new FormData();
				formData.append("file", bookCoverImage.file);

				axios
					.post(apiConfig[fileUploadHost], formData, {
						onUploadProgress: (ProgressEvent) => {
							let progress =
								Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
								"%";
						},
					})
					.then((result) => {
						saveBookApiCall(createAnotherOne, result.data.path);
					});
			} else {
				saveBookApiCall(createAnotherOne);
			}
		}
	};

	const saveBookApiCall = (createAnotherOne, imagePath) => {
		let newItemObject = {
			...bookItem,
			coverImage: imagePath,
		};

		let request = {
			purpose: endpointPurposes.createItem,
			body: newItemObject,
		};

		// console.log(request);

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				if (createAnotherOne) {
					history.go(0);
				} else {
					history.push("/books/");
				}
			}
		});
	};

	const fileUploadHandler = (event) => {
		let imageUrl = URL.createObjectURL(event.target.files[0]);

		setUploadButtonDisabled(true);

		const formData = new FormData();
		formData.append("file", event.target.files[0]);

		axios
			.post(apiConfig[fileUploadHost], formData, {
				onUploadProgress: (ProgressEvent) => {
					let progress =
						Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
						"%";
				},
			})
			.then((result) => {
				if (result.data.success) {
					updateBookApiCall(result.data.path);
				}
			});
	};

	const clearImageUploaded = () => {
		setUploadButtonDisabled(true);
		updateBookApiCall("clear");
	};

	const inputBlurHandler = () => {
		updateBookApiCall();
	};

	const updateBookApiCall = (newImagePath) => {
		let isValid = validateForm();
		if (isValid) {
			let coverImage = "";
			if (newImagePath) {
				if (newImagePath == "clear") {
					coverImage = "";
				} else {
					coverImage = newImagePath;
				}
			} else {
				coverImage = bookItem.coverImage
					? bookItem.coverImage.replace(apiConfig.Main, "")
					: "";
			}
			let newItemObject = {
				...bookItem,
				coverImage: coverImage,
			};

			let request = {
				purpose: endpointPurposes.updateItem,
				body: newItemObject,
				updateAvatar: newImagePath ? true : false,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getBookEditData(bookItem.id);
					setUploadButtonDisabled(false);
				} else {
					console.log("Error updating");
				}
			});
		}
	};

	return (
		<>
			{/* <Header /> */}
			{/* <MainNavigation /> */}
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm book-create-edit-header">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<div className="card-actions-left-wrapper">
											<NavLink to={"/books/"}>
												<IconButton
													aria-label="Перегляд/Редагувати"
													// onClick={() => deleteItemHandler(value)}
												>
													<ArrowBackIcon className="header-back-arrow" />
												</IconButton>
											</NavLink>

											<h1>
												{mode == "create" ? "Додати книгу" : bookItem.title}
											</h1>
										</div>
										{mode == "create" ? (
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<Button
														className="book-action-button"
														variant="outlined"
														color="primary"
														size="big"
														onClick={() => saveItemHandler(false)}
														startIcon={<ArrowBackIosIcon />}
													>
														Зберегти
													</Button>
													<Button
														className="book-action-button"
														variant="outlined"
														color="primary"
														size="big"
														onClick={() => saveItemHandler(true)}
														startIcon={<ArrowForwardIosIcon />}
													>
														Зберегти і додати ще
													</Button>
												</li>
											</ul>
										) : (
											""
										)}
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20 books-edit-wrapper">
						<div class="row">
							<div class="col-xs-12">
								<div class="content">
									<header class="content-heading"></header>
									<div class="content-body m-t-20">
										<div className="col-md-6 books-edit-column-wrapper">
											<h2>Основна інформація</h2>
											<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
												<TextField
													required
													autoFocus
													id="item-title-input"
													name="title"
													onBlur={inputBlurHandler}
													onChange={inputValidation}
													value={bookItem.title}
													// onChange={handleInputChange}
													label="Назва книги"
													defaultValue=""
													helperText={titleError ? "Поле обов'язкове" : ""}
													error={titleError}
												/>
											</FormControl>
											<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
												<TextField
													id="item-author-input"
													name="author"
													onBlur={inputBlurHandler}
													onChange={inputValidation}
													value={bookItem.author}
													// onChange={handleInputChange}
													label="Автор"
													defaultValue=""
													// helperText={formValidation.fields.title.text}
													// error={formValidation.fields.title.isError}
												/>
											</FormControl>
											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-id-input"
															name="bookIdentifier"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.bookIdentifier}
															// onChange={handleInputChange}
															label="ID"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-library-label">
															Бібліотека
														</InputLabel>
														<Select
															labelId="settings-modal-input-library-label"
															id="settings-modal-input-library"
															name="library"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.library}
															// onChange={(event) => handleParentCheck(event)}
														>
															{/* <MenuItem selected value={null}>
														-- немає --
													</MenuItem> */}
															{bookConstants.libraries.map(function(item, i) {
																return (
																	<MenuItem value={item._id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-permissions-label">
															Обмеження
														</InputLabel>
														<Select
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															labelId="settings-modal-input-permissions-label"
															id="settings-modal-input-permissions"
															name="permission"
															value={bookItem.permission}
															// onChange={(event) => handleParentCheck(event)}
														>
															{/* <MenuItem selected value={null}>
														-- немає --
													</MenuItem> */}
															{bookConstants.permissions.map(function(item, i) {
																return (
																	<MenuItem value={item.id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</div>

												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-category-label">
															Категорія
														</InputLabel>
														<Select
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															labelId="settings-modal-input-category-label"
															id="settings-modal-input-category"
															name="category"
															value={bookItem.category}
															// onChange={(event) => handleParentCheck(event)}
														>
															{/* <MenuItem selected value={null}>
														-- немає --
													</MenuItem> */}
															{bookConstants.categories.map(function(item, i) {
																return (
																	<MenuItem value={item.id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-category-label">
															Мова
														</InputLabel>
														<Select
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															labelId="settings-modal-input-language-label"
															id="settings-modal-input-language"
															name="language"
															value={bookItem.language}
															// onChange={(event) => handleParentCheck(event)}
														>
															{/* <MenuItem selected value={null}>
														-- немає --
													</MenuItem> */}
															{bookConstants.languages.map(function(item, i) {
																return (
																	<MenuItem value={item.id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</div>

												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<InputLabel id="settings-modal-input-category-label">
															Цінність
														</InputLabel>
														<Select
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															labelId="settings-modal-input-value-label"
															id="settings-modal-input-value"
															name="value"
															value={bookItem.value}
															// onChange={(event) => handleParentCheck(event)}
														>
															{/* <MenuItem selected value={null}>
														-- немає --
													</MenuItem> */}
															{bookConstants.values.map(function(item, i) {
																return (
																	<MenuItem value={item.id}>
																		{item.title}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</div>
											</div>
											{/* </div> */}
											{/* <div className="col-md-6 books-edit-column-wrapper"> */}
											<h2 className="m-t-50">Додаткова інформація</h2>

											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-publisher-input"
															name="publisher"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.publisher}
															// onChange={handleInputChange}
															label="Видавництво"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>

												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-publisher-city-input"
															name="publicationCity"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.publicationCity}
															// onChange={handleInputChange}
															label="Місто видання"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-publication-year-input"
															name="publicationYear"
															type="number"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.publicationYear}
															// onChange={handleInputChange}
															label="Рік видання"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>
											</div>
											<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
												<TextField
													id="item-translator-input"
													name="translator"
													onBlur={inputBlurHandler}
													onChange={inputValidation}
													value={bookItem.translator}
													// onChange={handleInputChange}
													label="Перекладач"
													defaultValue=""
													// helperText={formValidation.fields.title.text}
													// error={formValidation.fields.title.isError}
												/>
											</FormControl>
											<div className="row">
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-collection-input"
															name="booksCollection"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.booksCollection}
															// onChange={handleInputChange}
															label="Коллекція"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>
												<div className="col-md-6">
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															id="item-pages-input"
															name="pages"
															type="number"
															onBlur={inputBlurHandler}
															onChange={inputValidation}
															value={bookItem.pages}
															// onChange={handleInputChange}
															label="Сторінок"
															defaultValue=""
															// helperText={formValidation.fields.title.text}
															// error={formValidation.fields.title.isError}
														/>
													</FormControl>
												</div>
											</div>
											<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
												<TextField
													id="item-keywords-input"
													multiline
													rows="3"
													name="keywords"
													onBlur={inputBlurHandler}
													onChange={inputValidation}
													value={bookItem.keywords}
													// onChange={handleInputChange}
													label="Ключові слова"
													defaultValue=""
													// helperText={formValidation.fields.title.text}
													// error={formValidation.fields.title.isError}
												/>
											</FormControl>
											<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
												<TextField
													id="item-excerpt-input"
													multiline
													rows="3"
													name="excertp"
													onBlur={inputBlurHandler}
													onChange={inputValidation}
													value={bookItem.excertp}
													// onChange={handleInputChange}
													label="Цитата"
													defaultValue=""
													// helperText={formValidation.fields.title.text}
													// error={formValidation.fields.title.isError}
												/>
											</FormControl>
										</div>
										<div className="col-md-6 books-right-column-wrapper">
											<h2>Обкладинка</h2>
											<Button
												className="book-image-add-button"
												variant="contained"
												color="primary"
												size="big"
												onClick={fileClickHandler}
												startIcon={<ImageIcon />}
												disabled={uploadButtonDisabled}
											>
												Додати обкладинку
											</Button>
											{!bookItem.coverImage ? (
												""
											) : (
												<div className="image-upload-clear-wrapper">
													<Tooltip title="Видалити">
														<ClearIcon onClick={clearImageUploaded} />
													</Tooltip>
												</div>
											)}
											<input
												type="file"
												style={{ display: "none" }}
												ref={(input) => {
													fileInput = input;
												}}
												accept="image/x-png,image/gif,image/jpeg"
												onChange={fileUploadHandler}
												onBlur={inputBlurHandler}
											/>
											<span
												className="book-cover-img"
												style={{
													backgroundImage: "url(" + bookItem.coverImage + ")",
												}}
											></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default BookCreateEdit;
