import { SET_SESSION } from "../actions/actionsType";

export default function sessionReducer(state = {}, action) {
	switch (action.type) {
		case SET_SESSION: {
			return { ...action.user };
		}
		default:
			return state;
	}
}
