import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

import { Loading } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ApiRequest from "../../api/apiRequest";

import Cookies from "universal-cookie";

const ForgotPasswordEnterNew = (props) => {
	const cookies = new Cookies();

	const [validCall, setValidCall] = useState();
	const [passwords, setPasswords] = useState();
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState(
		""
	);
	const [newPasswordError, setNewPasswordError] = useState(false);
	const [newPasswordHelperText, setNewPasswordHelperText] = useState("");

	const [feedbackText, setFeedbackText] = useState("Перевіряємо запит");

	useEffect(() => {
		props.Loading(true);

		let request = {
			email: queryString.parse(window.location.search).email,
			hash: props.match.params.hash,
		};

		ApiRequest.apiRequest("ForgotPasswordCheck", request).then((result) => {
			if (result.success) {
				setValidCall(true);
				props.Loading(false);
			} else {
				setValidCall(false);
				setFeedbackText(
					"Посилання застаріло, будь-ласка спробуйте створити запит ще раз"
				);
				props.Loading(false);
			}
		});
	}, [props.match.params.hash]);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let valid = validateForm();

		if (valid) {
			let request = {
				new_password: passwords.new_password,
				confirm_password: passwords.confirm_password,
				email: queryString.parse(window.location.search).email,
				forgot_password: true,
			};

			ApiRequest.apiRequest("ForgotPasswordChange", request).then((result) => {
				if (result.success) {
					cookies.remove("flamingo");
					cookies.remove("user");
					window.location.replace("/login");
				} else {
					throw new Error("error changing password");
				}
			});
		}
	};

	const validateForm = () => {
		let passwordsObj = passwords;

		let valid = true;

		if (passwordsObj) {
			if (!passwordsObj.new_password) {
				valid = false;
				setNewPasswordError(true);
				setNewPasswordHelperText("Поле обов'язкове");
			} else {
				setNewPasswordError(false);
				setNewPasswordHelperText("");
			}

			if (!passwordsObj.confirm_password) {
				valid = false;
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Поле обов'язкове");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}

			if (passwordsObj.new_password !== passwordsObj.confirm_password) {
				valid = false;
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Паролі мають співпадати");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}
		} else {
			valid = false;

			setNewPasswordError(true);
			setNewPasswordHelperText("Поле обов'язкове");

			setConfirmPasswordError(true);
			setConfirmPasswordHelperText("Поле обов'язкове");
		}

		return valid;
	};

	const changeHandler = (event) => {
		setPasswords({
			...passwords,
			[event.target.name]: event.target.value,
		});
	};

	const validateConfirmPassword = (event) => {
		if (passwords) {
			if (passwords.new_password !== event.target.value) {
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Паролі мають співпадати");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}
		}
	};

	return (
		<>
			{!validCall ? (
				<h3>{feedbackText}</h3>
			) : (
				<>
					<section class="login-body-wrapper">
						<Paper elevation={3}>
							<div className="login-form-wrapper">
								<div className="login-logo"></div>
								<h2 className="login-wellcome-text">Зміна паролю</h2>

								<form onSubmit={handleFormSubmit}>
									<FormControl className="login-input-wrapper">
										<TextField
											// required
											// autoFocus
											type="password"
											id="new-password-input"
											name="new_password"
											// value={newItem.title}
											// onChange={handleInputChange}
											onChange={changeHandler}
											label="Новий пароль*"
											error={newPasswordError}
											helperText={newPasswordHelperText}
										/>
									</FormControl>
									<FormControl className="login-input-wrapper">
										<TextField
											// required
											// autoFocus
											type="password"
											id="confirm-password-input"
											name="confirm_password"
											// value={newItem.title}
											// onChange={handleInputChange}
											label="Підтвердіть новий пароль*"
											onChange={changeHandler}
											onBlur={validateConfirmPassword}
											error={confirmPasswordError}
											helperText={confirmPasswordHelperText}
										/>
									</FormControl>
									<div className="login-buttons-wrapper">
										<button
											// type="submit"
											class="btn btn-primary login-buttons-login"
											// onClick={handleUpdateSave}
											// onClick={validateForm}
										>
											Зберегти
										</button>
									</div>
								</form>
							</div>
						</Paper>
					</section>
				</>
			)}
		</>
	);
};

export default connect(
	(store) => ({
		// isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Loading,
			},
			dispatch
		)
)(ForgotPasswordEnterNew);
