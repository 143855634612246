import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../../api/apiRequest";
import { tableSettings } from "../../../configs/visualizationConfig";

const BookCategoriesSettings = (props) => {
	let fileInput = null;

	const host = "BooksConfig";
	const endpointPurposes = {
		getData: "getCategories",
		createItem: "createCategory",
		updateItem: "updateCategory",
		deleteItem: "deleteCategories",
		uploadFile: "uploadFileCategories",
	};

	const [dialogOpen, setDialogOpen] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [updateMode, setUpdateMode] = useState(false);
	const [categoryToUpdateId, setCategoryToUpdateId] = useState();

	const [fileUploaded, setFileUploaded] = useState();

	const [newCategory, setNewCategory] = useState({
		title: "",
		shortName: "",
		parentId: "",
		parent: "",
		description: "",
		booksCount: 0,
	});

	const [newCategoryStateSave, setNewCategoryStateSave] = useState({
		title: "",
		shortName: "",
		parentId: "",
		parent: "",
		description: "",
		booksCount: 0,
	});

	const [saveStateCategoryInput, setSaveStateCategoryInput] = useState(false);

	const [categories, setCategories] = useState([]);

	const [categoriesWaitingForDelete, setCategoriesWaitingForDelete] = useState(
		[]
	);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const [tableData, setTableData] = useState([]);

	const [titleError, setTitleError] = useState(false);
	const [shortNameError, setShortNameError] = useState(false);

	const getCategoriesFromApi = () => {
		let request = {
			purpose: endpointPurposes.getData,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let dataArray = [];

				result.categories.forEach((element) => {
					let tempArray = [];
					tempArray.push(element.shortName);
					tempArray.push(element.title);
					tempArray.push(element.parent);
					tempArray.push(element.description);
					tempArray.push(element.booksCount);
					tempArray.push(element.id);

					dataArray.push(tempArray);
				});

				setCategories(result.categories);
				setTableData(dataArray);
			}
		});
	};

	useEffect(() => {
		getCategoriesFromApi();
	}, []);

	const columns = [
		"Скорочення",
		"Категорія",
		"Материнська",
		"Опис",
		"Книг в категорії",
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<Tooltip title="Редагувати">
							<IconButton
								aria-label="Редагувати"
								onClick={() => editCategoryHandler(value)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Видалити">
							<IconButton
								aria-label="Видалити"
								onClick={() => deleteCategoryHandler(value)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	const handleParentCategoryCheck = (event, newValue) => {
		let parentTitle = "";
		let parentId = event.target.value;

		if (event.target.value != null) {
			categories.forEach((element) => {
				if (element.id == event.target.value) {
					parentTitle = element.title;
				}
			});
		}

		// setNewCategory(event.target.value);

		// categories.forEach((element) => {
		// 	if (element.id == newValue.id) {
		// 		parentTitle = element.title;
		// 	}
		// });

		setNewCategory({
			...newCategory,
			parent: parentTitle,
			parentId: parentId,
			// parent: parentTitle,
		});
	};

	// Dialog handlers

	const handleInputChange = (event) => {
		if (!updateMode) {
			setNewCategoryStateSave({
				...newCategory,
				[event.target.name]: event.target.value,
			});
		}
		setNewCategory({
			...newCategory,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormSave = () => {
		let isValid = validateForm();
		if (isValid) {
			let newCategoryObject = {
				title: newCategory.title,
				shortName: newCategory.shortName,
				parentId: newCategory.parentId,
				parent: newCategory.parent,
				description: newCategory.description,
				booksCount: newCategory.booksCount,
			};

			let request = {
				purpose: endpointPurposes.createItem,
				body: newCategoryObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				getCategoriesFromApi();
				dialogCancelHandler();
				setUpdateMode(false);
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Категорію " + newCategoryObject.title + " успішно створено!"
				);
			});
		}
	};

	const dialogOpenHandler = () => {
		if (saveStateCategoryInput) {
			setNewCategory(newCategoryStateSave);
		}

		setDialogOpen(true);
		setUpdateMode(false);
	};

	const dialogCloseHandler = () => {
		if (updateMode) {
			setNewCategory(newCategoryStateSave);
		}
		setDialogOpen(false);
		// setUpdateMode(false);
	};

	const dialogCancelHandler = () => {
		setNewCategory({
			title: "",
			shortName: "",
			parentId: "",
			parent: "",
			description: "",
		});
		setDialogOpen(false);

		setTitleError(false);
		setShortNameError(false);

		if (updateMode) {
			setSaveStateCategoryInput(true);
		} else {
			setSaveStateCategoryInput(false);
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!newCategory.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!newCategory.shortName) {
			valid = false;
			setShortNameError(true);
		} else {
			setShortNameError(false);
		}

		return valid;
	};

	// Edit dialog handlers

	const editCategoryHandler = (id) => {
		let categoryToEdit = {};

		categories.forEach((element) => {
			if (element.id == id) {
				categoryToEdit = element;
			}
		});

		setTitleError(false);
		setShortNameError(false);

		setUpdateMode(true);
		setNewCategoryStateSave(newCategory);
		// setEditCategory(categoryToEdit);
		setNewCategory(categoryToEdit);
		setDialogOpen(true);
	};

	const handleUpdateSave = () => {
		let isValid = validateForm();
		if (isValid) {
			let newCategoryObject = {
				id: newCategory.id,
				title: newCategory.title,
				shortName: newCategory.shortName,
				parentId: newCategory.parentId,
				parent: newCategory.parent,
				description: newCategory.description,
				booksCount: newCategory.booksCount,
			};

			let request = {
				purpose: endpointPurposes.updateItem,
				body: newCategoryObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				getCategoriesFromApi();
				dialogCancelHandler();
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Категорію " + newCategoryObject.title + " успішно оновлено!"
				);
			});
		}
	};

	// Table actions handlers

	const deleteCategoriesApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: categoriesWaitingForDelete,
		};

		console.log("delete cat api call: ", categoriesWaitingForDelete);

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getCategoriesFromApi();
				setSnackBarOpen(true);
				setSnackBarMessage(
					"Було видалено " + categoriesWaitingForDelete.length + " категорію!"
				);
			}
		});
	};

	const deleteCategoryHandler = (id) => {
		let categoriesToDelete = categoriesWaitingForDelete;

		if (id) {
			categoriesToDelete.push(id);

			setCategoriesWaitingForDelete(categoriesToDelete);
			setAlertWarningMessage(
				"Ви впевнені що хочете видалити обрану категорію?"
			);
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteCategoriesApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleCategoryDeleteHandler = (rowsDeleted, dataRows) => {
		let categoriesToDelete = categoriesWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			categoriesToDelete.push(categories[element.index].id);
			counter += 1;
		});

		setCategoriesWaitingForDelete(categoriesToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " категорії(й)"
		);
		setAlertActionButton(
			<button
				type="button"
				class="btn btn-danger"
				onClick={deleteCategoriesApiCall}
			>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setCategoriesWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			// let memberSince = data[0]["Member Since"];
			// let correctDate = getJsDateFromExcel(memberSince);

			let categoriesUpload = [];
			let counter = 0;

			for (let index = 0; index < data.length; index++) {
				let parentItem = "";
				let parentItemId = "";
				let excelParentItem = data[index]["Батьківська категорія"];

				if (excelParentItem) {
					categories.forEach((element) => {
						if (element.title == excelParentItem) {
							parentItem = element.title;
							parentItemId = element.id;
						}
					});
				}

				categoriesUpload.push({
					title: data[index]["Назва категорії"],
					shortName: data[index]["Скорочення"],
					description: data[index]["Опис"],
					parentId: parentItemId,
					parent: parentItem,
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: categoriesUpload,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getCategoriesFromApi();
					setSnackBarOpen(true);
					setSnackBarMessage(counter + " категорій успішно завантажено!");
				}
			});
		});
	};

	const prepareDataToExport = () => {
		let fileName = "BookCategories-Flaming.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		categories.forEach((element) => {
			dataArray.push({
				"Назва категорії": element.title,
				Скорочення: element.shortName,
				"Батьківська категорія": element.parent,
				Опис: element.description,
				"Книг у категорії": element.booksCount,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	// Table settings

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleCategoryDeleteHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<div class="col-xs-12">
				<div class="content">
					<header class="content-heading">
						<div className="card-actions-left-wrapper">
							<button
								class="btn btn-green"
								onClick={dialogOpenHandler}
								data-toggle="modal"
								data-target="#basic_modal"
							>
								+ Додати категорію
							</button>
						</div>
						<ul class="card-actions icons right-top">
							<li className="page-controlls-right-item">
								<button
									class="btn btn-default btn-fab"
									onClick={fileClickHandler}
								>
									<i class="zmdi zmdi-upload"></i>
									<input
										type="file"
										style={{ display: "none" }}
										ref={(input) => {
											fileInput = input;
										}}
										accept=".csv, .xls, .xlsx"
										onChange={fileUploadHandler}
									/>
									{/* /> */}
								</button>
							</li>
							<li className="page-controlls-right-item">
								<button
									class="btn btn-default btn-fab"
									onClick={prepareDataToExport}
								>
									<i class="zmdi zmdi-download"></i>
								</button>
							</li>
						</ul>
					</header>
					<div class="content-body m-t-20">
						<MUIDataTable
							title={"Категорії книг"}
							data={tableData}
							columns={columns}
							options={options}
						/>
					</div>
				</div>
			</div>

			{/* Create dialog */}
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								{updateMode ? "Редагувати категорію" : "Додати категорію книг"}
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<div class="modal-body">
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									required
									autoFocus
									id="book-category-title-input"
									name="title"
									value={newCategory.title}
									onChange={handleInputChange}
									label="Назва категорії"
									defaultValue=""
									helperText={titleError ? "Поле обов'язкове" : ""}
									error={titleError}
								/>
							</FormControl>
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									required
									id="book-category-short-input"
									name="shortName"
									value={newCategory.shortName}
									onChange={handleInputChange}
									label="Скорочений запис"
									defaultValue=""
									helperText={shortNameError ? "Поле обов'язкове" : ""}
									error={shortNameError}
								/>
							</FormControl>
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<InputLabel id="settings-modal-input-book-parrent-label">
									Материнська категорія
								</InputLabel>
								<Select
									labelId="settings-modal-input-book-parrent-label"
									id="settings-modal-input-book-parrent"
									name="parent"
									label="Материнська категорія"
									value={newCategory.parentId}
									onChange={(event) => handleParentCategoryCheck(event)}
								>
									<MenuItem selected value={null}>
										-- немає --
									</MenuItem>
									{categories.map(function(item, i) {
										if (item.id != newCategory.id) {
											return <MenuItem value={item.id}>{item.title}</MenuItem>;
										}
									})}
								</Select>

								{/* <Autocomplete
									id="combo-box-demo"
									options={categories}
									getOptionLabel={(item) => item.title}
									style={{ width: 300 }}
									name="parent"
									value={newCategory.parentId}
									onChange={(event, newValue) =>
										handleParentCategoryCheck(event, newValue)
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Материнська категорія"
											// variant="outlined"
											// value={newCategory.parent}
										/>
									)}
								/> */}
							</FormControl>
							<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
								<TextField
									id="book-category-short-input"
									name="description"
									value={newCategory.description}
									onChange={handleInputChange}
									label="Опис"
									// defaultValue=""
									helperText=""
									multiline
									rows={4}
								/>
							</FormControl>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-default btn-flat"
								data-dismiss="modal"
								onClick={dialogCancelHandler}
							>
								Скасувати
							</button>
							{updateMode ? (
								<button
									type="button"
									class="btn btn-primary"
									onClick={handleUpdateSave}
									// onClick={validateForm}
								>
									Зберегти
								</button>
							) : (
								<button
									type="button"
									class="btn btn-primary"
									onClick={handleFormSave}
									// onClick={validateForm}
								>
									Створити
								</button>
							)}
						</div>
					</div>
				</div>
			</Dialog>

			<Dialog
				open={alertOpen}
				onClose={alertCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div className="flamingo-alert-body-wrapper">
					<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
					{alertWarningMessage}
				</div>
				<div className="flamingo-alert-buttons-wrapper">
					<button
						type="button"
						class="btn btn-default btn-flat"
						data-dismiss="modal"
						onClick={alertCloseHandler}
					>
						Скасувати
					</button>
					{alertActionButton}
					{/* <button
						type="button"
						class="btn btn-danger"
						onClick={deleteCategoriesApiCall}
					>
						Видалити
					</button> */}
				</div>
			</Dialog>

			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity="success"
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
			{/* </div> */}
		</>
	);
};

export default BookCategoriesSettings;
