import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import RoomIcon from "@mui/icons-material/Room";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";

import * as ApiRequest from "../../api/apiRequest";

const PeopleLogosHistory = (props) => {
	const host = "People";
	const endpointPurposes = {
		updateItem: "updateLogosHistory",
		deleteItem: "deleteLogosHistory",
	};

	const [item, setItem] = useState({});

	const [logosHistoryCards, setLogosHistoryCards] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	useEffect(() => {
		fillTable();
	}, [props.person]);

	const onRowClick = (logos) => {
		if (logos) {
			setDialogOpen(true);
			setItem(logos);
		}
	};

	const fillTable = () => {
		let logosCards = [];

		props.person.live_force_logoses_history.forEach((logos) => {
			logosCards.push(
				<>
					<div
						className="member-activities-table-wrapper-item"
						// onClick={() => onRowClick(logos)}
					>
						<div className="col-md-12 member-activities-table-wrapper-item-first-line">
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								<AccountBalanceIcon className="member-activities-table-wrapper-item-cell-icon" />
								{
									props.livingForceLogos.find((item) => item._id == logos.logos)
										.title
								}
							</div>
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								{logos.status ? (
									<>
										<div className="member-activities-table-wrapper-item-cell-active">
											<CheckIcon className="member-activities-table-wrapper-item-cell-icon" />
										</div>
										Активний
									</>
								) : (
									<>
										<LoopIcon className="member-activities-table-wrapper-item-cell-icon" />
										Змінений
									</>
								)}
							</div>
						</div>
						<div className="col-md-12 member-activities-table-wrapper-item-second-line">
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
								{"З: " + logos.start_date}
							</div>
							<div className="col-md-6 member-activities-table-wrapper-item-cell">
								{logos.end_date == "" ? (
									""
								) : (
									<>
										<ScheduleIcon className="member-activities-table-wrapper-item-cell-icon" />
										{"По: " + logos.end_date}
									</>
								)}
							</div>
						</div>
					</div>
				</>
			);
		});
		setLogosHistoryCards(logosCards);
	};

	const dialogCloseHandler = () => {
		setDialogOpen(false);
	};

	const handleInputChange = (event) => {
		setItem({
			...item,
			[event.target.name]: event.target.value,
		});
	};

	const handleFormSave = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let logos = item;

		let request = {
			purpose: endpointPurposes.updateItem,
			body: logos,
			_id: props.person._id,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Історія логосів оновлена");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const HandleFormDelete = (event) => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: { _id: props.person._id, logos: item._id },
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setDialogOpen(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Історія логоса успішно видалено");
				props.getPersonData(props.person._id);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	return (
		<>
			<div className="row member-activities-table-wrapper">
				{logosHistoryCards}
			</div>
			<Dialog
				open={dialogOpen}
				onClose={dialogCloseHandler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="flamingo-modal"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<DialogTitle className="modal-header">
							<h4 class="modal-title" id="myModalLabel-2">
								Редагувати історію логосів
							</h4>
							<ul class="card-actions icons right-top">
								<a
									href="javascript:void(0)"
									data-dismiss="modal"
									class="text-white"
									aria-label="Close"
									onClick={dialogCloseHandler}
								>
									<i class="zmdi zmdi-close"></i>
								</a>
							</ul>
						</DialogTitle>
						<div class="modal-body">
							{item.logos ? (
								<form onSubmit={handleFormSave}>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<InputLabel id="logos-label">Логос</InputLabel>
										<Select
											labelId="logos-label"
											id="logos"
											name="logos"
											value={item.logos}
											onChange={handleInputChange}
										>
											{props.livingForceLogos
												.filter((logos) => logos.gender == props.person.gender)
												.map(function (item, i) {
													return (
														<MenuItem value={item._id}>{item.title}</MenuItem>
													);
												})}
										</Select>
									</FormControl>
									<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
										<TextField
											id="live_force_start_date"
											name="live_force_start_date"
											label="Дата завершення"
											disabled={item.status == true ? true : false}
											type="date"
											value={item.end_date}
											onChange={props.handleInputChange}
											onBlur={props.handleItemUpdate}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
									<div class="modal-footer">
										<button
											type="button"
											class="btn btn-warning dialog-modal-footer-button-left"
											onClick={HandleFormDelete}
										>
											Видалити
										</button>
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={dialogCloseHandler}
										>
											Скасувати
										</button>

										<button
											type="submit"
											class="btn btn-primary"
											// onClick={handleFormSave}
											// onClick={validateForm}
										>
											OK
										</button>
									</div>
								</form>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</Dialog>
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={6000}
				onClose={handleSnackBarClose}
			>
				<MuiAlert
					onClose={handleSnackBarClose}
					severity={snackBarSeverity}
					elevation={6}
					variant="filled"
				>
					{snackBarMessage}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default PeopleLogosHistory;
