import React, { useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Auth from "./Auth";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	console.log("Protected ROute: ", Auth.isAuthenticated());
	if (!Auth.isAuthenticated()) {
		return (
			<Redirect
				to={{
					pathname: "/login",
				}}
			/>
		);
	}
	return <Route {...rest} component={Component} />;
};

export default withRouter(ProtectedRoute);
