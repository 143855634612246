import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";

const Loader = (props) => {
	if (!props.isLoading) return null;

	return (
		<>
			<div id="app-loader">
				<CircularProgress />
			</div>
		</>
	);
};

export default connect((store) => ({
	isLoading: store.isLoading,
}))(Loader);
