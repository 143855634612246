import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import Cookies from "universal-cookie";
import * as ApiRequest from "../../api/apiRequest";

const ChangePassword = (props) => {
	const cookies = new Cookies();

	const [passwords, setPasswords] = useState();
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
		useState("");
	const [oldPasswordError, setOldPasswordError] = useState(false);
	const [oldPasswordHelperText, setOldPasswordHelperText] = useState("");
	const [newPasswordError, setNewPasswordError] = useState(false);
	const [newPasswordHelperText, setNewPasswordHelperText] = useState("");

	const handleFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let valid = validateForm();

		if (valid) {
			let userId = cookies.get("user");

			let request = {
				purpose: "changePassword",
				old_password: passwords.old_password,
				new_password: passwords.new_password,
				confirm_password: passwords.confirm_password,
				user: userId,
			};

			ApiRequest.apiRequest("AdminConfig", request).then((result) => {
				if (result.success) {
					cookies.remove("flamingo");
					cookies.remove("user");
					window.location.replace("/login");
				} else {
					throw new Error("error changing password");
				}
			});
		}
	};

	const validateForm = () => {
		let passwordsObj = passwords;

		let valid = true;

		if (passwordsObj) {
			if (!passwordsObj.old_password) {
				valid = false;
				setOldPasswordError(true);
				setOldPasswordHelperText("Поле обов'язкове");
			} else {
				setOldPasswordError(false);
				setOldPasswordHelperText("");
			}

			if (!passwordsObj.new_password) {
				valid = false;
				setNewPasswordError(true);
				setNewPasswordHelperText("Поле обов'язкове");
			} else {
				setNewPasswordError(false);
				setNewPasswordHelperText("");
			}

			if (!passwordsObj.confirm_password) {
				valid = false;
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Поле обов'язкове");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}

			if (passwordsObj.new_password == passwordsObj.old_password) {
				valid = false;
				setNewPasswordError(true);
				setNewPasswordHelperText("Новий пароль має відрізнятись");
			} else {
				setNewPasswordError(false);
				setNewPasswordHelperText("");
			}

			if (passwordsObj.new_password !== passwordsObj.confirm_password) {
				valid = false;
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Паролі мають співпадати");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}
		} else {
			valid = false;
			setOldPasswordError(true);
			setOldPasswordHelperText("Поле обов'язкове");

			setNewPasswordError(true);
			setNewPasswordHelperText("Поле обов'язкове");

			setConfirmPasswordError(true);
			setConfirmPasswordHelperText("Поле обов'язкове");
		}

		return valid;
	};

	const changeHandler = (event) => {
		setPasswords({
			...passwords,
			[event.target.name]: event.target.value,
		});
	};

	const validateConfirmPassword = (event) => {
		if (passwords) {
			if (passwords.new_password !== event.target.value) {
				setConfirmPasswordError(true);
				setConfirmPasswordHelperText("Паролі мають співпадати");
			} else {
				setConfirmPasswordError(false);
				setConfirmPasswordHelperText("");
			}
		}
	};

	return (
		<>
			<section class="login-body-wrapper">
				<Paper elevation={3}>
					<div className="login-form-wrapper">
						<div className="login-logo"></div>
						<h2 className="login-wellcome-text">Зміна паролю</h2>

						<form onSubmit={handleFormSubmit}>
							<FormControl className="login-input-wrapper">
								<TextField
									// required
									// autoFocus
									type="password"
									id="old-password-input"
									name="old_password"
									// value={newItem.title}
									// onChange={handleInputChange}
									onChange={changeHandler}
									label="Старий пароль*"
									error={oldPasswordError}
									helperText={oldPasswordHelperText}
								/>
							</FormControl>
							<FormControl className="login-input-wrapper">
								<TextField
									// required
									// autoFocus
									type="password"
									id="new-password-input"
									name="new_password"
									// value={newItem.title}
									// onChange={handleInputChange}
									onChange={changeHandler}
									label="Новий пароль*"
									error={newPasswordError}
									helperText={newPasswordHelperText}
								/>
							</FormControl>
							<FormControl className="login-input-wrapper">
								<TextField
									// required
									// autoFocus
									type="password"
									id="confirm-password-input"
									name="confirm_password"
									// value={newItem.title}
									// onChange={handleInputChange}
									label="Підтвердіть новий пароль*"
									onChange={changeHandler}
									onBlur={validateConfirmPassword}
									error={confirmPasswordError}
									helperText={confirmPasswordHelperText}
								/>
							</FormControl>
							<div className="login-buttons-wrapper">
								<button
									// type="submit"
									class="btn btn-primary login-buttons-login"
									// onClick={handleUpdateSave}
									// onClick={validateForm}
								>
									Змінити
								</button>
							</div>
						</form>
					</div>
				</Paper>
			</section>
		</>
	);
};

export default ChangePassword;
