import {
	Home,
	BookCreateEdit,
	Books,
	ChangePassword,
	EventView,
	Events,
	ForgotPassword,
	ForgotPasswordEnterNew,
	HomePage,
	Loans,
	Login,
	People,
	PeopleEdit,
	AdminSettings,
	BookSettings,
	BranchesSettings,
	EventsSettings,
	MediaSettings,
	GeneralSettings,
	SecondCircleSettings,
	PeopleGeneralSettings,
	PeopleSecondCircleSettings,
	PeopleThirdCircleSettings,
} from "../components/pages";

export const loginContainerRoutes = ["/login", "/changepassword", "/forgot"];

export const defaultContainerRoutes = [
	"/people",
	"/books",
	"/settings/books/",
	"/settings/branches/",
	"/settings/events/",
	"/settings/media/",
	"/settings/people/general",
	"/settings/people/thirdcircle",
	"/settings/people/secondcircle",
	"/settings/admin",
	"/books/:param",
	"/books",
	"/loans",
	"/people/view/:param",
	"/people/",
	"/people/:page",
	"/events/view/:param",
	"/events/:param",
];

export const noNavigationRoutes = [
	{ path: "/login", component: Login },
	{ path: "/changepassword", component: ChangePassword },
];

export const withNavigationRoutes = [
	{ path: "/", component: Home },
	{ path: "/people/", component: People },
	{ path: "/people/:page", component: People },
	{ path: "/people/view/:param", component: PeopleEdit },
	{ path: "/books/:param", component: BookCreateEdit },
	{ path: "/books", component: Books },
];
