import React, { useState } from "react";
import HierarchiesSettings from "../../settings/People/HierarchiesSettings";
import SecretariesSettings from "../../settings/People/SecretariesSettings";
import NewsLettersExternalSettings from "../../settings/People/NewsLettersExternalSettings";

const SeccondCircleSettings = (props) => {
	const [] = useState();

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Слухачі - Налаштування</h1>
										<ol class="breadcrumb">
											<li>
												<a href="index.html"></a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Друге коло</li>
										</ol>
									</header>
								</div>
							</div>
						</div>
					</div>
					<div class="tabpanel tab-header">
						<ul class="nav nav-tabs p-l-30 flamingo-tab-panel ">
							<li class="active" role="presentation">
								<a href="#hierarchies" data-toggle="tab">
									Ієрархії
								</a>
							</li>
							<li role="presentation">
								<a href="#secretaries" data-toggle="tab">
									Секретаріати
								</a>
							</li>
							<li role="presentation">
								<a href="#internalnewsletters" data-toggle="tab">
									Внутрішні розсилки
								</a>
							</li>
						</ul>
					</div>
					<div id="content" class="container-fluid m-t-0">
						<div class="content-body">
							<div id="card_content" class="tab-content">
								<div class="tab-pane fade active in" id="hierarchies">
									<div class="row">
										<HierarchiesSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="secretaries">
									<div class="row">
										<SecretariesSettings />
									</div>
								</div>
								<div class="tab-pane fade in" id="internalnewsletters">
									<div class="row">
										<NewsLettersExternalSettings />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SeccondCircleSettings;
