const tableSettings = {
	filterType: "checkbox",
	print: false,
	fixedHeader: true,
	download: false,
	expandableRows: false,
	textLabels: {
		toolbar: {
			search: "Пошук",
			downloadCsv: "Єкспорт у CSV",
			print: "Друкувати",
			viewColumns: "Стовпці",
			filterTable: "Фільтрувати",
		},
		filter: {
			all: "Всі",
			title: "ФІЛЬТРИ",
			reset: "ОЧИСТИТИ",
		},
		selectedRows: {
			text: "Обрано стовпців",
			delete: "Відалити",
			deleteAria: "Видалити обрані записи",
		},
		body: {
			noMatch: "Вибачте, нічого не знайдено",
			toolTip: "Сортувати",
		},
		pagination: {
			next: "Наступна",
			previous: "Попередня",
			rowsPerPage: "Рядків на сторінці:",
			displayRows: "з",
			jumpToPage: "До сторінки",
		},
		viewColumns: {
			title: "Показати стовпці",
			titleAria: "Відобразити/Сховати стовпці",
		},
	},
};

export { tableSettings };
