import React, { useState, useEffect } from "react";

import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/lab/Autocomplete";
import NativeSelect from "@mui/material/NativeSelect";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import * as ApiRequest from "../../../api/apiRequest";
import { tableSettings } from "../../../configs/visualizationConfig";

const AdminSettings = (props) => {
	let fileInput = null;

	const host = "AdminConfig";
	const endpointPurposes = {
		getData: "get",
		createItem: "create",
		updateItem: "update",
		deleteItem: "delete",
		uploadFile: "uploadFile",
	};

	const [items, setItems] = useState([]);
	const [tableData, setTableData] = useState([]);

	const [dialogOpen, setDialogOpen] = useState(false);

	//// !!! Change the structure !!!

	const clearStructure = {
		branch: "",
		generatePassword: false,
	};

	const [newItem, setNewItem] = useState(clearStructure);

	//// !!! Change the structure !!!
	const [newItemStateSave, setNewItemStateSave] = useState(clearStructure);

	const [people, setPeople] = useState([]);
	const [roles, setRoles] = useState([]);
	const [branches, setBranches] = useState([]);

	const [updateMode, setUpdateMode] = useState(false);

	const [saveStateItemInput, setSaveStateItemInput] = useState(false);

	const [alertOpen, setAlertOpen] = useState(false);
	const [alertWarningMessage, setAlertWarningMessage] = useState("");

	const [alertActionButton, setAlertActionButton] = useState("");

	const [itemsWaitingForDelete, setItemsWaitingForDelete] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] = useState("success");

	const [titleError, setTitleError] = useState(false);
	const [daysToSignReportError, setDaysToSignReportError] = useState(false);

	const getItemsFromApi = () => {
		let request = {
			purpose: endpointPurposes.getData,
		};

		//// !!! Change the structure !!!
		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				let dataArray = [];
				result.items.forEach((element) => {
					let tempArray = [];
					tempArray.push(`${element.name} ${element.surname}`);
					tempArray.push(element.email);
					tempArray.push(element.isEnabled);
					tempArray.push(element.lastLogin);
					tempArray.push(
						result.roles.find((role) => role._id == element.role).title
					);
					tempArray.push(element._id);

					dataArray.push(tempArray);
				});

				setItems(result.items);
				// setItemCategories(result.categories);
				setPeople(result.people);
				setRoles(result.roles);
				setBranches(result.branches);
				setTableData(dataArray);
			}
		});
	};

	useEffect(() => {
		getItemsFromApi();
	}, []);

	//// !!! Change the structure !!!
	const columns = [
		"Ім'я",
		"Email",
		{
			name: "Статус",
			label: "Статус",
			options: {
				customBodyRender: (value) => (value ? "Активний" : "Вимкнено"),
			},
		},
		"Авторизація",
		"Роль",
		{
			name: "",
			label: "",
			options: {
				customBodyRender: (value) => (
					<>
						<Tooltip title="Редагувати">
							<IconButton
								aria-label="Редагувати"
								onClick={() => editItemHandler(value)}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Видалити">
							<IconButton
								aria-label="Видалити"
								onClick={() => deleteItemHandler(value)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				),
				filter: false,
				sort: false,
			},
		},
	];

	// Dialog handlers

	const handleInputChange = (event) => {
		if (!updateMode) {
			setNewItemStateSave({
				...newItem,
				[event.target.name]: event.target.value,
			});
		}
		setNewItem({
			...newItem,
			[event.target.name]: event.target.value,
		});
	};

	const handleSwitcherChange = (event) => {
		if (!updateMode) {
			setNewItemStateSave({
				...newItem,
				[event.target.name]: event.target.checked,
			});
		}

		setNewItem({
			...newItem,
			[event.target.name]: event.target.checked,
		});
	};

	const dialogOpenHandler = () => {
		if (saveStateItemInput) {
			setNewItem(newItemStateSave);
		}

		setDialogOpen(true);
		setUpdateMode(false);
	};

	const dialogCloseHandler = () => {
		if (updateMode) {
			setNewItem(newItemStateSave);
		}
		setDialogOpen(false);
	};

	const dialogCancelHandler = () => {
		//// !!! Change the structure !!!

		setNewItem(clearStructure);
		setDialogOpen(false);

		setTitleError(false);
		setDaysToSignReportError(false);

		if (updateMode) {
			setSaveStateItemInput(true);
		} else {
			setSaveStateItemInput(false);
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!newItem.title) {
			valid = false;
			setTitleError(true);
		} else {
			setTitleError(false);
		}

		if (!newItem.daysToSignReport) {
			valid = false;
			setDaysToSignReportError(true);
		} else {
			setDaysToSignReportError(false);
		}

		return valid;
	};

	const handleFormSave = () => {
		// let isValid = validateForm();
		// if (isValid) {
		let newItemObject = newItem;

		let request = {
			purpose: endpointPurposes.createItem,
			body: newItemObject,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				getItemsFromApi();
				dialogCancelHandler();
				setUpdateMode(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Успішно додано адміністратора");
			} else {
				let message = "Помилка серверу";

				if (result.message == "exist") {
					message = "Адміністратор з таким ім'ям вже існує";
				}

				getItemsFromApi();
				dialogCancelHandler();
				setUpdateMode(false);
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage(message);
			}
		});
		// }
	};

	// Edit dialog handlers

	const editItemHandler = (id) => {
		let itemToEdit = {};

		items.forEach((element) => {
			if (element._id == id) {
				itemToEdit = element;
			}
		});

		itemToEdit = {
			...itemToEdit,
			user: people.find((p) => p._id == itemToEdit.userId),
			password: "",
		};

		setUpdateMode(true);
		setNewItemStateSave(newItem);
		setNewItem(itemToEdit);
		setDialogOpen(true);

		setTitleError(false);
		setDaysToSignReportError(false);
	};

	const handleUpdateSave = () => {
		// let isValid = validateForm();
		// if (isValid) {

		let newItemObject = {
			_id: newItem._id,
			name: newItem.user.name,
			surname: newItem.user.surname,
			branch: newItem.branch,
			role: newItem.role,
			email: newItem.email,
			generatePassword: newItem.generatePassword,
			password: newItem.password,
		};
		// let newItemObject = newItem;

		let request = {
			purpose: endpointPurposes.updateItem,
			body: newItemObject,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				getItemsFromApi();
				dialogCancelHandler();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage("Адміністратора успішно оновлено!");
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
		// }
	};

	// Table actions handlers

	const deleteItemsApiCall = () => {
		let request = {
			purpose: endpointPurposes.deleteItem,
			body: itemsWaitingForDelete,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				alertCloseHandler();
				getItemsFromApi();
				setSnackBarOpen(true);
				setSnackBarSeverity("success");
				setSnackBarMessage(
					"Було видалено " + itemsWaitingForDelete.length + " адміністратора!"
				);
			} else {
				setSnackBarOpen(true);
				setSnackBarSeverity("error");
				setSnackBarMessage("Сталася помилка");
			}
		});
	};

	const deleteItemHandler = (id) => {
		let itemsToDelete = itemsWaitingForDelete;

		if (id) {
			itemsToDelete.push(id);

			setItemsWaitingForDelete(itemsToDelete);
			setAlertWarningMessage(
				"Ви впевнені що хочете видалити обраного адміністратора?"
			);
			setAlertActionButton(
				<button
					type="button"
					class="btn btn-danger"
					onClick={deleteItemsApiCall}
				>
					Видалити
				</button>
			);
			setAlertOpen(true);
		}
	};

	const multipleItemsDeleteHandler = (rowsDeleted, dataRows) => {
		let itemsToDelete = itemsWaitingForDelete;

		let counter = 0;

		rowsDeleted.data.forEach((element) => {
			itemsToDelete.push(items[element.index].id);
			counter += 1;
		});

		setItemsWaitingForDelete(itemsToDelete);
		setAlertWarningMessage(
			"Ви впевнені що хочете видалити " + counter + " адміністраторів"
		);
		setAlertActionButton(
			<button type="button" class="btn btn-danger" onClick={deleteItemsApiCall}>
				Видалити
			</button>
		);
		setAlertOpen(true);
	};

	const alertCloseHandler = () => {
		setItemsWaitingForDelete([]);
		setAlertOpen(false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	const selectPersonHandler = (event, newValue) => {
		setNewItem({
			...newItem,
			name: newValue.name,
			surname: newValue.surname,
			email: newValue.email,
			branch: newValue.branch,
			userId: newValue._id,
			user: newValue,
		});
	};

	// Download/Upload excel

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const promise = new Promise((resolve, reject) => {
			let fileReader = new FileReader();

			fileReader.readAsArrayBuffer(userFile);

			fileReader.onload = (e) => {
				let bufferArray = e.target.result;
				let wb = XLSX.read(bufferArray, { type: "buffer" });
				let wsname = wb.SheetNames[0];
				let ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((data) => {
			let successFlag = true;

			let itemsUpload = [];
			let counter = 0;

			//// !!! Change the structure !!!

			for (let index = 0; index < data.length; index++) {
				let categoryId = "";
				let isManager = true;

				if (data[index]["Керівник"] == "Так") {
					isManager = true;
				} else {
					isManager = false;
				}

				if (data[index]["Ієрархія"] == undefined) {
					successFlag = false;
					setSnackBarOpen(true);
					setSnackBarSeverity("error");
					setSnackBarMessage(
						"Не вірний формат, перевірте файл, рядок: " + index
					);
					break;
				}

				itemsUpload.push({
					title: data[index]["Ієрархія"],
					reportTitle: data[index]["Назва звіту"],
					daysToSignReport: data[index]["Днів на підписання звіту"],
					isManager: isManager,
				});

				counter += 1;
			}

			let request = {
				purpose: endpointPurposes.uploadFile,
				body: itemsUpload,
			};

			if (successFlag) {
				ApiRequest.apiRequest(host, request).then((result) => {
					if (result.success) {
						getItemsFromApi();
						setSnackBarOpen(true);
						setSnackBarSeverity("success");
						setSnackBarMessage("Успішно завантажено " + counter + " ієрархій!");
					} else {
						setSnackBarOpen(true);
						setSnackBarSeverity("error");
						setSnackBarMessage("Сталася помилка");
					}
				});
			}
		});
	};

	//// !!! Change the structure !!!
	const prepareDataToExport = () => {
		let fileName = "Hierarchies-Flamingo.xlsx";
		let fileType =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		let fileExtension = ".xlsx";

		let dataArray = [];

		items.forEach((element) => {
			let isManager = "Так";

			if (!element.isManager) {
				isManager = "Ні";
			}

			dataArray.push({
				Ієрархія: element.title,
				Керівник: isManager,
				"Назва звіту": element.reportTitle,
				"Днів на підписання звіту": element.daysToSignReport,
			});
		});

		let ws = XLSX.utils.json_to_sheet(dataArray);

		let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

		let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		let data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};

	let tableSettingsLocal = tableSettings;
	tableSettingsLocal.onRowsDelete = multipleItemsDeleteHandler;
	tableSettingsLocal.expandableRows = false;

	const options = tableSettingsLocal;

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					<div id="header_wrapper" class="header-sm ">
						<div class="container-fluid">
							<div class="row">
								<div class="col-xs-12">
									<header id="header">
										<h1>Адміністратори</h1>
										{/* <ol class="breadcrumb">
											<li>
												<a href="index.html">Головна</a>
											</li>
											<li>
												<a href="javascript:void(0)">Налаштування</a>
											</li>
											<li class="active">Медіа</li>
										</ol> */}
									</header>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-body">
					<div id="card_content" class="tab-content p-20">
						<div class="tab-pane fade active in" id="mediaBody">
							<div class="row p-20">
								<div class="col-xs-12">
									<div class="content">
										<header class="content-heading">
											<div className="card-actions-left-wrapper">
												<button
													class="btn btn-green"
													onClick={dialogOpenHandler}
													data-toggle="modal"
													data-target="#basic_modal"
												>
													+ Додати адміністратора
												</button>
											</div>
											<ul class="card-actions icons right-top">
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={fileClickHandler}
													>
														<i class="zmdi zmdi-upload"></i>
														<input
															type="file"
															style={{ display: "none" }}
															ref={(input) => {
																fileInput = input;
															}}
															accept=".csv, .xls, .xlsx"
															onChange={fileUploadHandler}
														/>
														{/* /> */}
													</button>
												</li>
												<li className="page-controlls-right-item">
													<button
														class="btn btn-default btn-fab"
														onClick={prepareDataToExport}
													>
														<i class="zmdi zmdi-download"></i>
													</button>
												</li>
											</ul>
										</header>
										<div class="content-body m-t-20">
											<MUIDataTable
												title={"Ієрархії"}
												data={tableData}
												columns={columns}
												options={options}
											/>
										</div>
									</div>
								</div>

								{/* Create dialog */}
								<Dialog
									open={dialogOpen}
									onClose={dialogCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div class="modal-dialog">
										<div class="modal-content">
											<DialogTitle className="modal-header">
												<h4 class="modal-title" id="myModalLabel-2">
													{updateMode
														? "Редагувати адміністратора"
														: "Додати адміністратора"}
												</h4>
												<ul class="card-actions icons right-top">
													<a
														href="javascript:void(0)"
														data-dismiss="modal"
														class="text-white"
														aria-label="Close"
														onClick={dialogCloseHandler}
													>
														<i class="zmdi zmdi-close"></i>
													</a>
												</ul>
											</DialogTitle>
											<div class="modal-body">
												<form
													onSubmit={
														updateMode ? handleUpdateSave : handleFormSave
													}
													action="#"
												>
													{people ? (
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<Autocomplete
																id="choose_person"
																className="admin-select-person-autocomplete-field"
																options={people}
																disabled={updateMode ? true : false}
																getOptionLabel={(option) =>
																	`${option.name} ${option.surname}`
																}
																value={newItem.user}
																// style={{ width: 300 }}
																onChange={selectPersonHandler}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Слухач"
																		// variant="outlined"
																	/>
																)}
															/>
														</FormControl>
													) : (
														""
													)}
													<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
														<TextField
															required
															// autoFocus
															id="item-email-input"
															name="email"
															onChange={handleInputChange}
															label="Email"
															value={newItem.email}
															InputLabelProps={{
																shrink: true,
															}}
															// helperText={titleError ? "Поле обов'язкове" : ""}
															// error={titleError}
														/>
													</FormControl>
													<FormGroup
														row
														className="p-t-20 settings-modal-input-wrapper"
													>
														<Grid
															component="label"
															container
															alignItems="center"
															spacing={1}
														>
															<Grid item>Пароль: </Grid>
															<Grid item>Автогенерація</Grid>
															<Grid item>
																<Switch
																	checked={newItem.generatePassword}
																	onChange={handleSwitcherChange}
																	name="generatePassword"
																/>
															</Grid>
															<Grid item>Ввести</Grid>
														</Grid>
													</FormGroup>
													{newItem.generatePassword ? (
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<TextField
																required
																// autoFocus
																id="item-password-input"
																name="password"
																// type="password"
																onChange={handleInputChange}
																label="Пароль"
																value={newItem.password}
																// InputLabelProps={{
																// 	shrink: true,
																// }}
																// helperText={titleError ? "Поле обов'язкове" : ""}
																// error={titleError}
															/>
														</FormControl>
													) : (
														""
													)}
													{roles ? (
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<InputLabel
																id="settings-modal-input-branch-parrent-label"
																// className={categoryError && "red"}
															>
																Роль *
															</InputLabel>
															<Select
																labelId="settings-modal-input-event-type-category-label"
																id="settings-modal-input-event-type-category"
																name="role"
																value={newItem.role}
																onChange={handleInputChange}
																required
																// error={categoryError}
															>
																{roles.map(function(item, i) {
																	return (
																		<MenuItem value={item._id}>
																			{item.title}
																		</MenuItem>
																	);
																})}
															</Select>
															{/* {categoryError && ( */}
															{/* <FormHelperText className="red">
																Поле обов'язкове
															</FormHelperText> */}
															{/* )} */}
														</FormControl>
													) : (
														""
													)}
													{branches ? (
														<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
															<InputLabel
																id="settings-modal-input-branch-parrent-label"
																// className={categoryError && "red"}
															>
																Осередок *
															</InputLabel>
															<Select
																labelId="settings-modal-input-event-type-category-label"
																id="settings-modal-input-event-type-category"
																name="branch"
																value={newItem.branch}
																onChange={handleInputChange}
																required
																// native
																InputLabelProps={{
																	shrink: true,
																}}
																// error={categoryError}
															>
																{branches.map(function(item, i) {
																	return (
																		<MenuItem value={item._id}>
																			{item.title}
																		</MenuItem>
																	);
																})}
															</Select>
															{/* {categoryError && ( */}
															{/* <FormHelperText className="red">
																Поле обов'язкове
															</FormHelperText> */}
															{/* )} */}
														</FormControl>
													) : (
														""
													)}
												</form>
											</div>
											<div class="modal-footer">
												<button
													type="button"
													class="btn btn-default btn-flat"
													data-dismiss="modal"
													onClick={dialogCancelHandler}
												>
													Скасувати
												</button>
												{updateMode ? (
													<button
														type="button"
														class="btn btn-primary"
														onClick={handleUpdateSave}
														// onClick={validateForm}
													>
														Зберегти
													</button>
												) : (
													<button
														type="button"
														class="btn btn-primary"
														onClick={handleFormSave}
														// onClick={validateForm}
													>
														Створити
													</button>
												)}
											</div>
										</div>
									</div>
								</Dialog>

								<Dialog
									open={alertOpen}
									onClose={alertCloseHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									className="flamingo-modal"
								>
									<div className="flamingo-alert-body-wrapper">
										<ErrorOutlineIcon className="flamingo-alert-body-warning-icon" />
										{alertWarningMessage}
									</div>
									<div className="flamingo-alert-buttons-wrapper">
										<button
											type="button"
											class="btn btn-default btn-flat"
											data-dismiss="modal"
											onClick={alertCloseHandler}
										>
											Скасувати
										</button>
										{alertActionButton}
									</div>
								</Dialog>
								<Snackbar
									open={snackBarOpen}
									autoHideDuration={6000}
									onClose={handleSnackBarClose}
								>
									<MuiAlert
										onClose={handleSnackBarClose}
										severity={snackBarSeverity}
										elevation={6}
										variant="filled"
									>
										{snackBarMessage}
									</MuiAlert>
								</Snackbar>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AdminSettings;
