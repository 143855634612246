import { SET_LOGIN_ERROR } from "../actions/actionsType";

export default function loginErrorReducer(state = {}, action) {
	switch (action.type) {
		case SET_LOGIN_ERROR: {
			return { ...action };
		}
		default:
			return state;
	}
}
