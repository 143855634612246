import React, { useState } from "react";

const GeneralSettings = (props) => {
	const [] = useState();

	return (
		<>
			<section id="content_outer_wrapper" class="">
				<div id="content_wrapper" class="simple">
					GeneralSettings
				</div>
			</section>
		</>
	);
};

export default GeneralSettings;
