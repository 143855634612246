import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

import { Loading } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ApiRequest from "../../api/apiRequest";

const ForgotPassword = (props) => {
	const [emailError, setEmailError] = useState(false);
	const [emailErrorText, setEmailErrorText] = useState("");

	const [emailSent, setEmailSent] = useState(false);

	const [email, setEmail] = useState("");

	const handleEmailBlur = (event) => {
		setEmail(event.target.value);
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		props.Loading(true);

		let valid = validateEmail(email);

		let request = {
			email: email,
		};

		ApiRequest.apiRequest("ForgotPassword", request).then((result) => {
			if (result.success) {
				setEmailSent(true);
				props.Loading(false);
			} else {
				setEmailError(true);
				setEmailErrorText("Користувача з такою адресою не знайдено");
				props.Loading(false);
			}
		});
	};

	const validateEmail = (emailInput) => {
		let valid = true;

		if (!emailInput) {
			valid = false;
			setEmailError(true);
			setEmailErrorText("Введіть будь-ласка ємейл");
		}

		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput)) {
			valid = false;
			setEmailError(true);
			setEmailErrorText("Введіть корректний ємейл");
		}

		if (valid) {
			setEmailError(false);
			setEmailErrorText("");
		}

		return valid;
	};

	return (
		<>
			<section class="login-body-wrapper">
				<Paper elevation={3}>
					<div className="login-form-wrapper">
						<div className="login-logo"></div>
						{emailSent ? (
							<h2 className="login-wellcome-text">
								На єлектронну адресу <b>{email}</b> було надіслано листа,
								перевірте пошту і перейдіть за посиланням
							</h2>
						) : (
							<>
								<h2 className="login-wellcome-text">
									Введіть свою електронну адресу:
								</h2>

								<form onSubmit={handleFormSubmit}>
									<FormControl className="login-input-wrapper">
										<TextField
											required
											autoFocus
											type="email"
											id="login-email-input"
											name="email"
											// value={newItem.title}
											onBlur={handleEmailBlur}
											label="Ємейл"
											error={emailError}
											helperText={emailErrorText}
										/>
									</FormControl>
									<div className="login-buttons-wrapper">
										<button
											// type="submit"
											class="btn btn-primary login-buttons-login"
											// onClick={handleUpdateSave}
											// onClick={validateForm}
										>
											Нагадати
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</Paper>
			</section>
		</>
	);
};

export default connect(
	(store) => ({
		// isLoad: store.isLoad,
	}),
	(dispatch) =>
		bindActionCreators(
			{
				Loading,
			},
			dispatch
		)
)(ForgotPassword);
