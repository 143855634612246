import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const PeopleVolunteerInfo = (props) => {
	const [] = useState();

	return (
		<>
			<form>
				<div className="row acordion-row">
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="volunteer-interests-input"
								name="volunteer_interestedIn"
								onBlur={props.handleItemUpdate}
								defaultValue={props.person.volunteer_interestedIn}
								// onChange={props.handleInputChange}
								label="Інтереси"
								// helperText={formValidation.fields.title.text}
								// error={formValidation.fields.title.isError}
							/>
						</FormControl>
					</div>
					<div className="col-md-6">
						<FormControl className="settings-modal-input-wrapper settings-modal-input-books-wrapper">
							<TextField
								id="isVolunteer-date"
								name="isVolunteer_date"
								label="Дата початку"
								type="date"
								defaultValue={props.person.isVolunteer_date}
								// onChange={props.handleInputChange}
								onBlur={props.handleItemUpdate}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</FormControl>
					</div>
				</div>
			</form>
		</>
	);
};

export default PeopleVolunteerInfo;
