export { default as Home } from "./Home";
export { default as BookCreateEdit } from "./BookCreateEdit";
export { default as Books } from "./Books";
export { default as ChangePassword } from "./ChangePassword";
export { default as Events } from "./Events";
export { default as EventView } from "./EventView";
export { default as ForgotPassword } from "./ForgotPassword";
export { default as ForgotPasswordEnterNew } from "./ForgotPasswordEnterNew";
// export { default as HomePage } from "./HomePage";
export { default as Loans } from "./Loans";
export { default as Login } from "./Login";
export { default as People } from "./People";
export { default as PeopleEdit } from "./PeopleEdit";
export { default as AdminSettings } from "./settings/AdminSettings";
export { default as BookSettings } from "./settings/BookSettings";
export { default as BranchesSettings } from "./settings/BranchesSettings";
export { default as EventsSettings } from "./settings/EventsSettings";
export { default as GeneralSettings } from "./settings/GeneralSettings";
export { default as MediaSettings } from "./settings/MediaSettings";
export { default as SecondCircleSettings } from "./settings/SeccondCircleSettings";
export { default as PeopleGeneralSettings } from "./settings/People/GeneralSettings";
export { default as PeopleSecondCircleSettings } from "./settings/People/SecondCircleSettings";
export { default as PeopleThirdCircleSettings } from "./settings/People/ThirdCircleSettings";
